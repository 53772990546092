import * as React from 'react';
import { CSSProperties } from 'react';
import * as moment from 'moment';
import styled from '../../styled';
import { STYLE_CONSTANTS } from '../../assets/StyleConstants';
import { LEFT_ARROW_ICON, RIGHT_ARROW_ICON } from '../../images/images';
import { CustomButton } from './Button';

interface Props {
    style?: React.CSSProperties;
    selectedDate: moment.Moment;
    onDateSelected: (date: moment.Moment) => void;
    onClickOutside: () => void;
}

interface State {
    selectedDate: moment.Moment;
    firstDayOfMonth: moment.Moment;
}

const ELEMENT_KEY_DATE_FORMAT = 'YYYY-M-D';

export class NLDatePicker extends React.Component<Props, State> {

    public state: State = NLDatePicker.getStateFromProps(this.props);

    private containerDiv: HTMLDivElement;

    public componentDidMount(): void {
        document.addEventListener('mousedown', this.handleClickOutside);
    }
    
    public componentWillUnmount(): void {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }
    
    private handleClickOutside = (event: any): void => {
        if (this.containerDiv && !this.containerDiv.contains(event.target)) {
          this.props.onClickOutside();
        }
    }

    public static getDerivedStateFromProps(nextProps: Props, prevState: State): State | null {

        const selectedDateFromNewProps = nextProps.selectedDate.clone().startOf('day');

        if (
            null == prevState
            || prevState.firstDayOfMonth.year() !== nextProps.selectedDate.year()
            || prevState.firstDayOfMonth.month() !== nextProps.selectedDate.month()
            || false === prevState.selectedDate.isSame(selectedDateFromNewProps)
        ) {
            return NLDatePicker.getStateFromProps(nextProps);
        }

        return null;
    }

    private static getStateFromProps(props: Props): State {

        return {
            selectedDate: props.selectedDate.clone().startOf('day'),
            firstDayOfMonth: props.selectedDate.clone().startOf('month')
        };
    }

    private renderWeeks(): JSX.Element[] {

        const weekElements: JSX.Element[] = [];

        const month = this.state.firstDayOfMonth.month();
        const today = moment().startOf('day');
        const dayOfWeekForFirstDayOfMonth = this.state.firstDayOfMonth.day();
        const firstSundayToShow = this.state.firstDayOfMonth.clone().subtract(dayOfWeekForFirstDayOfMonth, 'days');
        const firstDayOfNextMonth = this.state.firstDayOfMonth.clone().add(1, 'months');

        for (let currentSunday = firstSundayToShow.clone(); currentSunday.isBefore(firstDayOfNextMonth); currentSunday.add(7, 'days')) {
            const week: JSX.Element = this.renderWeek(currentSunday, month, today);
            weekElements.push(week);
        }

        return weekElements;
    }

    private renderWeek(sunday: moment.Moment, month: number, today: moment.Moment): JSX.Element {

        const dayElements: JSX.Element[] = [];

        for (let day = sunday.clone(); day.diff(sunday, 'days') < 7; day.add(1, 'days')) {
            const isInMonth = day.month() === month;
            const dayElement = this.renderDay(day, isInMonth, today);
            dayElements.push(dayElement);
        }

        return (
            <div style={dayRowStyle} key={`${sunday.format(ELEMENT_KEY_DATE_FORMAT)}`}>
                {dayElements}
            </div>
        );
    }

    private renderDay(date: moment.Moment, isInMonth: boolean, today: moment.Moment): JSX.Element {

        let style = dayStyle;
        let isFuture = false;

        if (isInMonth) {
            if (date.isSame(this.state.selectedDate)) {
                style = selectedDayStyle;
            } else if (date.isAfter(today)) {
                style = futureDayStyle;
                isFuture = true;
            }
        }

        const dateClone = date.clone();

        return (
            <CustomButton
                type={'button'}
                style={style}
                isDisabled={isFuture}
                onClick={() => this.props.onDateSelected(dateClone)}
                key={date.format(ELEMENT_KEY_DATE_FORMAT)}
            >
                {isInMonth ? dateClone.format('D') : ''}
            </CustomButton>
        );
    }

    public render(): JSX.Element {

        return (
            <div
                style={{...outerContainerStyle, ...this.props.style}}
                ref={(div: HTMLDivElement): void => { this.containerDiv = div; }}
            >
                <div style={popoverArrowStyle}>&nbsp;</div>
                <div style={containerStyle}>
                    <TitleNavRow>
                        <CustomButton
                            style={monthNavButtonStyle}
                            onClick={() => {
                                this.setState({
                                    firstDayOfMonth: this.state.firstDayOfMonth.clone().subtract(1, 'month')
                                });
                            }}
                        >
                            <div style={leftArrowIconStyle}>&nbsp;</div>
                        </CustomButton>
                        <div style={monthNameStyle}>
                            <span style={boldStyle}>{this.state.firstDayOfMonth.format('MMMM')}</span>&nbsp;<span>{this.state.firstDayOfMonth.format('YYYY')}</span>
                        </div>
                        <CustomButton
                            style={monthNavButtonStyle}
                            onClick={() => {
                                this.setState({
                                    firstDayOfMonth: this.state.firstDayOfMonth.clone().add(1, 'month')
                                });
                            }}
                        >
                            <div style={rightArrowIconStyle}>&nbsp;</div>
                        </CustomButton>
                    </TitleNavRow>
                    <div style={dayOfWeekRowStyle}>
                        <span style={dayOfWeekStyle}>S</span>
                        <span style={dayOfWeekStyle}>M</span>
                        <span style={dayOfWeekStyle}>T</span>
                        <span style={dayOfWeekStyle}>W</span>
                        <span style={dayOfWeekStyle}>T</span>
                        <span style={dayOfWeekStyle}>F</span>
                        <span style={dayOfWeekStyle}>S</span>
                    </div>
                    {this.renderWeeks()}
                </div>
            </div>
        );
    }
}

const outerContainerStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    zIndex: 1
};

const containerStyle: CSSProperties = {
    borderRadius: '5px',
    border: 0,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    width: '240px',
    backgroundColor: STYLE_CONSTANTS.white,
    boxShadow: '0 5px 15px 0 rgba(50,50,93,0.03), 0 15px 35px 0 rgba(50,50,93,0.1)',
    paddingBottom: '15px'
};

const monthNameStyle: CSSProperties = {
    paddingTop: '15px',
    paddingBottom: '15px'
};

const dayOfWeekRowStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontSize: STYLE_CONSTANTS.fontSizeSmaller,
    padding: '15px 15px 0 15px'
};

const boldStyle: CSSProperties = {
    fontWeight: 'bold'
};

const dayStyle: CSSProperties = {
    width: '30px',
    height: '30px',
    lineHeight: '22px',
    padding: '5px',
    textAlign: 'center',
    color: STYLE_CONSTANTS.darkerGray,
    backgroundColor: STYLE_CONSTANTS.white,
    fontSize: STYLE_CONSTANTS.fontSizeSmaller,
    transition: 'none'
};

const futureDayStyle: CSSProperties = {
    ...dayStyle,
    color: STYLE_CONSTANTS.mediumGray
};

const selectedDayStyle: CSSProperties = {
    ...dayStyle,
    backgroundColor: STYLE_CONSTANTS.darkGreen,
    color: STYLE_CONSTANTS.white,
    borderRadius: '15px'
};

const dayOfWeekStyle: CSSProperties = {
    ...dayStyle,
    fontWeight: 'bold',
    color: 'black'
};

const dayRowStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    color: STYLE_CONSTANTS.darkerGray,
    fontSize: STYLE_CONSTANTS.fontSizeSmaller,
    padding: '0px 15px 0px 15px'
};

const monthNavButtonStyle: CSSProperties = {
    backgroundColor: STYLE_CONSTANTS.transparent,
    color: STYLE_CONSTANTS.white,
    margin: 0,
    padding: '15px',
    height: undefined
};

const navButtonStyle: CSSProperties = {
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    height: '13px',
    width: '8px'
};

const leftArrowIconStyle: CSSProperties = {
    ...navButtonStyle,
    backgroundImage: `url(${LEFT_ARROW_ICON})`

};

const rightArrowIconStyle: CSSProperties = {
    ...navButtonStyle,
    backgroundImage: `url(${RIGHT_ARROW_ICON})`
};

const popoverArrowStyle: CSSProperties = {
    width: 0,
    height: 0,
    borderLeft: '10px solid transparent',
    borderRight: '10px solid transparent',
    borderBottom: `10px solid ${STYLE_CONSTANTS.midGradientGreen}`,
    margin: 'auto'
};

const TitleNavRow = styled.div`
    background: linear-gradient(to right, #4bcdb8, #4bdfa2);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 14px;
`;
