import * as React from 'react';
import { Link } from 'react-router-dom';
import { cellLinkTextStyle, cellTextStyle } from '../../assets/CommonStyles';
import { Client } from '../../data/models/Client';
import { ROUTES } from '../../Routes';

interface Props {
    style?: React.CSSProperties;
    client: Client | undefined;
}

export const ClientLink: React.SFC<Props> = ({ client, style, children }) => {

    if (undefined == client) {
        return <p style={{ ...cellTextStyle, ...style }}>&ndash;</p>;
    }

    return (
        <Link
            key={'client_link'}
            to={`${ROUTES.client}/${client.firebaseUid}`}
            style={{ ...cellLinkTextStyle, flexDirection: 'row', display: 'flex', ...style }}
        >
            {children}
        </Link>
    );
};
