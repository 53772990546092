import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { CSSProperties } from 'react';
import { Link, Redirect, RouteComponentProps } from 'react-router-dom';
import { panelButtonStyle } from '../../assets/CommonStyles';
import { COPY_CONSTANTS } from '../../assets/CopyConstants';
import { STYLE_CLASSES, STYLE_CONSTANTS } from '../../assets/StyleConstants';
import { Facility } from '../../data/models/Facility';
import { Peer } from '../../data/models/Peer';
import { PeerVisit } from '../../data/models/PeerVisit';
import { ApplicationStore } from '../../data/stores/ApplicationStore';
import { ROUTES } from '../../Routes';
import { timestampToDateString } from '../../util/common';
import { CustomButton } from '../components/Button';
import { ConfirmationModal } from '../components/ConfirmationModal';
import { Header } from '../components/Header';
import { ListPanel } from '../components/ListPanel';
import { PeerAddEditModal } from '../components/PeerAddEditModal';
import { Darkener } from '../components/Darkener';

interface IRouteParams {
    id: string;
}

export interface Props extends RouteComponentProps<IRouteParams> {
    store: ApplicationStore;
}

interface State {
    peer?: Peer;
    clientCount: number;
    peerVisits: PeerVisit[];
    facility?: Facility;
    isEditing: boolean;
    isDeactivating: boolean;
    hasDeactivated?: boolean;
}

@inject('store')
@observer
export class PeerDetail extends React.Component<Props, State> {

    public constructor(props: Props) {

        super(props);

        this.state = {
            clientCount: 0,
            isEditing: false,
            isDeactivating: false,
            peerVisits: []
        };
    }

    public componentWillMount(): void {

        this.refreshData();
    }

    private async refreshData(): Promise<void> {

        const peerUserFirebaseID = this.props.match.params.id;
        const peer = await this.props.store.userStore.getPeer(peerUserFirebaseID);
        const clients = await this.props.store.userStore.getClientIDsAndDisplayNamesForPeer(peerUserFirebaseID);
        const peerVisits = await this.props.store.peerVisitStore.getPeerVisitsForPeer(peerUserFirebaseID);
        const facility = await this.props.store.facilityStore.getFacility(peer.facilityFirebaseID);

        this.setState({
            peer: peer,
            clientCount: clients ? clients.length : 0,
            peerVisits: peerVisits,
            facility: facility,
            isDeactivating: false
        });
    }

    private getPeerInfoItems(peer: Peer, facility: Facility): JSX.Element[][] {

        const rowItems: JSX.Element[][] = [
            this.buildPeerInfoRowItem(COPY_CONSTANTS.firstName, this.wrapFieldValueInParagraph(peer.firstName)),
            this.buildPeerInfoRowItem(COPY_CONSTANTS.lastName, this.wrapFieldValueInParagraph(peer.lastName)),
            this.buildPeerInfoRowItem(COPY_CONSTANTS.email, this.wrapFieldValueInParagraph(peer.emailAddress, wordBreakAllStyle)),
            this.buildPeerInfoRowItem(COPY_CONSTANTS.facility, this.getFacilityLink(facility)),
            this.buildPeerInfoRowItem(COPY_CONSTANTS.joinDate, this.wrapFieldValueInParagraph(timestampToDateString(peer.createdTimestamp))),
            this.buildPeerInfoRowItem(COPY_CONSTANTS.clients, this.wrapFieldValueInParagraph(this.state.clientCount.toString())),
        ];

        return rowItems;
    }

    private buildPeerInfoRowItem(field: string, value: JSX.Element): JSX.Element[] {

        return [
            <p style={cellTextStyle} key={'field'}>{field}</p>,
            value
        ];
    }

    private wrapFieldValueInParagraph(value: string, additionalStyle?: CSSProperties): JSX.Element {

        return <p style={{ ...cellTextStyle, ...additionalStyle }}>{value}</p>;
    }

    private getFacilityLink(facility: Facility): JSX.Element {

        return (
            <Link
                to={`${ROUTES.facility}/${facility.firebaseFacilityID}`}
                style={cellLinkTextStyle}
            >
                {facility.name}
            </Link>
        );
    }

    private getPeerVisitRowItems(peerVisits: PeerVisit[]): JSX.Element[][] {

        const rowItems = peerVisits
            .sort((a: PeerVisit, b: PeerVisit) => a.compareToForDescendingDateSort(b))
            .map((peerVisit: PeerVisit, index: number) => this.buildPeerVisitRowItem(peerVisit, index));

        if (0 === rowItems.length) {
            rowItems.push(this.buildNoPeerVisitsRowItem());
        }

        return rowItems;
    }

    private buildPeerVisitRowItem(peerVisit: PeerVisit, index: number): JSX.Element[] {

        return [
            <p key={'category'} style={cellTextStyle}>{peerVisit.category}</p>,
            <p key={'date'} style={cellTextStyle}>{peerVisit.date.displayString}</p>,
            <p key={'time'} style={cellTextStyle}>{peerVisit.durationDisplay}</p>
        ];
    }

    private buildNoPeerVisitsRowItem(): JSX.Element[] {

        return [
            <p key={'message'} style={{ ...cellTextStyle, ...cellMessageTextStyle }}>{COPY_CONSTANTS.noPeerVisits}</p>,
        ];
    }

    private async deactivate(): Promise<void> {

        if (undefined === this.state.peer) {
            throw new Error('PeerDetail.deactivate: no peer');
        }

        this.state.peer.isActive = false;

        await this.props.store.userStore.deactivatePeer(this.state.peer);

        this.setState({ hasDeactivated: true });
    }

    public render(): JSX.Element | null {

        if (undefined === this.state.peer || undefined === this.state.peerVisits || undefined === this.state.facility) {
            return null;
        }

        if (this.state.hasDeactivated) {
            return <Redirect to={ROUTES.dashboard} />;
        }

        return (
            <div>

                <div className={this.props.store!.isModalShowing ? STYLE_CLASSES.DIV_BLUR : undefined}>

                    <div style={containerStyle}>

                        <Header title={this.state.peer.fullName} addButtons={[]} />

                        <ListPanel
                            title={COPY_CONSTANTS.peerInfo}
                            columnWidths={['50%', '50%']}
                            rowItems={this.getPeerInfoItems(this.state.peer, this.state.facility)}
                            style={peerInfoStyle}
                            titleRowButtons={[
                                <CustomButton
                                    key={COPY_CONSTANTS.edit}
                                    style={panelButtonStyle}
                                    styleClassName={STYLE_CLASSES.BUTTON_PANEL}
                                    onClick={() => this.setState({ isEditing: true })}
                                >

                                    {COPY_CONSTANTS.edit}

                                </CustomButton>,

                                <CustomButton
                                    key={COPY_CONSTANTS.deactivate}
                                    style={panelButtonStyle}
                                    styleClassName={STYLE_CLASSES.BUTTON_MAGENTA}
                                    onClick={() => this.setState({ isDeactivating: true })}
                                >

                                    {COPY_CONSTANTS.deactivate}

                                </CustomButton>
                            ]}
                        />

                        <ListPanel
                            title={COPY_CONSTANTS.peerVisits}
                            columnNames={[COPY_CONSTANTS.category, COPY_CONSTANTS.date, COPY_CONSTANTS.time]}
                            columnWidths={['30%', '30%', '30%']}
                            rowItems={this.getPeerVisitRowItems(this.state.peerVisits)}
                            style={peerVisitListStyle}
                        />

                    </div>

                </div>

                <PeerAddEditModal
                    isVisible={this.state.isEditing}
                    peerToEdit={this.state.peer}
                    onComplete={() => {
                        this.setState({ isEditing: false });
                        this.refreshData();
                    }}
                />

                <ConfirmationModal
                    isVisible={this.state.isDeactivating}
                    title={COPY_CONSTANTS.deactivatePeer}
                    message={COPY_CONSTANTS.deactivatePeerConfirmationMessage}
                    confirmButtonText={COPY_CONSTANTS.deactivate}
                    confirmButtonStyleClassName={STYLE_CLASSES.BUTTON_MAGENTA}
                    cancelButtonStyleClassName={STYLE_CLASSES.BUTTON_MEDIUM_LIGHT_GRAY}
                    onConfirm={() => this.deactivate()}
                    onCancel={() => this.setState({ isDeactivating: false })}
                />

                <Darkener isActive={this.props.store!.isModalShowing}/>

            </div>
        );

    }

}

const containerStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    padding: '50px',
    backgroundColor: STYLE_CONSTANTS.lightGray
};

const peerInfoStyle: CSSProperties = {
    width: '100%',
    minWidth: '400px'
};

const cellTextStyle: CSSProperties = {
    margin: '0',
    textDecoration: 'none',
    color: STYLE_CONSTANTS.darkGray
};

const cellLinkTextStyle: CSSProperties = {
    ...cellTextStyle,
    color: STYLE_CONSTANTS.darkerPurple
};

const peerVisitListStyle: CSSProperties = {
    marginTop: '50px'
};

const cellMessageTextStyle: CSSProperties = {
    fontStyle: 'italic'
};

const wordBreakAllStyle: CSSProperties = {
    wordBreak: 'break-all'
};
