import { CSSProperties } from 'react';
import styled from '../styled';
import { LOGIN_BACKGROUND } from '../images/images';
import { STYLE_CONSTANTS } from './StyleConstants';

export const panelButtonStyle: CSSProperties = {
    width: '100px',
    height: '34px',
    color: STYLE_CONSTANTS.white,
    fontSize: STYLE_CONSTANTS.fontSizeSmaller,
    verticalAlign: 'center',
    marginLeft: '10px'
};

export const pageTitleStyle: CSSProperties = {
    float: 'left',
    fontSize: STYLE_CONSTANTS.fontSizeTitle,
    color: STYLE_CONSTANTS.darkerGray,
    paddingRight: '10px'
};

export const rowStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '10px',
};

export const gridCellButtonStyle: CSSProperties = {
    width: '90px',
    height: '28px',
    color: STYLE_CONSTANTS.darkGreen,
    fontSize: STYLE_CONSTANTS.fontSizeSmaller,
    verticalAlign: 'center'
};

export const rightColumnFieldStyle: CSSProperties = {
    marginLeft: '50px'
};

export const closeRightColumnFieldStyle: CSSProperties = {
    marginLeft: '20px'
};

export const cellTextStyle: CSSProperties = {
    margin: '0',
    textDecoration: 'none',
    color: STYLE_CONSTANTS.darkGray
};

export const cellLinkTextStyle: CSSProperties = {
    ...cellTextStyle,
    color: STYLE_CONSTANTS.darkerPurple
};

export const cellOverflowHiddenTextStyle: CSSProperties = {
    ...cellTextStyle,
    overflow: 'hidden'
};

export const gridCellIconStyle: React.CSSProperties = {
    marginLeft: '30px'
};

export const gridCellWithExtraLeftMarginStyle: React.CSSProperties = {
    ...cellTextStyle,
    marginLeft: '10px'
};

export const bigButtonStyle: CSSProperties = {
    border: 'none',
    textTransform: 'none',
    color: 'white',
    width: '200px',
    height: '60px',
    fontSize: STYLE_CONSTANTS.fontSizePrimaryButton,
    marginTop: '30px'
};

export const lightGreenBigButtonStyle: CSSProperties = {
    ...bigButtonStyle,
    color: STYLE_CONSTANTS.darkGreen,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
};

export const floatingMenuStyle: CSSProperties = {
    background: undefined,
    backgroundColor: STYLE_CONSTANTS.white,
    color: STYLE_CONSTANTS.darkGray,
    border: `0.5px solid ${STYLE_CONSTANTS.mediumLightGray}`,
    borderRadius: '5px',
    position: 'absolute',
    top: '60px',
    right: '10px',
    width: '100px'
};

export const modalStyle: CSSProperties = {
    position: 'fixed',
    left: '5%',
    right: '5%',
    top: '40px',
    backgroundColor: STYLE_CONSTANTS.lightGray,
    boxSizing: 'border-box',
    borderRadius: '10px',
    maxHeight: '95%',
    overflow: 'scroll',
    zIndex: 3
};

export const emailCellStyle: React.CSSProperties = { overflowX: 'hidden' };

export const UnauthenticatedPageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background: url(${LOGIN_BACKGROUND}), linear-gradient(to right, #768ae9 , #b467d1);
    background-size: cover;
    background-position: center bottom;
    height: 100vh;
    margin: 0;
    border: 0;
`;

export const clientNotesCreatorStyle: CSSProperties = {
    color: STYLE_CONSTANTS.darkGray,
    border: `1.5px solid ${STYLE_CONSTANTS.mediumLightGray}`,
    minHeight: '300px',
    height: '60%',
    width: '100%',
    maxHeight: '500px', // eventually want a better way to manage this, but good enough for now.
    minWidth: '300px',
    display: 'flex',
    marginTop: '10px',
    resize_enabled: false,
    '--ck-focus-ring': '0px solid black',
    //'--ck-focus-outer-shadow': '0 0 0 0 0px',

}