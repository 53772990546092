import Reactotron from 'reactotron-react-js'
import { database } from 'firebase';
import { Provider } from 'mobx-react';
import * as React from 'react';
import { Switch } from 'react-router';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { FIREBASE_CONSTANTS } from './assets/FirebaseConstants';
import { ApplicationStore } from './data/stores/ApplicationStore';
import { NLFirebaseLogger } from './lib/NLFirebaseLogger';
import { ROUTES } from './Routes';
import { theme } from './styled/theme';
import './styles/App.css';
import { NavigationController } from './views/NavigationController';
import { Login } from './views/pages/Login';
import { PoliciesPage } from './views/pages/PoliciesPage';

const store = new ApplicationStore();
NLFirebaseLogger.setup(database().ref(FIREBASE_CONSTANTS.environment), {});

class App extends React.Component {

    public render(): JSX.Element {
        return (
            <Provider store={store}>
                <ThemeProvider theme={theme}>
                    <Router>
                        <div className="App">
                            <Switch>
                                <Route exact={true} path={ROUTES.login} component={Login} />
                                <Route path={ROUTES.termsAndConditions} component={PoliciesPage} />
                                <Route path={ROUTES.privacyPolicy} component={PoliciesPage} />
                                <Route path={ROUTES.privacyNotice} component={PoliciesPage} />
                                <Route component={NavigationController} />
                            </Switch>
                        </div>
                    </Router>
                </ThemeProvider>
            </Provider>
        );
    }

}

export default App;
