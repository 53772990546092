import * as React from 'react';
import { CSSProperties } from 'react';
import { STYLE_CONSTANTS, STYLE_CLASSES } from '../../assets/StyleConstants';

interface Props {
    label: string;
    validationError?: string;
    isStatic?: boolean;
    isModified?: boolean;
    isPlaceholderShowing: boolean;
    style?: CSSProperties;
    labelTextStyle?: CSSProperties;
    indicatorBarUnpopulatedStyle?: CSSProperties;
    icon?: any;
    iconDimensionPixels?: number;
}

export class FormField extends React.Component<Props> {

    private getIndicatorBarStyle(): CSSProperties {

        const combinedIndicatorBarUnpopulatedStyle = {
            ...indicatorBarUnpopulatedStyle,
            ...this.props.indicatorBarUnpopulatedStyle
        };

        return (
            this.props.isStatic
                ? combinedIndicatorBarUnpopulatedStyle
                :
                    '' === this.props.validationError
                        ? (
                            this.props.isModified
                                ? indicatorBarValidStyle
                                : combinedIndicatorBarUnpopulatedStyle
                        )
                        : indicatorBarInvalidStyle
        );
    }

    public render(): JSX.Element {

        const combinedContainerStyle = { ...containerStyle, ...this.props.style };

        const labelStyle = this.props.isPlaceholderShowing
            ? labelPlaceholderStyle
            : labelPopulatedStyle;

        let iconOverrideStyle: CSSProperties | undefined = undefined;

        if (this.props.iconDimensionPixels) {
            const iconDimensionPixels = `${this.props.iconDimensionPixels}px`;
            iconOverrideStyle = {
                width: iconDimensionPixels,
                height: iconDimensionPixels,
                minWidth: iconDimensionPixels,
                minHeight: iconDimensionPixels
            };
        }

        return (
            <div style={combinedContainerStyle}>

                { this.props.isStatic
                    ? null
                    :
                        <div
                            style={{ ...labelStyle, ...this.props.labelTextStyle }}
                            className={STYLE_CLASSES.FONT_WEIGHT_LIGHTER}
                        >
                            {this.props.label}
                        </div>
                }

                <div style={contentRowStyle}>

                    {this.props.icon &&
                        <div
                            style={{
                                    ...iconStyle,
                                    ...iconOverrideStyle,
                                    backgroundImage: `url(${this.props.icon})`
                            }}
                        >

                            &nbsp;

                        </div>
                    }

                    {this.props.children}

                </div>

                <div style={this.getIndicatorBarStyle()}/>

                {this.props.isStatic
                    ? null
                    :
                        <div
                            style={'' === this.props.validationError
                                ? validationNoErrorStyle
                                : validationErrorStyle}
                        >
                            {this.props.validationError || 'x'}
                        </div>
                }

            </div>
        );
    }
}

const containerStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
};

const labelPopulatedStyle: CSSProperties = {
    fontSize: STYLE_CONSTANTS.fontSizeSmall,
    fontWeight: 'bold',
    color: STYLE_CONSTANTS.mediumGray,
    marginBottom: '10px'
};

const labelPlaceholderStyle: CSSProperties = {
    visibility: 'hidden',
    ...labelPopulatedStyle
};

const indicatorBarBaseStyle: CSSProperties = {
    width: '100%',
    height: '2px',
    borderRadius: '1px',
    marginTop: '10px'
};

const indicatorBarValidStyle: CSSProperties = {
    backgroundColor: STYLE_CONSTANTS.brightGreen,
    ...indicatorBarBaseStyle
};

const indicatorBarInvalidStyle: CSSProperties = {
    backgroundColor: STYLE_CONSTANTS.invalidRed,
    ...indicatorBarBaseStyle
};

const indicatorBarUnpopulatedStyle: CSSProperties = {
    backgroundColor: STYLE_CONSTANTS.purpleGray,
    ...indicatorBarBaseStyle
};

const validationErrorStyle: CSSProperties = {
    fontSize: STYLE_CONSTANTS.fontSizeSmall,
    fontWeight: 'bold',
    color: STYLE_CONSTANTS.invalidRed,
    marginTop: '10px'
};

const validationNoErrorStyle: CSSProperties = {
    visibility: 'hidden',
    ...validationErrorStyle
};

const contentRowStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'row'
};

const iconStyle: CSSProperties = {
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    width: '20px',
    height: '20px',
    minWidth: '20px',
    minHeight: '20px',
    marginRight: '10px',
    marginTop: 'auto',
    marginBottom: 'auto'
};
