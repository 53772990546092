import { ItemKeyAndDisplayValue } from '../../util/common';

export enum PeerVisitCategory {
    Unassigned = 'n/a',
    Education = 'Education',
    Advocacy = 'Advocacy',
    Mentoring = 'Mentoring',
    SupportGroup = 'Support group',
    ClientAppointment = 'Client appointment',
    ResourceGuidance = 'Resource guidance',
    NonclinicalRecoverySupport = 'Nonclinical recovery support'
}

export function getAllCategories(): ItemKeyAndDisplayValue[] {

    const keysAndDisplayValues: ItemKeyAndDisplayValue[] = [];

    Object.keys(PeerVisitCategory)
        .forEach((key: string) => 
            keysAndDisplayValues.push({
                    key: key,
                    display: PeerVisitCategory[key]
            })
        );

    return keysAndDisplayValues;
}
