import * as moment from 'moment';
import * as React from 'react';
import { VictoryAxis, VictoryChart, VictoryLine, VictoryScatter } from 'victory';
import { COPY_CONSTANTS } from '../../assets/CopyConstants';
import { STYLE_CONSTANTS } from '../../assets/StyleConstants';
import { Client } from '../../data/models/Client';
import { ClientReflection } from '../../data/models/ClientReflection';
import { Date } from '../../data/models/Date';
import { GraphTimeWindow } from '../../data/models/GraphTimeWindow';
import { CustomButton } from './Button';

type HealthMetricKeys = keyof Pick<ClientReflection, 'overallFeelingRating' | 'sleepRating' | 'foodRating' | 'exerciseRating'>;
const ALL_HEALTH_METRIC_KEYS: HealthMetricKeys[] = ['overallFeelingRating', 'sleepRating', 'foodRating', 'exerciseRating'];

interface Props {
    client: Client;
    numberOfDaysViewing: GraphTimeWindow;
    style?: React.CSSProperties;
    headingStyle?: React.CSSProperties;
}

interface State {
    visibleHealthMetrics: HealthMetricKeys[];
}

const HealthGraphColors = {
    overallFeelingRating: '#49DBA7',
    sleepRating: '#9C71FF',
    foodRating: '#FFA779',
    exerciseRating: '#30B0F6'
};

const HealthGraphReadableNames = {
    overallFeelingRating: 'Overall Feeling',
    sleepRating: 'Sleep',
    foodRating: 'Food',
    exerciseRating: 'Exercise'
};

export class HealthMetricsGraph extends React.Component<Props, State> {
    public state: State = { visibleHealthMetrics: ALL_HEALTH_METRIC_KEYS };

    private getDataFromClient(): Record<HealthMetricKeys, [string, number][]> {
        if (null == this.props.client) {
            throw new Error('Cannot get reflections without a client');
        }

        const reflectionsByDate = Array.from(this.props.client.reflectionsByDate.entries())
            .sort(([a], [b]) => a.localeCompare(b));
        return ALL_HEALTH_METRIC_KEYS.reduce(
            (data, key) => {
                const averagesByDate = reflectionsByDate.map<[string, number]>(
                    ([date, reflections]) => [
                        date, reflections.reduce((sum, reflection) => sum + reflection[key], 0) / reflections.length
                    ]
                );
                return { ...data, [key]: averagesByDate };
            },
            {
                overallFeelingRating: [],
                foodRating: [],
                sleepRating: [],
                exerciseRating: []
            }
        );
    }

    private handleClickIndividualMetric(key: HealthMetricKeys): void {
        const indexOfKey = this.state.visibleHealthMetrics.indexOf(key);
        let newVisibleHealthMetrics = [...this.state.visibleHealthMetrics];
        if (indexOfKey < 0) {
            newVisibleHealthMetrics.push(key);
        } else {
            newVisibleHealthMetrics.splice(indexOfKey, 1);
        }
        this.setState({ visibleHealthMetrics: newVisibleHealthMetrics });
    }

    private get xAxisTickFormatter(): (index: number) => string {
        if (this.props.numberOfDaysViewing === GraphTimeWindow.Weekly) {
            return index => {
                const date = this.momentFromXAxisIndex(index);
                return date.format('dd')[0];
            };
        }
        return index => {
            const date = this.momentFromXAxisIndex(index);
            return date.format('M/D');
        };
    }

    private momentFromXAxisIndex(tick: number): moment.Moment {
        return moment().subtract(this.props.numberOfDaysViewing - tick, 'days');
    }

    public render(): JSX.Element {
        const data = this.getDataFromClient();
        const yAxisExtraProps: {} = {
            style: {
                grid: {
                    stroke: (t: number) => (t > 0 ? STYLE_CONSTANTS.lightGray : 'none')
                },
                tickLabels: tickLabelStyle
            }
        };

        const xAxisTickValues = this.props.numberOfDaysViewing === GraphTimeWindow.Monthly ? [2, 9, 16, 23, 30] : [1, 2, 3, 4, 5, 6, 7];

        const today = Date.createForToday();

        const commonDataProps = {
            x: (point: [string, number]) => this.props.numberOfDaysViewing + (new Date(point[0]).diff(today)),
            y: (point: [string, number]) => point[1],
            animate: { duration: 500 },
            domain: { x: [1, this.props.numberOfDaysViewing] as [number, number] }
        };

        return (
            <div style={this.props.style}>
                <p style={this.props.headingStyle}>{COPY_CONSTANTS.overview}</p>
                <div style={graphContainerStyle}>
                    <VictoryChart padding={{ top: 0, bottom: 30, left: 35, right: 50 }} height={200} >
                        <VictoryAxis
                            tickFormat={this.xAxisTickFormatter}
                            tickValues={xAxisTickValues}
                            domain={[1, this.props.numberOfDaysViewing]}
                            standalone={false}
                            style={{ grid: { stroke: STYLE_CONSTANTS.lightGray }, tickLabels: tickLabelStyle }}
                        />
                        <VictoryAxis
                            domain={{ y: [0, 5.9] }}
                            tickValues={[0, 1, 2, 3, 4, 5]}
                            dependentAxis={true}
                            {...yAxisExtraProps}
                        />
                        {ALL_HEALTH_METRIC_KEYS.map(key => {
                            const dataPropsForKey = {
                                ...commonDataProps,
                                data: data[key],
                                key
                            };
                            return data[key].length > 1
                                ? <VictoryLine
                                    interpolation={'monotoneX'}
                                    style={{ data: { stroke: HealthGraphColors[key], strokeWidth: this.state.visibleHealthMetrics.indexOf(key) >= 0 ? 2 : 0 } }}
                                    {...dataPropsForKey}
                                />
                                : <VictoryScatter
                                    style={{ data: { fill: HealthGraphColors[key], fillOpacity: this.state.visibleHealthMetrics.indexOf(key) >= 0 ? 1 : 0 } }}
                                    {...dataPropsForKey}
                                />;
                        })}
                    </VictoryChart>
                    <div style={buttonRowStyle}>
                        {ALL_HEALTH_METRIC_KEYS.map(
                            key => (
                                <CustomButton
                                    key={key}
                                    onClick={() => this.handleClickIndividualMetric(key)}
                                >
                                    <span
                                        style={{
                                            ...metricButtonLabelStyle,
                                            color: this.state.visibleHealthMetrics.indexOf(key) >= 0 ? HealthGraphColors[key] : STYLE_CONSTANTS.mediumGray
                                        }}
                                    >
                                        {HealthGraphReadableNames[key]}
                                    </span>
                                </CustomButton>
                            )
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

const buttonRowStyle: React.CSSProperties = { display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingRight: 40 };
const graphContainerStyle: React.CSSProperties = { display: 'flex', flex: 1, flexDirection: 'column' };
const metricButtonLabelStyle: React.CSSProperties = { fontSize: STYLE_CONSTANTS.fontSizeSmall, fontWeight: 'bold' };
const tickLabelStyle: React.CSSProperties = { fontSize: STYLE_CONSTANTS.fontSizeSmall, color: '#5C5C5C', fontFamily: 'Avenir, Nunito, sans-serif', fontWeight: 'lighter' };

