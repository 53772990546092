import * as assert from 'assert';
import * as moment from 'moment';
import { momentToDateString } from '../../util/common';
import { IFirebaseClientReflectionWithDate } from './firebase/FirebaseClientReflection';

export class ClientReflection {

    private _dateTimeIsoString: string;

    private _overallFeelingRating: number;
    private _sleepRating: number;
    private _exerciseRating: number;
    private _foodRating: number;
    private _emotions: Map<string, string[]>;
    private _usedNonMedicalDrugs: boolean;
    private _nonMedicalDrugsCausedProblems: boolean | undefined;
    private _nonMedicalDrugsAreConcerned: boolean | undefined;
    private _prescriptionMedication: boolean | undefined;
    private _hadTraumaDream: boolean;
    private _dreamDescription: string | undefined;
    private _connectedWithTrustedPerson: boolean;
    private _hadPositiveSocialInteractions: boolean;
    private _attendedGroupsCount: string;
    private _anxietySources: string[] | undefined;
    private _completedYesterdaysGoal: boolean;
    private _todaysGoal: string;
    private _thankfulFor: string;

    private constructor() { }

    public get overallFeelingRating(): number {
        return this._overallFeelingRating;
    }
    public get sleepRating(): number {
        return this._sleepRating;
    }
    public get exerciseRating(): number {
        return this._exerciseRating;
    }
    public get foodRating(): number {
        return this._foodRating;
    }
    public get emotions(): Map<string, string[]> {
        return this._emotions;
    }
    public get usedNonMedicalDrugs(): boolean {
        return this._usedNonMedicalDrugs;
    }
    public get nonMedicalDrugsCausedProblems(): boolean | undefined {
        return this._nonMedicalDrugsCausedProblems;
    }
    public get nonMedicalDrugsAreConcerned(): boolean | undefined {
        return this._nonMedicalDrugsAreConcerned;
    }
    public get prescribedMedication(): boolean | undefined {
        return this._prescriptionMedication;
    }
    public get hadTraumaDream(): boolean {
        return this._hadTraumaDream;
    }
    public get dreamDescription(): string | undefined {
        return this._dreamDescription;
    }
    public get connectedWithTrustedPerson(): boolean {
        return this._connectedWithTrustedPerson;
    }
    public get hadPositiveSocialInteractions(): boolean {
        return this._hadPositiveSocialInteractions;
    }
    public get attendedGroupsCount(): string {
        return this._attendedGroupsCount;
    }
    public get anxietySources(): string[] | undefined {
        return this._anxietySources;
    }
    public get completedYesterdaysGoal(): boolean {
        return this._completedYesterdaysGoal;
    }
    public get todaysGoal(): string {
        return this._todaysGoal;
    }
    public get thankfulFor(): string {
        return this._thankfulFor;
    }

    public get dateTimeIsoString(): string {
        return this._dateTimeIsoString;
    }

    public get dateDisplay(): string {
        return momentToDateString(moment(this._dateTimeIsoString));
    }

    public get dateTimeCompareString(): string {
        return this._dateTimeIsoString;
    }

    public static createFromFirebase(firebaseClientReflectionWithDate: IFirebaseClientReflectionWithDate): ClientReflection {

        const clientReflection = new ClientReflection();

        assert(null != firebaseClientReflectionWithDate, 'ClientReflection constructor passed args with null firebaseClientReflectionWithDate');
        assert(null != firebaseClientReflectionWithDate.reflection, 'ClientReflection constructor passed args with null reflection');
        assert(null != firebaseClientReflectionWithDate.date, 'ClientReflection constructor passed args with null date');
        assert(typeof firebaseClientReflectionWithDate.date === 'string', 'ClientReflection constructor passed args with invalid date');
        clientReflection._dateTimeIsoString = firebaseClientReflectionWithDate.date;

        const {
            overallFeeling,
            sleep,
            exercise,
            food,
            emotions,
            anxietySources,
            support,
            nonMedicalDrugReflection,
            prescriptionMedicine,
            goals,
            thankfulFor
        } = firebaseClientReflectionWithDate.reflection;
        assert(null != overallFeeling, 'ClientReflection constructor passed args with null overallFeeling');
        assert(null != sleep, 'ClientReflection constructor passed args with null sleep');
        assert(null != exercise, 'ClientReflection constructor passed args with null exercise');
        assert(null != food, 'ClientReflection constructor passed args with null food');
        assert(null != emotions, 'ClientReflection constructor passed args with null emotions');
        assert(null != support, 'ClientReflection constructor passed args with null support');
        assert(null != nonMedicalDrugReflection, 'ClientReflection constructor passed args with null nonMedicalDrugReflection');
        assert(null != goals, 'ClientReflection constructor passed args with null goals');

        clientReflection._overallFeelingRating = overallFeeling.rating;
        clientReflection._sleepRating = sleep.rating;
        clientReflection._exerciseRating = exercise.rating;
        clientReflection._foodRating = food.rating;
        clientReflection._emotions = Object.keys(emotions).reduce(
            (map, emotion) => map.set(
                emotion,
                true === emotions[emotion] ? [] : Object.keys(emotions[emotion])),
            new Map<string, string[]>()
        );
        clientReflection._anxietySources = anxietySources;
        clientReflection._attendedGroupsCount = support.attendedGroupsCount;
        clientReflection._connectedWithTrustedPerson = support.connectedWithTrustedPerson;
        clientReflection._hadPositiveSocialInteractions = support.hadPositiveSocialInteractions || false;
        clientReflection._dreamDescription = sleep.dreamDescription;
        clientReflection._hadTraumaDream = sleep.hadTraumaDream;
        clientReflection._nonMedicalDrugsAreConcerned = nonMedicalDrugReflection.areConcerned;
        clientReflection._nonMedicalDrugsCausedProblems = nonMedicalDrugReflection.causedProblems;
        clientReflection._usedNonMedicalDrugs = nonMedicalDrugReflection.used;
        clientReflection._prescriptionMedication = prescriptionMedicine;
        clientReflection._todaysGoal = goals.todaysGoal;
        clientReflection._completedYesterdaysGoal = goals.completedYesterday;
        clientReflection._thankfulFor = thankfulFor;

        return clientReflection;
    }

}
