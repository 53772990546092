import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { CSSProperties } from 'react';
import { COPY_CONSTANTS } from '../../assets/CopyConstants';
import { STYLE_CLASSES, STYLE_CONSTANTS } from '../../assets/StyleConstants';
import { ApplicationStore } from '../../data/stores/ApplicationStore';
import { Header } from '../components/Header';
import { ClientAddEditModal } from './ClientAddEditModal';
import { PeerVisitAddEditModal } from './PeerVisitAddEditModal';
import { Darkener } from './Darkener';

export interface Props {
    store?: ApplicationStore;
    title: string;
    searchText?: string;
    onSearchTextChanged?: (newValue: string) => void;
    onAddClientClick?: () => void;
    onAddTimeClick?: () => void;
}

interface State {
    isAddingClient: boolean;
    isAddingTime: boolean;
}

@inject('store')
@observer
export class PeerPage extends React.Component<Props, State> {
    public state: State = { isAddingClient: false, isAddingTime: false };

    private showAddClient = () => this.setState({ isAddingClient: true });

    private showAddTime = () => this.setState({ isAddingTime: true });

    private hideAddClient = () => this.setState({ isAddingClient: false });

    private hideAddTime = () => this.setState({ isAddingTime: false });

    public render(): JSX.Element | null {

        return (
            <div>
                <div className={this.props.store!.isModalShowing ? STYLE_CLASSES.DIV_BLUR : undefined}>

                    <div style={containerStyle}>

                        <Header
                            title={this.props.title}
                            searchText={this.props.searchText}
                            onSearchTextChanged={this.props.onSearchTextChanged}
                            addButtons={[
                                { name: COPY_CONSTANTS.addClient, action: this.props.onAddClientClick || this.showAddClient },
                                { name: COPY_CONSTANTS.addTime, action: this.props.onAddTimeClick || this.showAddTime }
                            ]}
                        />

                        {this.props.children}


                    </div>

                </div>

                <ClientAddEditModal
                    isVisible={this.state.isAddingClient}
                    onClose={this.hideAddClient}
                    onComplete={this.hideAddClient}
                />

                <PeerVisitAddEditModal
                    peerUserFirebaseID={this.props.store!.authorizationStore.firebaseUser.uid}
                    isVisible={this.state.isAddingTime}
                    onComplete={this.hideAddTime}
                />

                <Darkener isActive={this.props.store!.isModalShowing}/>

            </div>
        );
    }

}

const containerStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    padding: '50px',
    backgroundColor: STYLE_CONSTANTS.lightGray
};
