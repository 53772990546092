import * as React from 'react';
import { Client } from '../../data/models/Client';
import { FLAG_ICON } from '../../images/images';
import { ClientLink } from './ClientLink';

interface Props {
    client: Client;
}

export const ClientLinkWithFlag: React.SFC<Props> = ({ client }) => (
    <ClientLink client={client}>
        <span key={client.firebaseUid} style={flagContainerStyle}>
            {client.isFlagged && <img src={FLAG_ICON} />}
        </span>
        {client.firstName}
    </ClientLink>
);

const flagContainerStyle: React.CSSProperties = {
    width: 25,
    alignItems: 'center',
    display: 'flex'
};