// interface Row extends Airtable.FieldSet {
//     initial_message_body: string;
// }

export class StatsStore {

    // @observable
    // private _activePeersByID: ObservableMap<string, Peer> = new ObservableMap<string, Peer>();

    // @computed
    // public get activePeers(): Peer[] {
    //     return Array.from(this._activePeersByID.values());
    // }

    // public reset(): void {
    //     this._activePeersByID.clear();
    // }

    // public async getStats(): Promise<Row[]> {
    //     const base = new Airtable({ apiKey: API_KEY }).base(APP_ID);
    //     const table = base('RECOVREE_BOT_RESPONSES') as Airtable.Table<Row>;

    //     table.select({view: 'Minnedemo'}).all();
    //     const query = base('RECOVREE_BOT_RESPONSES').select({view: 'Minnedemo'});
    //     {
    //         const rows = await query.all();
    //         for (const row of rows) {
    //             // console.log('ID: ', row.id);
    //             console.log(row.fields['initial_message_body']);
    //         }

    //         return rows
    //     }
    // }

}
