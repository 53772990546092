import * as React from 'react';
import { CSSProperties } from 'react';
import { STYLE_CONSTANTS } from '../../assets/StyleConstants';
import { Panel } from './Panel';

interface Props {
    style?: React.CSSProperties;
    title: string | JSX.Element;
    columnNames?: string[];
    columnWidths: string[];
    rowItems: JSX.Element[][];
    titleRowButtons?: JSX.Element[];
    titleRowButtonContainerStyle?: CSSProperties;
    minimumRowCount?: number;
    maxHeight?: number | string;
}

export class ListPanel extends React.Component<Props, {}> {

    private getCellStyle(columnIndex: number): CSSProperties {
        return {
            width: this.props.columnWidths[columnIndex],
            display: 'flex',
            alignItems: 'center'
        };
    }

    private renderRow(rowItem: JSX.Element[], itemIndex: number): JSX.Element {
        const rowColorStyle = (
            0 === itemIndex % 2
                ? dataRowEvenStyle
                : dataRowOddStyle);

        const rowContent = rowItem.map(
            (cellContent, index) => <div key={index} style={this.getCellStyle(index)}>{cellContent}</div>
        );

        return (
            <div style={{ ...rowColorStyle, ...dataRowStyle }} key={itemIndex}>
                {rowContent}
            </div>
        );
    }

    private renderRows(): JSX.Element[] {
        if (null == this.props.minimumRowCount
            || this.props.rowItems.length >= this.props.minimumRowCount) {
            return this.props.rowItems.map(
                (rowItem, itemIndex) => this.renderRow(rowItem, itemIndex)
            );
        }
        const emptyRow = Array(this.props.columnWidths.length).fill(0).map((_, index) => <span key={index} />);
        return Array(this.props.minimumRowCount).fill(0).map((_, index) => this.renderRow(this.props.rowItems[index] || emptyRow, index));
    }

    public render(): JSX.Element {

        return (
            <Panel
                title={this.props.title}
                style={this.props.style}
                titleRowButtons={this.props.titleRowButtons}
                titleRowButtonContainerStyle={this.props.titleRowButtonContainerStyle}
            >
                {this.props.columnNames &&
                    <div style={headerRowStyle}>
                        {this.props.columnNames.map(
                            (columnName, index) => <div key={columnName} style={this.getCellStyle(index)}>{columnName}</div>)}
                    </div>
                }
                <div style={{ maxHeight: this.props.maxHeight, overflowY: undefined != this.props.maxHeight ? 'scroll' : undefined }}>
                    {this.renderRows()}
                </div>
            </Panel>
        );
    }
}

const headerRowStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: STYLE_CONSTANTS.darkerPurple,
    padding: '12px',
    paddingLeft: '20px',
    fontSize: STYLE_CONSTANTS.fontSizeSmall
};

const dataRowStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    color: STYLE_CONSTANTS.darkGray,
    paddingRight: '20px',
    paddingLeft: '20px',
    minHeight: '40px'
};

const dataRowOddStyle: CSSProperties = {
    backgroundColor: STYLE_CONSTANTS.lighterGray
};

const dataRowEvenStyle: CSSProperties = {
    backgroundColor: STYLE_CONSTANTS.lightestGray
};
