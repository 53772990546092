import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { CSSProperties } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { panelButtonStyle } from '../../assets/CommonStyles';
import { COPY_CONSTANTS } from '../../assets/CopyConstants';
import { STYLE_CLASSES, STYLE_CONSTANTS } from '../../assets/StyleConstants';
import { Facility } from '../../data/models/Facility';
import { Peer } from '../../data/models/Peer';
import { ApplicationStore } from '../../data/stores/ApplicationStore';
import { ROUTES } from '../../Routes';
import { CustomButton } from '../components/Button';
import { FacilityAddEditModal } from '../components/FacilityAddEditModal';
import { FloatingMenu } from '../components/FloatingMenu';
import { Header } from '../components/Header';
import { ListPanel } from '../components/ListPanel';
import { PeerAddEditModal } from '../components/PeerAddEditModal';
import { Darkener } from '../components/Darkener';

interface Props extends RouteComponentProps<{}> {
    store?: ApplicationStore;
}

interface State {
    sortByFacility: boolean;
    isAddingFacility: boolean;
    isAddingPeer: boolean;
    isSortMenuOpen: boolean;
    sortBy: SortField;
}

enum SortField {
    Name = 'Name',
    Facility = 'Facility'
}

@inject('store')
@observer
export class FounderDashboard extends React.Component<Props, State> {

    public constructor(props: Props) {
        super(props);
        this.state = {
            sortByFacility: false,
            isAddingFacility: false,
            isAddingPeer: false,
            isSortMenuOpen: false,
            sortBy: SortField.Name
        };
    }

    public componentWillMount(): void {
        this.props.store!.facilityStore.listen();
    }

    public componentWillUnmount(): void {
        this.props.store!.facilityStore.unlisten();
    }

    private getRowItems(): JSX.Element[][] {

        const noPeerFacilitiesByFacilityID = new Map<string, Facility>(this.props.store!.facilityStore.facilitiesByID);
        const rowItems: JSX.Element[][] = [];

        this.props.store!.userStore.activePeers
            .sort((a: Peer, b: Peer) =>
                this.comparePeers(a, b, noPeerFacilitiesByFacilityID))
            .forEach((peer: Peer) => {

                rowItems.push(this.buildPeerRowItem(peer));

                if (noPeerFacilitiesByFacilityID.has(peer.facilityFirebaseID)) {
                    noPeerFacilitiesByFacilityID.delete(peer.facilityFirebaseID);
                }
            });

        Array.from(noPeerFacilitiesByFacilityID.values())
            .sort((a: Facility, b: Facility) => a.compareToForSort(b))
            .forEach((facility: Facility) => {
                rowItems.push(this.buildNoPeerFacilityRowItem(facility));
            });

        if (0 === rowItems.length) {
            rowItems.push(this.buildNoPeersRowItem());
        }

        return rowItems;
    }

    private comparePeers(a: Peer, b: Peer, noPeerFacilitiesByFacilityID: Map<string, Facility>): number {

        if (this.state.sortBy === SortField.Facility) {
            if (a.facilityFirebaseID === b.facilityFirebaseID) {
                return a.compareToForSort(b);
            }
            const facilityA = noPeerFacilitiesByFacilityID.get(a.facilityFirebaseID);
            const facilityB = noPeerFacilitiesByFacilityID.get(b.facilityFirebaseID);
            if (!facilityA || !facilityB) {
                return 0;
            }
            return facilityA.compareToForSort(facilityB);
        } else {
            return a.compareToForSort(b);
        }
    }

    private buildPeerRowItem(peer: Peer): JSX.Element[] {

        return [
            this.getPeerLink(peer),
            <p key={'email'} style={emailCellTextStyle}>{peer.emailAddress}</p>,
            this.getFacilityLink(peer.facilityFirebaseID)
        ];
    }

    private buildNoPeerFacilityRowItem(facility: Facility): JSX.Element[] {
        return this.buildMessageRowItem(
            COPY_CONSTANTS.facilityHasNoPeers,
            this.getFacilityLink(facility.firebaseFacilityID)
        );
    }

    private buildNoPeersRowItem(): JSX.Element[] {
        return this.buildMessageRowItem(
            COPY_CONSTANTS.noPeers,
            <p style={cellTextStyle}>&nbsp;</p>
        );
    }

    private buildMessageRowItem(message: string, facilityElement: JSX.Element | null): JSX.Element[] {

        const messageRowItem = [<p key={'message'} style={{ ...cellTextStyle, ...cellMessageTextStyle }}>{message}</p>];

        if (facilityElement) {
            messageRowItem.push(<p key={'blank'} style={cellTextStyle} />);
            messageRowItem.push(facilityElement);
        }

        return messageRowItem;
    }

    private getPeerLink(peer: Peer): JSX.Element {

        return (
            <Link
                to={`${ROUTES.peer}/${peer.firebaseUid}`}
                style={cellLinkTextStyle}
            >
                {peer.fullName}
            </Link>
        );
    }

    private getFacilityLink(facilityFirebaseID: string): JSX.Element {

        const facility = this.props.store!.facilityStore.facilitiesByID.get(facilityFirebaseID);

        return (
            <Link
                to={`${ROUTES.facility}/${facilityFirebaseID}`}
                style={cellLinkTextStyle}
            >
                {facility ? facility.name : ''}
            </Link>
        );
    }

    private openSortMenu = () => this.setState({ isSortMenuOpen: true });
    private closeSortMenu = () => this.setState({ isSortMenuOpen: false });

    private handleSortOptionClicked = (option: SortField) => this.setState({ sortBy: option, isSortMenuOpen: false });

    public render(): JSX.Element | null {

        return (
            <div>

                <div className={this.props.store!.isModalShowing ? STYLE_CLASSES.DIV_BLUR : undefined}>
                    <div style={containerStyle}>
                        <Header
                            title={COPY_CONSTANTS.dashboard + ' Feb 4 2019 release'}
                            addButtons={[
                                { name: COPY_CONSTANTS.addPeer, action: () => this.setState({ isAddingPeer: true }) },
                                { name: COPY_CONSTANTS.addFacility, action: () => this.setState({ isAddingFacility: true }) }
                            ]}
                        />

                        <ListPanel
                            title={COPY_CONSTANTS.peers}
                            columnNames={[
                                COPY_CONSTANTS.name,
                                COPY_CONSTANTS.email,
                                COPY_CONSTANTS.facility
                            ]}
                            columnWidths={
                                [
                                    '30%',
                                    '30%',
                                    '30%'
                                ]
                            }
                            rowItems={this.getRowItems()}
                            titleRowButtons={[
                                (
                                    <div key="sort">
                                        <CustomButton
                                            key={COPY_CONSTANTS.sort}
                                            style={panelButtonStyle}
                                            styleClassName={STYLE_CLASSES.BUTTON_PANEL}
                                            onClick={this.openSortMenu}
                                        >

                                            {COPY_CONSTANTS.sort}

                                        </CustomButton>
                                        <FloatingMenu
                                            isOpen={this.state.isSortMenuOpen}
                                            options={[
                                                SortField.Name,
                                                SortField.Facility
                                            ]}
                                            onOptionClicked={this.handleSortOptionClicked}
                                            onExternalClick={this.closeSortMenu}
                                            backgroundColor={STYLE_CONSTANTS.white}
                                            style={sortMenuStyle}
                                            optionStyle={sortMenuOptionStyle}
                                            optionStyleClassName={STYLE_CLASSES.BUTTON_WHITE}
                                            highlightedOptionStyleClassName={STYLE_CLASSES.BUTTON_MEDIUM_LIGHT_GRAY}
                                        />
                                    </div>
                                )
                            ]}
                        />

                    </div>

                </div>

                <FacilityAddEditModal
                    isVisible={this.state.isAddingFacility}
                    onComplete={() => this.setState({ isAddingFacility: false })}
                />

                <PeerAddEditModal
                    isVisible={this.state.isAddingPeer}
                    onComplete={() => this.setState({ isAddingPeer: false })}
                />

                <Darkener isActive={this.props.store!.isModalShowing}/>

            </div>
        );

    }
}

const containerStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    padding: '50px',
    backgroundColor: STYLE_CONSTANTS.lightGray
};

const cellTextStyle: CSSProperties = {
    margin: '0',
    textDecoration: 'none',
    color: STYLE_CONSTANTS.darkGray
};

const cellLinkTextStyle: CSSProperties = {
    ...cellTextStyle,
    color: STYLE_CONSTANTS.darkerPurple
};

const emailCellTextStyle: CSSProperties = {
    ...cellTextStyle,
    wordBreak: 'break-all'
};

const cellMessageTextStyle: CSSProperties = {
    fontStyle: 'italic'
};

const sortMenuStyle: React.CSSProperties = {
    top: '5px',
    left: '5px',
    width: '100px'
};

const sortMenuOptionStyle: React.CSSProperties = {
    color: STYLE_CONSTANTS.darkGray,
    fontSize: STYLE_CONSTANTS.fontSizeSmall
};
