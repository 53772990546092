import { action, computed, observable, ObservableMap } from 'mobx';
import * as moment from 'moment';
import { assertNotNull } from '../../util/assert';
import { ItemKeyAndDisplayValue } from '../../util/common';
import { Client } from '../models/Client';
import { ClientReflection } from '../models/ClientReflection';
import { Date } from '../models/Date';
import { IFirebaseClientReflectionWithDate } from '../models/firebase/FirebaseClientReflection';
import { IFirebaseUsers, IFirebaseUserWithID } from '../models/firebase/FirebaseUser';
import { Founder } from '../models/Founder';
import { Peer } from '../models/Peer';
import { PeerVisit } from '../models/PeerVisit';
import { PortalUser } from '../models/PortalUser';
import { User } from '../models/User';
import { UserType } from '../models/UserType';
import { ClientReflectionService } from '../services/ClientReflectionService';
import { UserService } from '../services/UserService';

const CLASS_NAME = 'UserStore';

export class UserStore {

    private readonly assertNotNull = assertNotNull(CLASS_NAME);

    @observable
    private _activePeersByID: ObservableMap<string, Peer> = new ObservableMap<string, Peer>();

    @observable
    private _clientsForPeerByID: ObservableMap<string, Client> = new ObservableMap();

    @computed
    public get activePeers(): Peer[] {
        return Array.from(this._activePeersByID.values());
    }

    @computed
    public get activeClients(): Client[] {
        return Array.from(this._clientsForPeerByID.values()).sort((a, b) => a.firstName.localeCompare(b.firstName));
    }

    @computed
    public get activeClientsForPeerByID(): Map<string, Client> {
        return new Map<string, Client>(this._clientsForPeerByID);
    }

    public reset(): void {
        this._activePeersByID.clear();
        this._clientsForPeerByID.clear();
    }

    public async getPeer(peerUserFirebaseID: string): Promise<Peer> {

        const firebaseUserWithID = await this.getFirebaseUserWithID(peerUserFirebaseID);
        return this.constructPeer(firebaseUserWithID);
    }

    public async getClient(clientUserFirebaseID: string): Promise<Client> {

        const firebaseUserWithID = await this.getFirebaseUserWithID(clientUserFirebaseID);
        const client = this.constructClient(firebaseUserWithID);

        ClientReflectionService.listenClientReflections(
            firebaseUserWithID.firebaseID,
            (clientUserID: string, firebaseReflectionWithDate: IFirebaseClientReflectionWithDate) => {
                this.addReflectionToClient(firebaseReflectionWithDate, client);
            }
        );

        return client;
    }

    private addReflectionToClient(firebaseReflectionWithDate: IFirebaseClientReflectionWithDate, client: Client): void {
        const dateString = Date.fromMoment(moment(firebaseReflectionWithDate.date)).dateString;
        let reflectionsForDate = client.reflectionsByDate.get(dateString) || [];
        const clientReflection = ClientReflection.createFromFirebase(firebaseReflectionWithDate);
        reflectionsForDate.push(clientReflection);
        client.reflectionsByDate.set(dateString, reflectionsForDate);
    }

    private async getFirebaseUserWithID(userFirebaseID: string): Promise<IFirebaseUserWithID> {

        const firebaseUser = await UserService.getUser(userFirebaseID);

        return {
            user: firebaseUser,
            firebaseID: userFirebaseID
        };
    }

    public async getActivePeersForFacility(facilityFirebaseID: string): Promise<Peer[]> {

        const firebaseUsers = await UserService.getPeersForFacility(facilityFirebaseID);

        if (undefined == firebaseUsers) {
            return [];
        }

        return Object.keys(firebaseUsers)
            .map((peerUserFirebaseID: string) => {

                const firebaseUser = firebaseUsers[peerUserFirebaseID];

                const firebaseUserWithID: IFirebaseUserWithID = {
                    user: firebaseUser,
                    firebaseID: peerUserFirebaseID
                };

                return this.constructPeer(firebaseUserWithID);
            })
            .filter((peer: Peer) => peer.isActive);
    }

    public async getClientsByIDFromPeerVisits(peerVisits: PeerVisit[]): Promise<Map<string, Client>> {

        const peerVisitClientUserFirebaseIDs = new Set<string>(
            peerVisits
                .filter((peerVisit: PeerVisit) =>
                    undefined !== peerVisit.clientUserFirebaseID
                ).map((peerVisit: PeerVisit) => peerVisit.clientUserFirebaseID!)
        );

        const firebaseUsers = await UserService.getClientsByIDs(peerVisitClientUserFirebaseIDs);

        return this.constructClientsMap(firebaseUsers);
    }

    private constructClientsMap(clientFirebaseUsers: IFirebaseUsers): Map<string, Client> {

        const clientsByID = new Map<string, Client>();

        if (undefined == clientFirebaseUsers) {
            return clientsByID;
        }

        Object.keys(clientFirebaseUsers)
            .forEach((clientUserFirebaseID: string) => {

                const firebaseUser = clientFirebaseUsers[clientUserFirebaseID];

                const firebaseUserWithID: IFirebaseUserWithID = {
                    user: firebaseUser,
                    firebaseID: clientUserFirebaseID
                };

                const client = this.constructClient(firebaseUserWithID);

                clientsByID.set(clientUserFirebaseID, client);
            });

        return clientsByID;
    }

    public async getClientIDsAndDisplayNamesForPeer(peerUserFirebaseID: string): Promise<ItemKeyAndDisplayValue[]> {

        const firebaseUsers = await UserService.getClientsForPeer(peerUserFirebaseID);

        if (undefined == firebaseUsers) {
            return [];
        }

        return Object.keys(firebaseUsers)
            .map((clientUserFirebaseID: string) => {

                const firebaseClient = firebaseUsers[clientUserFirebaseID];

                return {
                    key: clientUserFirebaseID,
                    display: `${firebaseClient.firstName} (${firebaseClient.emailAddress})`
                };
            });
    }

    public listenPeers(): void {

        UserService.listenPeers(

            (firebaseUserWithID: IFirebaseUserWithID) =>
                this.onPeerAddedHandler(firebaseUserWithID),

            (firebaseUserWithID: IFirebaseUserWithID) =>
                this.onPeerChangedHandler(firebaseUserWithID),

            (firebaseID: string) =>
                this.onPeerRemovedHandler(firebaseID)
        );
    }

    public async listenClients(): Promise<void> {
        const currentUser = await this.getCurrentUser();
        if (null == currentUser.firebaseUid) {
            throw new Error(`${this.constructor.name}: current user has no ID`);
        }
        UserService.listenClientsForPeer(
            currentUser.firebaseUid,
            firebaseUserWithID => this.onClientAddedHandler(firebaseUserWithID),
            firebaseUserWithID => this.onClientChangedHandler(firebaseUserWithID),
            firebaseID => this.onClientRemovedHandler(firebaseID)
        );
    }

    public unlistenClients(): void {
        UserService.unlistenClients();
    }

    @action
    public unlistenPeers(): void {

        UserService.unlistenPeers();

        this._activePeersByID.clear();
    }

    @action
    public onPeerAddedHandler(firebaseUserWithID: IFirebaseUserWithID): void {

        this.assertNotNull('onPeerAddedHandler')(firebaseUserWithID, 'firebaseUserWithID');

        const user = this.constructUser(firebaseUserWithID);
        this.assertNotNull('onPeerAddedHandler')(user, 'user');

        if (false === (user instanceof Peer)) {
            throw new Error('onPeerAddedHandler: user is not peer');
        }

        const peer = user as Peer;

        if (peer.isActive) {
            this._activePeersByID.set(firebaseUserWithID.firebaseID, peer);
        }
    }

    @action
    public onPeerChangedHandler(firebaseUserWithID: IFirebaseUserWithID): void {

        this.assertNotNull('onPeerChangedHandler')(firebaseUserWithID, 'firebaseUserWithID');

        const user = this.constructUser(firebaseUserWithID);
        this.assertNotNull('onPeerChangedHandler')(user, 'user');

        if (false === (user instanceof Peer)) {
            throw new Error('onPeerChangedHandler: user is not peer');
        }

        const peer = user as Peer;

        if (peer.isActive) {
            this._activePeersByID.set(firebaseUserWithID.firebaseID, peer);
        } else {
            this.onPeerRemovedHandler(firebaseUserWithID.firebaseID);
        }
    }

    @action
    public onPeerRemovedHandler(firebaseID: string): void {

        this.assertNotNull('onPeerRemovedHandler')(firebaseID, 'firebaseID');

        if (this._activePeersByID.has(firebaseID)) {
            this._activePeersByID.delete(firebaseID);
        }
    }

    @action
    private onClientAddedHandler(firebaseUserWithID: IFirebaseUserWithID): void {

        this.assertNotNull('onClientAddedHandler')(firebaseUserWithID, 'firebaseUserWithID');

        const user = this.constructUser(firebaseUserWithID);
        this.assertNotNull('onClientAddedHandler')(user, 'user');

        if (false === (user instanceof Client)) {
            throw new Error('onClientAddedHandler: user is not client');
        }

        const client = user as Client;

        ClientReflectionService.listenClientReflections(
            firebaseUserWithID.firebaseID,
            this.onClientReflectionAddedHandler
        );

        this._clientsForPeerByID.set(firebaseUserWithID.firebaseID, client);
    }

    @action
    private onClientChangedHandler(firebaseUserWithID: IFirebaseUserWithID): void {

        this.assertNotNull('onClientChangedHandler')(firebaseUserWithID, 'firebaseUserWithID');

        const user = this.constructUser(firebaseUserWithID);
        this.assertNotNull('onClientChangedHandler')(user, 'user');

        if (false === (user instanceof Client)) {
            throw new Error('onClientChangedHandler: user is not Client');
        }

        const existingClient = this._clientsForPeerByID.get(firebaseUserWithID.firebaseID);

        const client = user as Client;
        client.reflectionsByDate.merge(existingClient ? existingClient.reflectionsByDate : {});

        this._clientsForPeerByID.set(firebaseUserWithID.firebaseID, client);
    }

    @action
    private onClientRemovedHandler(firebaseID: string): void {

        this.assertNotNull('onClientRemovedHandler')(firebaseID, 'firebaseID');

        if (this._clientsForPeerByID.has(firebaseID)) {
            this._clientsForPeerByID.delete(firebaseID);
        }
    }

    @action
    private onClientReflectionAddedHandler = (clientUserID: string, firebaseReflectionWithDate: IFirebaseClientReflectionWithDate): void => {
        const client = this._clientsForPeerByID.get(clientUserID);
        if (null == client) {
            throw new Error(`${this.constructor.name}: Cannot set reflections if client ${clientUserID} does not exist`);
        }

        this.addReflectionToClient(firebaseReflectionWithDate, client);
    }

    private constructPeer(firebaseUserWithID: IFirebaseUserWithID): Peer {

        const user = this.constructUser(firebaseUserWithID);

        if (false === user instanceof Peer) {
            throw new Error('UserStore.constructPeer: user is not Peer');
        }

        return user as Peer;
    }

    private constructClient(firebaseUserWithID: IFirebaseUserWithID): Client {

        const user = this.constructUser(firebaseUserWithID);

        if (false === user instanceof Client) {
            throw new Error('UserStore.constructClient: user is not Client');
        }

        return user as Client;
    }

    private constructUser(firebaseUserWithID: IFirebaseUserWithID): User {

        this.assertNotNull('constructUser')(firebaseUserWithID, 'firebaseUserWithID');

        const firebaseUser = firebaseUserWithID.user;
        const firebaseID = firebaseUserWithID.firebaseID;

        switch (firebaseUser.userType) {

            case UserType.Client:
                return Client.createFromFirebase(firebaseID, firebaseUser);
            case UserType.Peer:
                return Peer.createFromFirebase(firebaseID, firebaseUser);
            case UserType.Founder:
                return Founder.createFromFirebase(firebaseID, firebaseUser);
            default:
                throw new Error('UserStore.constructUser: unknown UserType ' + firebaseUser.userType);
        }
    }

    public async upsertUser(user: User): Promise<void> {

        this.assertNotNull('upsertUser')(user, 'user');

        if (null == user.firebaseUid) {
            throw new Error('UserStore.upsertUser: user has no firebase ID');
        }

        return UserService.upsertUser(user.firebaseUid, user.toFirebaseUser());
    }

    public async deactivatePeer(peer: Peer): Promise<void> {

        this.assertNotNull('deactivatePeer')(peer, 'peer');

        if (null == peer.firebaseUid) {
            throw new Error('UserStore.deactivatePeer: user has no firebase ID');
        }

        const clients = await UserService.getClientsForPeer(peer.firebaseUid);
        const clientUserFirebaseIDs = clients ? new Set<string>(Object.keys(clients)) : new Set<string>();

        return UserService.deactivatePeer(peer.firebaseUid, clientUserFirebaseIDs);
    }

    public async removeClientFromPeer(clientUserFirebaseID: string, peerUserFirebaseID: string): Promise<void> {

        this.assertNotNull('removeClientFromPeer')(clientUserFirebaseID, 'clientUserFirebaseID');
        this.assertNotNull('removeClientFromPeer')(peerUserFirebaseID, 'peerUserFirebaseID');

        return UserService.removeClientFromPeer(clientUserFirebaseID, peerUserFirebaseID);
    }

    public async doesUserExistWithEmail(emailAddress: string): Promise<boolean> {

        return UserService.doesUserExistWithEmail(emailAddress);
    }

    public isEmailForPeerOrFounder(emailAddress: string): Promise<boolean> {
        return UserService.isEmailForPeerOrFounder(emailAddress);
    }

    public async getUserIDForEmail(emailAddress: string): Promise<string | null> {
        const existingUser = await UserService.getUserForEmail(emailAddress);

        if (null == existingUser) {
            return null;
        }
        return existingUser[0];
    }

    public async isEmailAlreadyClient(emailAddress: string): Promise<boolean> {
        const existingUser = await UserService.getUserForEmail(emailAddress);

        if (null == existingUser) {
            return false;
        }

        const currentUser = await this.getCurrentUser();
        if (isPeer(currentUser) && currentUser.firebaseUid) {
            const clients = await UserService.getClientsForPeer(currentUser.firebaseUid);
            if (clients && Object.keys(clients).some(clientUserFirebaseID => clientUserFirebaseID === existingUser[0])) {
                return true;
            }
        }
        return false;
    }

    public async getCurrentUser(): Promise<PortalUser> {

        const firebaseUserWithID = await UserService.getCurrentUser();
        this.assertNotNull('getCurrentUser')(firebaseUserWithID, 'firebaseUserWithID');

        const currentUser = this.constructUser(firebaseUserWithID);
        this.assertNotNull('getCurrentUser')(currentUser, 'currentUser');

        return currentUser as PortalUser;
    }

    public async addClientToPeer(firstName: string, email: string, clientUserFirebaseID: string): Promise<void> {
        const currentUser = await this.getCurrentUser();

        if (null == currentUser.firebaseUid) {
            throw new Error(`${this.constructor.name}: current peer user must have an ID`);
        }

        if (false === await this.doesUserExistWithEmail(email)) {
            const client = Client.create(firstName, email, currentUser.firebaseUid, undefined, clientUserFirebaseID);
            await UserService.upsertUser(clientUserFirebaseID, client.toFirebaseUser());
        }

        UserService.addClientToPeer(clientUserFirebaseID, currentUser.firebaseUid);
    }

    public async addAuthUser(emailAddress: string, firebaseUid: string): Promise<void> {
        return UserService.addAuthUser(emailAddress, firebaseUid);
    }

    public async getAuthUserFirebaseUid(emailAddress: string): Promise<string | undefined> {
        return UserService.getAuthUserFirebaseUid(emailAddress);
    }

    public async getUserOnboardedStatus(email: string): Promise<{ isOnboarded?: boolean; isClient?: boolean }> {
        return UserService.getUserOnboardedStatus(email);
    }

}

function isPeer(candidate: PortalUser): candidate is Peer {
    return null != (candidate as Peer).isActive;
}
