import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { CSSProperties } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { panelButtonStyle } from '../../assets/CommonStyles';
import { COPY_CONSTANTS } from '../../assets/CopyConstants';
import { STYLE_CLASSES, STYLE_CONSTANTS } from '../../assets/StyleConstants';
import { Org } from '../../data/models/Org';
import { Facility } from '../../data/models/Facility';
import { ApplicationStore } from '../../data/stores/ApplicationStore';
import { ROUTES } from '../../Routes';
import { CustomButton } from '../components/Button';
import { OrgAddEditModal } from '../components/OrgAddEditModal';
import { Header } from '../components/Header';
import { ListPanel } from '../components/ListPanel';
import { Darkener } from '../components/Darkener';

interface IRouteParams {
    id: string;
}

export interface Props extends RouteComponentProps<IRouteParams> {
    store: ApplicationStore;
}

interface State {
    org?: Org;
    activeFacilities?: Facility[];
    isEditing: boolean;
}

@inject('store')
@observer
export class OrgDetail extends React.Component<Props, State> {

    public constructor(props: Props) {

        super(props);

        this.state = {
            isEditing: false
        };
    }

    public async componentWillMount(): Promise<void> {

        const orgFirebaseID = this.props.match.params.id;
        const org = await this.props.store.orgStore.getOrg(orgFirebaseID);
        const activeFacilities = await this.props.store.facilityStore.getActiveFacilitiesForOrg(orgFirebaseID);
        this.setState({
            org: org,
            activeFacilities: activeFacilities
        });
    }

    private getOrgInfoItems(org: Org): JSX.Element[][] {

        const rowItems: JSX.Element[][] = [
            this.buildOrgInfoRowItem(COPY_CONSTANTS.name, org.name),
            this.buildOrgInfoRowItem(COPY_CONSTANTS.address, org.address, multiLineValueStyle),
            this.buildOrgInfoRowItem(COPY_CONSTANTS.telephone, org.contactPhone),
            this.buildOrgInfoRowItem(COPY_CONSTANTS.email, org.contactEmailAddress, wordBreakAllStyle)
        ];

        return rowItems;
    }

    private buildOrgInfoRowItem(field: string, value: string, rowStyle?: CSSProperties): JSX.Element[] {

        return [
            <p style={cellTextStyle} key={'field'}>{field}</p>,
            <p style={{ ...cellTextStyle, ...rowStyle }} key={'value'}>{value}</p>
        ];
    }

    private getFacilityRowItems(activeFacilities: Facility[]): JSX.Element[][] {

        const rowItems = activeFacilities
            .sort((a: Facility, b: Facility) => a.compareToForSort(b))
            .map((facility: Facility) => this.buildFacilityRowItem(facility));

        if (0 === rowItems.length) {
            rowItems.push(this.buildNoFacilitiesRowItem());
        }

        return rowItems;
    }

    private buildFacilityRowItem(facility: Facility): JSX.Element[] {

        return [
            this.getFacilityLink(facility),
            <p key={'email'} style={{ ...cellTextStyle, ...wordBreakAllStyle }}>{facility.name}</p>
        ];
    }

    private buildNoFacilitiesRowItem(): JSX.Element[] {

        return [
            <p key={'message'} style={{ ...cellTextStyle, ...cellMessageTextStyle }}>{COPY_CONSTANTS.orgHasNoFacilities}</p>
        ];
    }

    private getFacilityLink(facility: Facility): JSX.Element {

        return (
            <Link
                to={`${ROUTES.facility}/${facility.firebaseFacilityID}`}
                style={cellLinkTextStyle}
            >
                {facility.name}
            </Link>
        );
    }

    public render(): JSX.Element | null {

        if (undefined === this.state.org || undefined === this.state.activeFacilities) {
            return null;
        }

        return (
            <div>

                <div className={this.props.store!.isModalShowing ? STYLE_CLASSES.DIV_BLUR : undefined}>

                    <div style={containerStyle}>

                        <Header title={this.state.org.name} addButtons={[]} />

                        <ListPanel
                            title={COPY_CONSTANTS.orgInfo}
                            columnWidths={['50%', '50%']}
                            rowItems={this.getOrgInfoItems(this.state.org)}
                            style={orgInfoStyle}
                            titleRowButtons={[
                                <CustomButton
                                    key={COPY_CONSTANTS.edit}
                                    style={panelButtonStyle}
                                    styleClassName={STYLE_CLASSES.BUTTON_PANEL}
                                    onClick={() => this.setState({ isEditing: true })}
                                >

                                    {COPY_CONSTANTS.edit}

                                </CustomButton>
                            ]}
                        />

                        <ListPanel
                            title={COPY_CONSTANTS.facilities}
                            columnNames={[COPY_CONSTANTS.name, COPY_CONSTANTS.email]}
                            columnWidths={['50%', '50%']}
                            rowItems={this.getFacilityRowItems(this.state.activeFacilities)}
                            style={facilityListStyle}
                        />

                    </div>

                </div>

                <OrgAddEditModal
                    isVisible={this.state.isEditing}
                    orgToEdit={this.state.org}
                    onComplete={() => this.setState({ isEditing: false })}
                />

                <Darkener isActive={this.props.store!.isModalShowing}/>

            </div>
        );

    }

}

const containerStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    padding: '50px',
    backgroundColor: STYLE_CONSTANTS.lightGray
};

const orgInfoStyle: CSSProperties = {
    width: '100%',
    minWidth: '400px'
};

const cellTextStyle: CSSProperties = {
    margin: '0',
    textDecoration: 'none',
    color: STYLE_CONSTANTS.darkGray
};

const cellLinkTextStyle: CSSProperties = {
    ...cellTextStyle,
    color: STYLE_CONSTANTS.darkerPurple
};

const multiLineValueStyle: CSSProperties = {
    whiteSpace: 'pre-wrap'
};

const facilityListStyle: CSSProperties = {
    marginTop: '50px'
};

const cellMessageTextStyle: CSSProperties = {
    fontStyle: 'italic'
};

const wordBreakAllStyle: CSSProperties = {
    wordBreak: 'break-all'
};
