import * as React from 'react';
import { pageTitleStyle } from '../../assets/CommonStyles';
import { COPY_CONSTANTS } from '../../assets/CopyConstants';
import { STYLE_CLASSES, STYLE_CONSTANTS } from '../../assets/StyleConstants';
import { SEARCH_ICON } from '../../images/images';
import { CustomButton } from './Button';
import { FormTextField } from './FormTextField';
import { ProfileButton } from './ProfileButton';

interface AddButtonProps {
    name: string;
    action(): void;
}

interface Props {
    title: string;
    searchText?: string;
    onSearchTextChanged?: (newValue: string) => void;
    addButtons: AddButtonProps[];
    onSearchTextChange?(text: string): void;

}

export class Header extends React.Component<Props> {
    public render(): JSX.Element {
        const { addButtons, title } = this.props;
        return (
            <div style={containerStyle}>

                <div style={{ ...pageTitleStyle, display: 'flex', flex: 1 }} className={STYLE_CLASSES.DIV_LIGHT_BOLD}>
                    {title}
                </div>

                <div style={searchAndButtonsContainer}>

                    {undefined != this.props.onSearchTextChanged &&
                        <FormTextField
                            label={COPY_CONSTANTS.search}
                            style={searchFieldStyle}
                            textStyle={searchTextStyle}
                            value={this.props.searchText || ''}
                            onValueChanged={(newValue: string) =>
                                this.props.onSearchTextChanged
                                    ? this.props.onSearchTextChanged(newValue)
                                    : undefined}
                            isStatic={true}
                            icon={SEARCH_ICON}
                            iconDimensionPixels={16}
                        />
                    }

                    {
                        addButtons.map((buttonProps, index) => (
                            <CustomButton
                                key={index}
                                style={buttonStyle}
                                styleClassName={STYLE_CLASSES.BUTTON_GREEN}
                                onClick={buttonProps.action}
                            >
                                {buttonProps.name}
                            </CustomButton>
                        ))
                    }

                </div>

                <ProfileButton />

            </div>
        );
    }
}



const containerStyle: React.CSSProperties = {
    display: 'flex'
};

const searchAndButtonsContainer: React.CSSProperties = {
    display: 'flex',
    justifyContent: 'space-between',
    marginRight: '20px'
};

const buttonStyle: React.CSSProperties = {
    marginLeft: '20px',
    width: '120px'
};

const searchFieldStyle: React.CSSProperties = {
    width: '260px',
    marginTop: 'auto',
    marginBottom: '0'
};

const searchTextStyle: React.CSSProperties = {
    fontSize: STYLE_CONSTANTS.fontSizePrimaryButton
};
