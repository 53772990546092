import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { ApplicationStore } from '../../data/stores/ApplicationStore';
import { RouteComponentProps } from 'react-router-dom';
import { Founder } from '../../data/models/Founder';
import { FounderDashboard } from './FounderDashboard';
import { PeerDashboard } from './PeerDashboard';

interface Props extends RouteComponentProps<{}> {
    store: ApplicationStore;
}

interface State {
    isFounder?: boolean;
}

@inject('store')
@observer
export class Dashboard extends React.Component<Props, State> {

    public state: State = {
        isFounder: undefined
    };

    public async componentWillMount(): Promise<void> {

        if (null == this.props.store) {
            throw new Error('Dashboard: no store');
        }

        const portalUser = await this.props.store.userStore.getCurrentUser();
        this.setState({
            isFounder: portalUser instanceof Founder
        });
    }


    public render(): JSX.Element | null {

        if (undefined == this.state.isFounder) {
            return null;
        }

        return (
            <div>
                {this.state.isFounder
                    ? <FounderDashboard {...this.props} />
                    : <PeerDashboard {...this.props} />
                }
            </div>
        );

    }
}
