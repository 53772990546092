import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { CSSProperties } from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { gridCellButtonStyle, panelButtonStyle } from '../../assets/CommonStyles';
import { COPY_CONSTANTS } from '../../assets/CopyConstants';
import { STYLE_CLASSES, STYLE_CONSTANTS } from '../../assets/StyleConstants';
import { Client } from '../../data/models/Client';
import { ClientReflection } from '../../data/models/ClientReflection';
import { GraphTimeWindow } from '../../data/models/GraphTimeWindow';
import { Peer } from '../../data/models/Peer';
import { PeerVisit } from '../../data/models/PeerVisit';
import { ApplicationStore } from '../../data/stores/ApplicationStore';
import { ROUTES } from '../../Routes';
import { CustomButton } from '../components/Button';
import { ClientAddEditModal } from '../components/ClientAddEditModal';
import { ClientReflectionModal } from '../components/ClientReflectionModal';
import { ConfirmationModal } from '../components/ConfirmationModal';
import { EmotionsGraph } from '../components/EmotionsGraph';
import { HealthMetricsGraph } from '../components/HealthMetricsGraph';
import { ListPanel } from '../components/ListPanel';
import { Panel } from '../components/Panel';
import { PeerPage } from '../components/PeerPage';
import { PeerVisitAddEditModal } from '../components/PeerVisitAddEditModal';
import { PeerVisitViewEditNotesModal } from '../components/PeerVisitViewEditNotesModal';

interface IRouteParams {
    id: string;
}

export interface Props extends RouteComponentProps<IRouteParams> {
    store: ApplicationStore;
}

interface State {
    client?: Client;
    peerVisits?: PeerVisit[];
    peerUserFirebaseID?: string;
    isEditingClient: boolean;
    isRemovingClient: boolean;
    viewingNotesPeerVisitFirebaseID?: string;
    viewingReflectionDateTimeIsoString?: string;
    currentDaysViewing: GraphTimeWindow;
    isAddingTime: boolean;
    hasBeenRemoved?: boolean;
    isViewingReflection: boolean;
    reflectionViewing: ClientReflection | undefined;
    previousReflection: ClientReflection | undefined;
}

@inject('store')
@observer
export class ClientDetail extends React.Component<Props, State> {

    public state: State = {
        isEditingClient: false,
        isRemovingClient: false,
        isAddingTime: false,
        currentDaysViewing: GraphTimeWindow.Weekly,
        isViewingReflection: false,
        reflectionViewing: undefined,
        previousReflection: undefined
    };

    public componentWillMount(): void {

        this.refreshData();
    }

    private async refreshData(): Promise<void> {

        const currentUser = await this.props.store!.userStore.getCurrentUser();
        if (undefined === currentUser.firebaseUid) {
            return;
        }

        if (false === currentUser instanceof Peer) {
            throw new Error('Only Peers may access this page');
        }

        const clientUserFirebaseID = this.props.match.params.id;
        const client = await this.props.store.userStore.getClient(clientUserFirebaseID);
        const peerVisits = await this.props.store.peerVisitStore.getPeerVisitsForClient(currentUser.firebaseUid, clientUserFirebaseID);

        this.setState({
            client: client,
            peerVisits: peerVisits,
            peerUserFirebaseID: currentUser.firebaseUid
        });
    }

    private showAddTimeModal = () => this.setState({ isAddingTime: true });
    private showEditClientModal = () => this.setState({ isEditingClient: true });
    private showRemoveClientModal = () => this.setState({ isRemovingClient: true });

    private viewReflection = (reflection: ClientReflection) => {
        const allReflections = Array.from(this.state.client!.reflectionsByDate.values())
            .reduce((all, forDay) => [...all, ...Array.from(forDay)], [])
            .sort((a, b) => a.dateTimeCompareString.localeCompare(b.dateTimeCompareString));

        const indexOfReflection = allReflections.findIndex(r => r.dateTimeIsoString === reflection.dateTimeIsoString);
        const previousReflection = indexOfReflection > 0 ? allReflections[indexOfReflection - 1] : undefined;
        this.setState({ isViewingReflection: true, reflectionViewing: reflection, previousReflection });
    }

    private closeReflectionModal = () => this.setState({ isViewingReflection: false, reflectionViewing: undefined, previousReflection: undefined });

    private addTimeModalClosed = () => {
        this.setState({ isAddingTime: false });
        this.refreshData();
    }
    private editClientModalClosed = () => {
        this.setState({ isEditingClient: false });
        this.refreshData();
    }

    private async remove(clientUserFirebaseID: string, peerUserFirebaseID: string): Promise<void> {

        await this.props.store!.userStore.removeClientFromPeer(clientUserFirebaseID, peerUserFirebaseID);

        this.setState({ hasBeenRemoved: true });
    }

    private getActivityRowItems(client: Client, peerVisits: PeerVisit[]): JSX.Element[][] {

        const rowItems: JSX.Element[][] = [];

        const activities: (ClientReflection | PeerVisit)[] = [];

        client.reflectionsByDate.forEach((reflections: ClientReflection[]) => {
            reflections.forEach((reflection: ClientReflection) => {
                activities.push(reflection);
            });
        });

        peerVisits.forEach((peerVisit: PeerVisit) => {
            activities.push(peerVisit);
        });

        activities.sort((a: ClientReflection | PeerVisit, b: ClientReflection | PeerVisit) =>
            b.dateTimeCompareString.localeCompare(a.dateTimeCompareString)
        );

        activities.forEach((activity: ClientReflection | PeerVisit) => {

            const isClientReflection = activity instanceof ClientReflection;

            if (isClientReflection) {
                rowItems.push(this.buildReflectionActivityRowItem(activity as ClientReflection));
            } else {
                rowItems.push(this.buildPeerVisitActivityRowItem(activity as PeerVisit));
            }
        });

        if (rowItems.length <= 0) {
            rowItems.push(this.buildNoActitiviesRowItem());
        }

        return rowItems;
    }

    private buildNoActitiviesRowItem(): JSX.Element[] {
        return [
            <p style={noActivitiesStyle} key="type">No Activities</p>,
            <span key="date" />,
            <span key="time" />,
            <span key="view" />
        ];
    }

    private buildReflectionActivityRowItem(reflection: ClientReflection): JSX.Element[] {

        return [
            <p style={cellTextStyle} key={'type'}>{COPY_CONSTANTS.reflection}</p>,
            <p style={cellTextStyle} key={'date'}>{reflection.dateDisplay}</p>,
            <p style={cellTextStyle} key={'time'}>&ndash;</p>,
            this.getViewReflectionAnswersButton(reflection)
        ];
    }

    private buildPeerVisitActivityRowItem(peerVisit: PeerVisit): JSX.Element[] {

        return [
            <p style={cellTextStyle} key={'type'}>{COPY_CONSTANTS.peerVisit}</p>,
            <p style={cellTextStyle} key={'date'}>{peerVisit.date.displayString}</p>,
            <p style={cellTextStyle} key={'time'}>{peerVisit.durationDisplay}</p>,
            this.getViewPeerVisitNotesButton(peerVisit)
        ];
    }

    private getViewPeerVisitNotesButton(peerVisit: PeerVisit): JSX.Element {

        return (
            <CustomButton
                key={'view_notes'}
                style={gridCellButtonStyle}
                styleClassName={STYLE_CLASSES.BUTTON_LIGHT_GREEN}
                onClick={() => this.setState({ viewingNotesPeerVisitFirebaseID: peerVisit.peerVisitFirebaseID })}
            >
                {COPY_CONSTANTS.notes}
            </CustomButton>
        );
    }

    private getViewReflectionAnswersButton(reflection: ClientReflection): JSX.Element {

        return (
            <CustomButton
                key={'view_answers'}
                style={gridCellButtonStyle}
                styleClassName={STYLE_CLASSES.BUTTON_LIGHT_GREEN}
                onClick={() => this.viewReflection(reflection)}
            >
                {COPY_CONSTANTS.answers}
            </CustomButton>
        );
    }

    private getClientInfoRowItems(client: Client): JSX.Element[][] {

        const rowItems: JSX.Element[][] = [
            this.buildClientInfoRowItem(COPY_CONSTANTS.name, this.wrapFieldValueInParagraph(client.firstName)),
            this.buildClientInfoRowItem(COPY_CONSTANTS.email, this.wrapFieldValueInParagraph(client.emailAddress, wordBreakAllStyle)),
            this.buildClientInfoRowItem(COPY_CONSTANTS.recoveryDate, this.wrapFieldValueInParagraph(client.recoveryDate ? client.recoveryDate.displayString : '')),
            this.buildClientInfoRowItem(COPY_CONSTANTS.program, this.wrapFieldValueInParagraph(client.program))
        ];

        return rowItems;
    }

    private buildClientInfoRowItem(field: string, value: JSX.Element): JSX.Element[] {
        return [
            <p style={cellTextStyle} key={'field'}>{field}</p>,
            value
        ];
    }

    private wrapFieldValueInParagraph(value: string, additionalStyle?: CSSProperties): JSX.Element {
        return <p style={{ ...cellTextStyle, ...additionalStyle }}>{value}</p>;
    }

    public render(): JSX.Element | null {

        if (undefined === this.state.client || undefined === this.state.peerVisits || undefined === this.state.peerUserFirebaseID) {
            return null;
        }

        if (this.state.hasBeenRemoved) {
            return <Redirect to={ROUTES.clients} />;
        }

        return (
            <div>
                <PeerPage
                    title={this.state.client.firstName}
                    onAddTimeClick={this.showAddTimeModal}
                >

                    <Panel
                        title={COPY_CONSTANTS.insights}
                        titleRowButtonContainerStyle={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}
                        titleRowButtons={[
                            (
                                <CustomButton
                                    key={'week'}
                                    style={{
                                        ...leftGraphSwitchStyle,
                                        backgroundColor: this.state.currentDaysViewing === GraphTimeWindow.Weekly ? STYLE_CONSTANTS.darkestPurple : STYLE_CONSTANTS.lightPurple
                                    }}
                                    onClick={() => this.setState({ currentDaysViewing: GraphTimeWindow.Weekly })}
                                >
                                    {COPY_CONSTANTS.week}
                                </CustomButton>
                            ),
                            (
                                <CustomButton
                                    key={'month'}
                                    style={{
                                        ...rightGraphSwitchStyle,
                                        backgroundColor: this.state.currentDaysViewing === GraphTimeWindow.Monthly ? STYLE_CONSTANTS.darkestPurple : STYLE_CONSTANTS.lightPurple
                                    }}
                                    onClick={() => this.setState({ currentDaysViewing: GraphTimeWindow.Monthly })}
                                >
                                    {COPY_CONSTANTS.month}
                                </CustomButton>
                            )
                        ]}
                    >
                        <div style={panelBodyStyle}>
                            {this.state.client && <EmotionsGraph client={this.state.client} numberOfDaysViewing={this.state.currentDaysViewing} style={graphPanelStyle} headingStyle={headingTextStyle} />}

                            {this.state.client && <HealthMetricsGraph client={this.state.client} numberOfDaysViewing={this.state.currentDaysViewing} style={graphPanelStyle} headingStyle={headingTextStyle} />}
                        </div>
                    </Panel>

                    <ListPanel
                        title={COPY_CONSTANTS.clientInfo}
                        columnWidths={[
                            '35%',
                            '65%',
                        ]}
                        rowItems={this.getClientInfoRowItems(this.state.client)}
                        titleRowButtons={[
                            <CustomButton
                                key={COPY_CONSTANTS.edit}
                                style={panelButtonStyle}
                                styleClassName={STYLE_CLASSES.BUTTON_PANEL}
                                onClick={this.showEditClientModal}
                            >

                                {COPY_CONSTANTS.edit}

                            </CustomButton>,

                            <CustomButton
                                key={COPY_CONSTANTS.remove}
                                style={panelButtonStyle}
                                styleClassName={STYLE_CLASSES.BUTTON_MAGENTA}
                                onClick={this.showRemoveClientModal}
                            >

                                {COPY_CONSTANTS.remove}

                            </CustomButton>
                        ]}
                    />

                    <ListPanel
                            title={COPY_CONSTANTS.activity}
                            columnNames={[
                                COPY_CONSTANTS.type,
                                COPY_CONSTANTS.date,
                                COPY_CONSTANTS.time,
                                COPY_CONSTANTS.view
                            ]}
                            columnWidths={[
                                '30%',
                                '25%',
                                '25%',
                                '20%'
                            ]}
                            rowItems={this.getActivityRowItems(this.state.client, this.state.peerVisits)}
                            maxHeight={500}
                    />

                </PeerPage>

                <PeerVisitViewEditNotesModal
                    peerVisitToEdit={this.state.peerVisits.find((peerVisit: PeerVisit) => peerVisit.peerVisitFirebaseID === this.state.viewingNotesPeerVisitFirebaseID)}
                    onComplete={() => {
                        this.setState({ viewingNotesPeerVisitFirebaseID: undefined });
                    }}
                />

                <ConfirmationModal
                    isVisible={this.state.isRemovingClient}
                    title={COPY_CONSTANTS.removeClient}
                    message={COPY_CONSTANTS.removeClientConfirmationMessage}
                    confirmButtonText={COPY_CONSTANTS.remove}
                    confirmButtonStyleClassName={STYLE_CLASSES.BUTTON_MAGENTA}
                    cancelButtonStyleClassName={STYLE_CLASSES.BUTTON_MEDIUM_LIGHT_GRAY}
                    onConfirm={() => {
                        if (this.state.client && this.state.client.firebaseUid && this.state.peerUserFirebaseID) {
                            this.remove(this.state.client.firebaseUid, this.state.peerUserFirebaseID);
                        }
                    }}
                    onCancel={() => this.setState({ isRemovingClient: false })}
                />

                <ClientAddEditModal
                    isVisible={this.state.isEditingClient}
                    clientToEdit={this.state.client}
                    onClose={this.editClientModalClosed}
                    onComplete={this.editClientModalClosed}
                />

                <PeerVisitAddEditModal
                    isVisible={this.state.isAddingTime}
                    peerUserFirebaseID={this.state.peerUserFirebaseID}
                    clientUserFirebaseIDForAdd={this.props.match.params.id}
                    onComplete={this.addTimeModalClosed}
                />

                <ClientReflectionModal
                    isVisible={this.state.isViewingReflection}
                    reflection={this.state.reflectionViewing}
                    onClose={this.closeReflectionModal}
                    previousReflection={this.state.previousReflection}
                />
            </div>
        );
    }

}

const bigRowStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
};

const halfWidthPanelStyle: CSSProperties = {
    width: '50%'
};

const panelBodyStyle: CSSProperties = {
    ...bigRowStyle,
    backgroundColor: STYLE_CONSTANTS.lightestGray
};

const cellTextStyle: CSSProperties = {
    margin: '0',
    textDecoration: 'none',
    color: STYLE_CONSTANTS.darkGray
};

const wordBreakAllStyle: CSSProperties = {
    wordBreak: 'break-all'
};

const headingTextStyle: CSSProperties = {
    fontSize: STYLE_CONSTANTS.fontSizeMedium,
    color: STYLE_CONSTANTS.darkerGray
};

const graphPanelStyle: CSSProperties = {
    ...halfWidthPanelStyle,
    paddingLeft: '30px',
    paddingTop: '10px'
};

const graphSwitchStyle: CSSProperties = {
    width: '90px',
    height: '30px'
};

const leftGraphSwitchStyle: CSSProperties = {
    ...graphSwitchStyle,
    borderTopLeftRadius: '5px',
    borderBottomLeftRadius: '5px',
    borderBottomRightRadius: '0px',
    borderTopRightRadius: '0px'
};

const rightGraphSwitchStyle: CSSProperties = {
    ...graphSwitchStyle,
    borderBottomRightRadius: '5px',
    borderTopRightRadius: '5px',
    borderTopLeftRadius: '0px',
    borderBottomLeftRadius: '0px',
};

const noActivitiesStyle: CSSProperties = {
    ...cellTextStyle,
    fontStyle: 'italic'
};
