import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { STYLE_CLASSES } from '../../assets/StyleConstants';
import { ApplicationStore } from '../../data/stores/ApplicationStore';

interface Props {
    store?: ApplicationStore;
    shouldDisplay: boolean;
    style?: React.CSSProperties;
    isInsideAnotherModal?: boolean;
}

interface State {
    isVisible: boolean;
    isFading: boolean;
}

@inject('store')
@observer
export class Fadeable extends React.Component<Props, State> {

    public constructor(props: Props) {

        super(props);

        this.state = {
            isVisible: props.shouldDisplay,
            isFading: false
        };
    }

    public componentDidUpdate(prevProps: Props): void {

        if (false === prevProps.shouldDisplay && this.props.shouldDisplay) {
            setTimeout(() => this.setVisible(), 100);
        } else if (prevProps.shouldDisplay && false === this.props.shouldDisplay) {
            this.setState({isVisible: false, isFading: true});
            this.unsetModalShowing();
            setTimeout(() => this.setState({isFading: false}), 500);
        }
    }

    private setVisible(): void {

        this.setState({isVisible: true});
        this.props.store!.isModalShowing = true;
    }

    public componentWillUnmount(): void {
        this.unsetModalShowing();
    }

    private unsetModalShowing(): void {

        if (undefined === this.props.isInsideAnotherModal || false === this.props.isInsideAnotherModal) {
            this.props.store!.isModalShowing = false;
        }
    }

    public render(): JSX.Element | null {

        if (false === this.props.shouldDisplay && false === this.state.isVisible && false === this.state.isFading) {
            return null;
        }

        return (
            <div
                style={this.props.style}
                className={this.state.isVisible
                    ? STYLE_CLASSES.DIV_FADE_SHOWN
                    : STYLE_CLASSES.DIV_FADE_HIDDEN}
            >
                {this.props.children}
            </div>
        );
    }
}
