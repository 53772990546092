import { PortalUser } from './PortalUser';
import { IFirebaseUser } from './firebase/FirebaseUser';

export class Founder extends PortalUser {

    private constructor() {
        super();
    }

    public get isOnboarded(): boolean {
        return true;
    }

    public static createFromFirebase(firebaseUid: string, firebaseUser: IFirebaseUser): Founder {

        const founder = new Founder();
        super.createFromFirebase(firebaseUid, firebaseUser, founder);

        return founder;
    }

    public toFirebaseUser(): IFirebaseUser {

        return super.getFirebaseUser();
    }
}
