import * as assert from 'assert';
import { IFirebaseUser } from './firebase/FirebaseUser';
import { User } from './User';
import { UserType } from './UserType';

export abstract class PortalUser extends User {

    private _lastName: string;
    public isActive: boolean;
    public userType: UserType;

    protected constructor() {
        super();
    }

    protected static createFromFirebase(firebaseUid: string, firebaseUser: IFirebaseUser, portalUser: PortalUser): void {

        super.createFromFirebase(firebaseUid, firebaseUser, portalUser);

        assert('string' === typeof firebaseUser.lastName, 'PortalUser createFromFirebase passed args with invalid lastName');
        assert('boolean' === typeof firebaseUser.isActive, 'PortalUser createFromFirebase passed args with invalid isActive');
        assert(portalUser instanceof PortalUser, 'PortalUser createFromFirebase passed args with invalid portaluser');

        portalUser._lastName = firebaseUser.lastName;
        portalUser.isActive = firebaseUser.isActive;
        portalUser.userType = firebaseUser.userType;
    }

    protected static createPortalUser(
        firstName: string, lastName: string, emailAddress: string,
        portalUser: PortalUser, newPeerUserFirebaseID: string | undefined)
        : void {

        super.createUser(emailAddress, firstName, portalUser, newPeerUserFirebaseID);

        assert('string' === typeof lastName, 'PortalUser createNew passed args with invalid lastName');
        assert(portalUser instanceof PortalUser, 'PortalUser createNew passed args with invalid portalUser');

        portalUser._lastName = lastName;

        if (newPeerUserFirebaseID) {
            portalUser.isActive = true;
        }
    }

    protected getFirebaseUser(): IFirebaseUser {

        let firebaseUser = super.getFirebaseUser() as IFirebaseUser;

        firebaseUser.lastName = this._lastName;
        firebaseUser.isActive = this.isActive;

        return firebaseUser;
    }

    public get initials(): string {
        return (
            (this.firstName[0] + this._lastName[0])
                .toUpperCase());
    }

    public get fullName(): string {
        return `${this.firstName} ${this._lastName}`;
    }

    public get lastName(): string {
        return this._lastName;
    }

    public abstract get isOnboarded(): boolean;

    public compareToForSort(portalUser: PortalUser): number {

        return this.sortKey.localeCompare(portalUser.sortKey);
    }

    private get sortKey(): string {
        return this.fullName + this.emailAddress;
    }
}
