import { assertNotNull } from '../../util/assert';
import { computed, observable, action, ObservableMap } from 'mobx';
import { Facility } from '../models/Facility';
import { IFirebaseFacility } from '../models/firebase/FirebaseFacility';
import { FacilityService } from '../services/FacilityService';
import { ItemKeyAndDisplayValue } from '../../util/common';

const CLASS_NAME = 'FacilityStore';

export class FacilityStore {

    private readonly assertNotNull = assertNotNull(CLASS_NAME);

    @observable
    private _facilitiesByID: ObservableMap<string, Facility> = new ObservableMap<string, Facility>();

    @computed
    public get facilitiesByID(): Map<string, Facility> {

        return this._facilitiesByID;
    }

    @computed
    get facilityNames(): Set<string> {

        return new Set<string>(
            Array.from(this._facilitiesByID.values())
                .map((facility: Facility) => facility.name));
    }

    @computed
    public get activeFacilities(): Facility[] {
        return Array.from(this._facilitiesByID.values());
    }

    public async getFacilityIDsAndDisplayValues(): Promise<ItemKeyAndDisplayValue[]> {

        const firebaseFacilities = await FacilityService.getAll();

        if (undefined == firebaseFacilities) {
            return [];
        }

        return Object.keys(firebaseFacilities)
            .map((facilityFirebaseID: string) => {

                const firebaseFacility = firebaseFacilities[facilityFirebaseID];

                return {
                    key: facilityFirebaseID,
                    display: firebaseFacility.name
                };
            });
    }

    public async getFacility(facilityFirebaseID: string): Promise<Facility> {

        const firebaseFacility = await FacilityService.getFacility(facilityFirebaseID);

        return Facility.createFromFirebase(facilityFirebaseID, firebaseFacility);
    }

    public listen(): void {

        FacilityService.listen(

            (firebaseID: string, firebaseFacility: IFirebaseFacility) =>
                this.onFacilityAddedOrUpdated(firebaseID, firebaseFacility),

            (firebaseID: string) =>
                this.onFacilityRemoved(firebaseID),
        );
    }

    public unlisten(): void {

        FacilityService.unlisten();
    }

    @action
    public onFacilityAddedOrUpdated(firebaseID: string, firebaseFacility: IFirebaseFacility): void {

        this.assertNotNull('onFacilityAddedOrUpdated')(firebaseID, 'firebaseID');
        this.assertNotNull('firebaseFacility')(firebaseID, 'firebaseFacility');

        const facility = Facility.createFromFirebase(firebaseID, firebaseFacility);
        this._facilitiesByID.set(firebaseID, facility);
    }

    @action
    public onFacilityRemoved(firebaseID: string): void {

        this.assertNotNull('onFacilityRemoved')(firebaseID, 'firebaseID');

        if (this._facilitiesByID.has(firebaseID)) {
            this._facilitiesByID.delete(firebaseID);
        }
    }

    public async addFacility(facility: Facility): Promise<void> {

        this.assertNotNull('addFacility')(facility, 'facility');

        return FacilityService.addFacility(facility.toFirebaseFacility());
    }

    public async updateFacility(facility: Facility): Promise<void> {

        this.assertNotNull('updateFacility')(facility, 'facility');
        this.assertNotNull('updateFacility')(facility.firebaseFacilityID, 'facility');

        return FacilityService.updateFacility(facility.firebaseFacilityID, facility.toFirebaseFacility());
    }



    public async getActiveFacilitiesForOrg(orgID: string): Promise<Facility[]> {

        const facilities = await FacilityService.getFacilitiesForOrg(orgID);

        if (undefined == facilities) {
            return [];
        }

        return Object.keys(facilities)
            .map((facilityID: string) => {

                const facility = facilities[facilityID];

                return Facility.createFromFirebase(facilityID, facility);

            })
            //.filter((facility: Facility) => facility.isActive);
            .filter((facility: Facility) => true);

    }

}