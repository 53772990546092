import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { CSSProperties } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { panelButtonStyle } from '../../assets/CommonStyles';
import { COPY_CONSTANTS } from '../../assets/CopyConstants';
import { STYLE_CLASSES, STYLE_CONSTANTS } from '../../assets/StyleConstants';
import { Facility } from '../../data/models/Facility';
import { Org } from '../../data/models/Org';
import { ApplicationStore } from '../../data/stores/ApplicationStore';
import { ROUTES } from '../../Routes';
import { CustomButton } from '../components/Button';
import { FacilityAddEditModal } from '../components/FacilityAddEditModal';
import { OrgAddEditModal } from '../components/OrgAddEditModal';
import { FloatingMenu } from '../components/FloatingMenu';
import { Header } from '../components/Header';
import { ListPanel } from '../components/ListPanel';
import { Darkener } from '../components/Darkener';

interface Props extends RouteComponentProps<{}> {
    store?: ApplicationStore;
}

interface State {
    sortByFacility: boolean;
    isAddingOrg: boolean;
    isAddingFacility: boolean;
    isSortMenuOpen: boolean;
    sortBy: SortField;
}

enum SortField {
    Org = 'Organization',
    Facility = 'Facility'
}

@inject('store')
@observer
export class Facilities extends React.Component<Props, State> {

    public constructor(props: Props) {
        super(props);
        this.state = {
            sortByFacility: false,
            isAddingOrg: false,
            isAddingFacility: false,
            isSortMenuOpen: false,
            sortBy: SortField.Facility
        };
    }

    public componentWillMount(): void {
        this.props.store!.orgStore.listen();
        this.props.store!.facilityStore.listen();
    
    }

    public componentWillUnmount(): void {
        this.props.store!.orgStore.unlisten();
        this.props.store!.facilityStore.unlisten();
    }

    private getRowItems(): JSX.Element[][] {

        const unsortedOrgsByOrgID = new Map<string, Org>(this.props.store!.orgStore.orgsByID);
        const rowItems: JSX.Element[][] = [];

        this.props.store!.facilityStore.activeFacilities
            .sort((a: Facility, b: Facility) =>
                this.compareFacilities(a, b, unsortedOrgsByOrgID))
            .forEach((facility: Facility) => {

                rowItems.push(this.buildFacilityRowItem(facility, unsortedOrgsByOrgID));

                if (unsortedOrgsByOrgID.has(facility.orgID)) {
                    unsortedOrgsByOrgID.delete(facility.orgID);
                }
            });

        Array.from(unsortedOrgsByOrgID.values())
            .sort((a: Org, b: Org) => a.compareToForSort(b))
            .forEach((org: Org) => {
                rowItems.push(this.buildNoFacilityOrgRowItem(org));
            });

        if (0 === rowItems.length) {
            rowItems.push(this.buildNoFacilitiesRowItem());
        }

        return rowItems;
    }

    private compareFacilities(a: Facility, b: Facility, unsortedOrgsByOrgID: Map<string, Org>): number {

        if (this.state.sortBy === SortField.Org) {
            if (a.orgID === b.orgID) {
                return a.compareToForSort(b);
            }
            const orgA = unsortedOrgsByOrgID.get(a.orgID);
            const orgB  = unsortedOrgsByOrgID.get(b.orgID);
            if (!orgA || !orgB) {
                return 0;
            }
            return orgA.compareToForSort(orgB);
        } else {
            return a.compareToForSort(b);
        }
    }

    private buildFacilityRowItem(facility: Facility, unsortedOrgsByOrgID:  Map<string, Org>): JSX.Element[] {

        var r: JSX.Element[] = [];
    
        if ("" == facility.orgID) {
            r = r.concat(  
                this.buildMessageLastRowItem(COPY_CONSTANTS.noOrg, this.getFacilityLink(facility))
            )
        } else {
            r = r.concat( [
                this.getFacilityLink(facility),
                this.getOrgLinkFromID(facility.orgID, unsortedOrgsByOrgID)
            ] );
        };
        return r
    }

    private buildNoFacilityOrgRowItem(org: Org): JSX.Element[] {
        return this.buildMessageFirstRowItem(
            COPY_CONSTANTS.orgHasNoFacilities,
            this.getOrgLink(org)
        );
    }

    private buildNoFacilitiesRowItem(): JSX.Element[] {
        return this.buildMessageFirstRowItem(
            COPY_CONSTANTS.noOrgsOrFacilities,
            <p style={cellTextStyle}>&nbsp;</p>
        );
    }

    private buildMessageFirstRowItem(message: string, element: JSX.Element | null): JSX.Element[] {

        const messageRowItem = [<p key={'message'} style={{ ...cellTextStyle, ...cellMessageTextStyle }}>{message}</p>];

        if (element) {
            messageRowItem.push(element);
        }
        return messageRowItem;
    }

    private buildMessageLastRowItem(message: string, element: JSX.Element): JSX.Element[] {

        const messageRowItem: JSX.Element[] = [];
        
        messageRowItem.push(element);
        
        messageRowItem.push( <p key={'message'} style={{ ...cellTextStyle, ...cellMessageTextStyle }}>{message}</p> )
        return messageRowItem;
    }

    private getFacilityLink(facility: Facility): JSX.Element {

        return (
            <Link
                to={`${ROUTES.facility}/${facility.firebaseFacilityID}`}
                style={cellLinkTextStyle}
            >
                {facility.name}
            </Link>
        );
    }

    private getOrgLinkFromID(orgID: string, unsortedOrgsByOrgID:  Map<string, Org>): JSX.Element {

        const org = unsortedOrgsByOrgID.get(orgID);
        return (
            <Link
                to={`${ROUTES.org}/${orgID}`}
                style={cellLinkTextStyle}
            >
                {org? org.name : ''}
            </Link>
        );
    }

    private getOrgLink(org: Org): JSX.Element{

        return (
            <Link
                to={`${ROUTES.org}/${org.firebaseOrgID}`}
                style={cellLinkTextStyle}
                >
                    {org.name}
                </Link>
        )
    }
    
    private openSortMenu = () => this.setState({ isSortMenuOpen: true });
    private closeSortMenu = () => this.setState({ isSortMenuOpen: false });

    private handleSortOptionClicked = (option: SortField) => this.setState({ sortBy: option, isSortMenuOpen: false });

    public render(): JSX.Element | null {

        return (
            <div>

                <div className={this.props.store!.isModalShowing ? STYLE_CLASSES.DIV_BLUR : undefined}>
                    <div style={containerStyle}>
                        <Header
                            title={COPY_CONSTANTS.facilities}
                            addButtons={[
                                { name: COPY_CONSTANTS.addOrg, action: () => this.setState({ isAddingOrg: true }) },
                                { name: COPY_CONSTANTS.addFacility, action: () => this.setState({ isAddingFacility: true }) }
                            ]}
                        />

                        <ListPanel
                            title={COPY_CONSTANTS.facilities}
                            columnNames={[
                                COPY_CONSTANTS.facility,
                                COPY_CONSTANTS.org
                            ]}
                            columnWidths={
                                [
                                    '30%',
                                    '30%'
                                ]
                            }
                            rowItems={this.getRowItems()}
                            titleRowButtons={[
                                (
                                    <div key="sort">
                                        <CustomButton
                                            key={COPY_CONSTANTS.sort}
                                            style={panelButtonStyle}
                                            styleClassName={STYLE_CLASSES.BUTTON_PANEL}
                                            onClick={this.openSortMenu}
                                        >

                                            {COPY_CONSTANTS.sort}

                                        </CustomButton>
                                        <FloatingMenu
                                            isOpen={this.state.isSortMenuOpen}
                                            options={[
                                                SortField.Org,
                                                SortField.Facility
                                            ]}
                                            onOptionClicked={this.handleSortOptionClicked}
                                            onExternalClick={this.closeSortMenu}
                                            backgroundColor={STYLE_CONSTANTS.white}
                                            style={sortMenuStyle}
                                            optionStyle={sortMenuOptionStyle}
                                            optionStyleClassName={STYLE_CLASSES.BUTTON_WHITE}
                                            highlightedOptionStyleClassName={STYLE_CLASSES.BUTTON_MEDIUM_LIGHT_GRAY}
                                        />
                                    </div>
                                )
                            ]}
                        />

                    </div>

                </div>

                <OrgAddEditModal
                    isVisible={this.state.isAddingOrg}
                    onComplete={() => this.setState({ isAddingOrg: false })}
                />

                <FacilityAddEditModal
                    isVisible={this.state.isAddingFacility}
                    onComplete={() => this.setState({ isAddingFacility: false })}
                />


                <Darkener isActive={this.props.store!.isModalShowing}/>

            </div>
        );

    }
}

const containerStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    padding: '50px',
    backgroundColor: STYLE_CONSTANTS.lightGray
};

const cellTextStyle: CSSProperties = {
    margin: '0',
    textDecoration: 'none',
    color: STYLE_CONSTANTS.darkGray
};

const cellLinkTextStyle: CSSProperties = {
    ...cellTextStyle,
    color: STYLE_CONSTANTS.darkerPurple
};

const emailCellTextStyle: CSSProperties = {
    ...cellTextStyle,
    wordBreak: 'break-all'
};

const cellMessageTextStyle: CSSProperties = {
    fontStyle: 'italic'
};

const sortMenuStyle: React.CSSProperties = {
    top: '5px',
    left: '5px',
    width: '100px'
};

const sortMenuOptionStyle: React.CSSProperties = {
    color: STYLE_CONSTANTS.darkGray,
    fontSize: STYLE_CONSTANTS.fontSizeSmall
};
