import * as React from 'react';
import { CSSProperties } from 'react';
import { STYLE_CONSTANTS } from '../../assets/StyleConstants';

interface ButtonProps {
    style?: React.CSSProperties;
    styleClassName?: string;
    onClick?: () => void;
    isDisabled?: boolean;
    type?: string;
    isCircle?: boolean;
}

export class CustomButton extends React.Component<ButtonProps> {

    public render(): JSX.Element {

        return (
            <button
                className={this.props.styleClassName}
                style={this.computedStyle}
                onClick={this.props.onClick}
                type={this.props.type}
                disabled={this.props.isDisabled}
            >
                {this.props.children}
            </button>
        );
    }

    private get computedStyle(): React.CSSProperties {
        let computedStyle = buttonStyle;

        if (this.props.isCircle) {
            computedStyle = {...computedStyle, ...circleButtonStyle};
        }
        
        if (null != this.props.style) {
            computedStyle = {...computedStyle, ...this.props.style};
        }

        return computedStyle;
    }
}

const buttonStyle: CSSProperties = {
    height: '43px',
    appearance: 'none',
    border: '0',
    paddingLeft: '20px',
    paddingRight: '20px',
    borderRadius: '5px',
    color: STYLE_CONSTANTS.white,
    textTransform: 'none',
    fontSize: STYLE_CONSTANTS.fontSizeSmall,
    outline: 'none',
    cursor: 'pointer'
};

const circleButtonStyle: CSSProperties = {
    borderRadius: '50%',
    height: '43px',
    width: '43px',
    display: 'inline-flex',
    justifyContent: 'center'
};
