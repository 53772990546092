import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { cellTextStyle, emailCellStyle, gridCellButtonStyle, gridCellIconStyle, gridCellWithExtraLeftMarginStyle, panelButtonStyle }
    from '../../assets/CommonStyles';
import { COPY_CONSTANTS } from '../../assets/CopyConstants';
import { STYLE_CLASSES, STYLE_CONSTANTS } from '../../assets/StyleConstants';
import { Client } from '../../data/models/Client';
import { Date } from '../../data/models/Date';
import { ApplicationStore } from '../../data/stores/ApplicationStore';
import { CHECKMARK_ICON, X_ICON } from '../../images/images';
import { durationMinutesToDisplay } from '../../util/common';
import { CustomButton } from '../components/Button';
import { ClientLink } from '../components/ClientLink';
import { ClientLinkWithArrow } from '../components/ClientLinkWithArrow';
import { ClientLinkWithFlag } from '../components/ClientLinkWithFlag';
import { FloatingMenu } from '../components/FloatingMenu';
import { LastCheckInText } from '../components/LastCheckInText';
import { ListPanel } from '../components/ListPanel';
import { PeerPage } from '../components/PeerPage';
import { PeerVisitAddEditModal } from '../components/PeerVisitAddEditModal';
import { StreakLengthText } from '../components/StreakLengthText';

interface Props {
    store: ApplicationStore;
}

interface State {
    isAddingClient: boolean;
    isAddingTime: boolean;
    searchText: string;
    firebaseClientIDForAddTime?: string;
    sortedBy: SortField;
    isSortMenuOpen: boolean;
}

enum SortField {
    Name = 'Name',
    Email = 'Email',
    New = 'New'
}

@inject('store')
@observer
export class Clients extends React.Component<Props, State> {
    public state: State = { isAddingClient: false, isAddingTime: false, searchText: '', sortedBy: SortField.Name, isSortMenuOpen: false };

    public componentDidMount(): void {
        const { authorizationStore, peerVisitStore } = this.props.store!;
        peerVisitStore.refreshPeerVisitsForPeer(authorizationStore.firebaseUser.uid);
    }

    private showAddTimeModal = () => this.setState({ isAddingTime: true });

    private handleSearchTextChanged = (searchText: string) => this.setState({ searchText });
    private handleSortOptionSelect = (option: SortField) => this.setState({
        sortedBy: option,
        isSortMenuOpen: false
    })
    private showSortMenu = () => this.setState({ isSortMenuOpen: true });
    private hideSortMenu = () => this.setState({ isSortMenuOpen: false });

    private getSortPredicate(): (a: Client, b: Client) => number {
        switch (this.state.sortedBy) {
            case SortField.Email:
                return (a, b) => a.emailAddress.localeCompare(b.emailAddress);
            case SortField.New:
                return (a, b) => b.createdTimestamp - a.createdTimestamp;
            default:
                return (a, b) => a.nameAndEmail.localeCompare(b.nameAndEmail);
        }
    }

    private getRowItems(): JSX.Element[][] {
        const searchText = this.state.searchText.toLocaleLowerCase();
        const sorter = this.getSortPredicate();
        const { activeClients } = this.props.store.userStore;
        if (activeClients.length <= 0) {
            return this.getEmptyRowItems(COPY_CONSTANTS.noClientsMessage);
        }
        const clientsMatchingSearch = activeClients.filter(client => client.doesMatchSearch(searchText));
        if (clientsMatchingSearch.length <= 0) {
            return this.getEmptyRowItems(COPY_CONSTANTS.noClientsSearchMessage);
        }
        return clientsMatchingSearch
            .sort(sorter)
            .map(client => [
                <ClientLinkWithFlag key={client.firebaseUid} client={client} />,
                <ClientLink client={client} style={emailCellStyle} key="email">{client.emailAddress}</ClientLink>,
                <img key={client.firebaseUid} style={gridCellIconStyle} src={client.reflectionsByDate.has(Date.createForToday().dateString) ? CHECKMARK_ICON : X_ICON} />,
                <StreakLengthText key={client.firebaseUid} client={client} />,
                this.getTimeThisWeek(client),
                <LastCheckInText key={client.firebaseUid} client={client} />,
                (
                    <CustomButton
                        key={'edit_time'}
                        style={gridCellButtonStyle}
                        styleClassName={STYLE_CLASSES.BUTTON_LIGHT_GREEN}
                        onClick={() => this.setState({ isAddingTime: true, firebaseClientIDForAddTime: client.firebaseUid })}
                    >
                        {COPY_CONSTANTS.add}
                    </CustomButton>
                ),
                <ClientLinkWithArrow key={client.firebaseUid} client={client} />
            ]);
    }

    private getTimeThisWeek(client: Client): JSX.Element {
        const peerVisitsForClient = this.props.store.peerVisitStore.getPeerVisitsForPeerAndClient(
            this.props.store.authorizationStore.firebaseUser.uid,
            client.firebaseUid!
        );
        const today = Date.createForToday();
        const totalMinutesForWeek = peerVisitsForClient.reduce(
            (minutes, visit) => today.diff(visit.date) < 7 ? minutes + visit.durationMinutes : minutes,
            0
        );
        return (
            <p key={client.firebaseUid} style={gridCellWithExtraLeftMarginStyle}>
                {durationMinutesToDisplay(totalMinutesForWeek)}
            </p>
        );
    }

    private getEmptyRowItems(text: string): JSX.Element[][] {
        return [
            [
                <span key="empty" />,
                <p key="none" style={cellTextStyle}>{text}</p>,
                <span key="empty" />,
                <span key="empty" />,
                <span key="empty" />,
                <span key="empty" />,
                <span key="empty" />,
                <span key="empty" />,
            ]
        ];
    }

    public render(): JSX.Element {
        return (
            <div>
                <PeerPage
                    title={COPY_CONSTANTS.clients}
                    onSearchTextChanged={this.handleSearchTextChanged}
                    searchText={this.state.searchText}
                    onAddTimeClick={this.showAddTimeModal}
                >
                    <ListPanel
                        title={COPY_CONSTANTS.clients}
                        columnNames={[
                            COPY_CONSTANTS.name,
                            COPY_CONSTANTS.email,
                            COPY_CONSTANTS.checkedIn,
                            COPY_CONSTANTS.streak,
                            COPY_CONSTANTS.timeThisWeek,
                            COPY_CONSTANTS.lastCheckIn,
                            COPY_CONSTANTS.time,
                            ''
                        ]}
                        columnWidths={[
                            '15%',
                            '21%',
                            '12%',
                            '12%',
                            '13%',
                            '13%',
                            '11%',
                            '3%'
                        ]}
                        rowItems={this.getRowItems()}
                        titleRowButtons={[
                            <div key="sort">
                                <CustomButton
                                    key={COPY_CONSTANTS.sort}
                                    style={panelButtonStyle}
                                    styleClassName={STYLE_CLASSES.BUTTON_PANEL}
                                    onClick={this.showSortMenu}
                                >
                                    {COPY_CONSTANTS.sort}
                                </CustomButton>
                                <FloatingMenu
                                    isOpen={this.state.isSortMenuOpen}
                                    options={[
                                        SortField.Name,
                                        SortField.Email,
                                        SortField.New
                                    ]}
                                    highlightedOption={this.state.sortedBy}
                                    onOptionClicked={this.handleSortOptionSelect}
                                    onExternalClick={this.hideSortMenu}
                                    backgroundColor={STYLE_CONSTANTS.white}
                                    style={sortMenuStyle}
                                    optionStyle={sortMenuOptionStyle}
                                    optionStyleClassName={STYLE_CLASSES.BUTTON_WHITE}
                                    highlightedOptionStyleClassName={STYLE_CLASSES.BUTTON_MEDIUM_LIGHT_GRAY}
                                />
                            </div>
                        ]}
                    />
                </PeerPage>
                <PeerVisitAddEditModal
                    isVisible={this.state.isAddingTime}
                    peerUserFirebaseID={this.props.store.authorizationStore.firebaseUser.uid}
                    onComplete={() => this.setState({ isAddingTime: false, firebaseClientIDForAddTime: undefined })}
                    clientUserFirebaseIDForAdd={this.state.firebaseClientIDForAddTime}
                />
            </div>
        );
    }
}

const sortMenuStyle: React.CSSProperties = {
    top: '5px',
    left: '5px',
    width: '100px'
};

const sortMenuOptionStyle: React.CSSProperties = {
    color: STYLE_CONSTANTS.darkGray,
    fontSize: STYLE_CONSTANTS.fontSizeSmall
};
