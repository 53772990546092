import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { CSSProperties } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { cellLinkTextStyle, cellOverflowHiddenTextStyle, cellTextStyle, gridCellButtonStyle, panelButtonStyle } from '../../assets/CommonStyles';
import { COPY_CONSTANTS } from '../../assets/CopyConstants';
import { STYLE_CLASSES, STYLE_CONSTANTS } from '../../assets/StyleConstants';
import { Client } from '../../data/models/Client';
import { Peer } from '../../data/models/Peer';
import { PeerVisit } from '../../data/models/PeerVisit';
import { PeerVisitCategory } from '../../data/models/PeerVisitCategory';
import { ApplicationStore } from '../../data/stores/ApplicationStore';
import { ROUTES } from '../../Routes';
import { CustomButton } from '../components/Button';
import { FloatingMenu } from '../components/FloatingMenu';
import { ListPanel } from '../components/ListPanel';
import { PeerPage } from '../components/PeerPage';
import { PeerVisitAddEditModal } from '../components/PeerVisitAddEditModal';
import { PeerVisitExportModal } from '../components/PeerVisitExportModal';
import { PeerVisitViewEditNotesModal } from '../components/PeerVisitViewEditNotesModal';

interface Props extends RouteComponentProps<{}> {
    store?: ApplicationStore;
}

interface State {
    peerUserFirebaseID?: string;
    searchText: string;
    isAddingTime: boolean;
    isSortMenuOpen: boolean;
    isExporting: boolean;
    viewingNotesPeerVisitFirebaseID?: string;
    editingPeerVisitFirebaseID?: string;
    peerVisits: PeerVisit[];
    clientsByIDFromPeerVisits: Map<string, Client>;
    sortedBy: SortField;
}

export enum SortField {
    Name = 'Name',
    Category = 'Category',
    Date = 'Date',
    Time = 'Time'
}

@inject('store')
@observer
export class TimeLog extends React.Component<Props, State> {

    public state: State = {
        searchText: '',
        isAddingTime: false,
        isSortMenuOpen: false,
        isExporting: false,
        peerVisits: [],
        clientsByIDFromPeerVisits: new Map<string, Client>(),
        sortedBy: SortField.Date
    };

    public componentWillMount(): void {
        this.refreshData();
    }

    private async refreshData(): Promise<void> {

        const currentUser = await this.props.store!.userStore.getCurrentUser();
        if (undefined === currentUser.firebaseUid) {
            return;
        }

        if (false === currentUser instanceof Peer) {
            throw new Error('Only Peers may access this page');
        }

        const peerVisits = await this.props.store!.peerVisitStore.getPeerVisitsForPeer(currentUser.firebaseUid);
        const clientsByIDFromPeerVisits = await this.props.store!.userStore.getClientsByIDFromPeerVisits(peerVisits);

        this.setState({
            peerUserFirebaseID: currentUser.firebaseUid,
            peerVisits: peerVisits,
            clientsByIDFromPeerVisits: clientsByIDFromPeerVisits
        });
    }

    private getClientLink(client: Client | undefined): JSX.Element {

        if (undefined == client) {
            return <p style={cellTextStyle}>&ndash;</p>;
        }

        return (
            <Link
                key={'client_link'}
                to={`${ROUTES.client}/${client.firebaseUid}`}
                style={cellLinkTextStyle}
            >
                {client.firstName}
            </Link>
        );
    }

    private getViewNotesButton(peerVisit: PeerVisit): JSX.Element {

        return (
            <CustomButton
                key={'view_notes'}
                style={gridCellButtonStyle}
                styleClassName={STYLE_CLASSES.BUTTON_LIGHT_GREEN}
                onClick={() => this.setState({ viewingNotesPeerVisitFirebaseID: peerVisit.peerVisitFirebaseID })}
            >
                {COPY_CONSTANTS.view}
            </CustomButton>
        );
    }

    private getEditTimeButton(peerVisit: PeerVisit): JSX.Element {

        return (
            <CustomButton
                key={'edit_time'}
                style={gridCellButtonStyle}
                styleClassName={STYLE_CLASSES.BUTTON_LIGHT_GREEN}
                onClick={() => this.setState({ editingPeerVisitFirebaseID: peerVisit.peerVisitFirebaseID })}
            >
                {COPY_CONSTANTS.edit}
            </CustomButton>
        );
    }

    private getPeerVisitRowItems(peerVisits: PeerVisit[]): JSX.Element[][] {

        const searchTextUpper = this.state.searchText.toUpperCase();

        const rowItems = peerVisits
            .filter((peerVisit: PeerVisit) => this.isSearchMatch(peerVisit, searchTextUpper))
            .sort((a: PeerVisit, b: PeerVisit) => this.compareForSort(a, b))
            .map((peerVisit: PeerVisit, index: number) => this.buildPeerVisitRowItem(peerVisit, index));

        if (0 === rowItems.length) {
            rowItems.push(this.buildNoPeerVisitsRowItem());
        }

        return rowItems;
    }

    private isSearchMatch(peerVisit: PeerVisit, searchTextUpper: string): boolean {
        if (searchTextUpper.length <= 0) {
            return true;
        }
        let isSearchMatch = false;
        if (peerVisit.clientUserFirebaseID) {
            const client = this.getClient(peerVisit.clientUserFirebaseID);
            if (client) {
                isSearchMatch =
                    client.firstName.toUpperCase().includes(searchTextUpper)
                    || client.emailAddress.toUpperCase().includes(searchTextUpper);
            }
        }
        return isSearchMatch;
    }

    private compareForSort(a: PeerVisit, b: PeerVisit): number {

        let compareResult: number;

        switch (this.state.sortedBy) {

            case SortField.Name:
                const aClient = a.clientUserFirebaseID ? this.getClient(a.clientUserFirebaseID) : undefined;
                const bClient = b.clientUserFirebaseID ? this.getClient(b.clientUserFirebaseID) : undefined;
                const aName = aClient ? aClient.nameAndEmail : '';
                const bName = bClient ? bClient.nameAndEmail : '';
                compareResult = aName.localeCompare(bName);
                break;
            case SortField.Category:
                compareResult = a.category.localeCompare(b.category);
                break;
            case SortField.Time:
                compareResult = b.durationMinutes - a.durationMinutes; // descending
                break;
            case SortField.Date:
            default:
                compareResult = a.compareToForDescendingDateSort(b);
                break;
        }

        if (0 === compareResult) {
            compareResult = a.compareToForDescendingDateSort(b);
        }

        return compareResult;
    }

    private buildPeerVisitRowItem(peerVisit: PeerVisit, index: number): JSX.Element[] {

        let client: Client | undefined = undefined;
        if (peerVisit.clientUserFirebaseID) {
            client = this.getClient(peerVisit.clientUserFirebaseID);
        }

        return [
            this.getClientLink(client),
            <p key={'email'} style={cellOverflowHiddenTextStyle}>{client ? client.emailAddress : ''}</p>,
            // <p key={'category'} style={cellTextStyle}>{PeerVisitCategory[peerVisit.category]}</p>,
            <p key={'date'} style={cellTextStyle}>{peerVisit.date.displayString}</p>,
            <p key={'time'} style={cellTextStyle}>{peerVisit.durationDisplay}</p>,
            this.getViewNotesButton(peerVisit) || <p />,
            this.getEditTimeButton(peerVisit) || <p />
        ];
    }

    private buildNoPeerVisitsRowItem(): JSX.Element[] {

        return [
            <p key={'message'} style={{ ...cellTextStyle, ...cellMessageTextStyle }}>{COPY_CONSTANTS.noActivities}</p>
        ];
    }

    private getClient(clientUserFirebaseID: string): Client | undefined {
        const client = this.props.store!.userStore.activeClientsForPeerByID.get(clientUserFirebaseID);
        if (undefined != client) {
            return client;
        }
        return this.state.clientsByIDFromPeerVisits.get(clientUserFirebaseID);
    }

    public render(): JSX.Element | null {

        if (undefined === this.state.peerUserFirebaseID) {
            return null;
        }

        return (
            <div>

                <PeerPage
                    title={COPY_CONSTANTS.timeLog}
                    searchText={this.state.searchText}
                    onSearchTextChanged={(newValue: string) => this.setState({ searchText: newValue })}
                    onAddTimeClick={() => this.setState({ isAddingTime: true })}
                >

                    <ListPanel
                        title={COPY_CONSTANTS.activity}
                        columnNames={[
                            COPY_CONSTANTS.client,
                            COPY_CONSTANTS.email,
                        //    COPY_CONSTANTS.category,
                            COPY_CONSTANTS.date,
                            COPY_CONSTANTS.time,
                            COPY_CONSTANTS.notes,
                            COPY_CONSTANTS.editTime
                        ]}
                        columnWidths={[
                            '14%',
                            '20%',
                        //    '17%',
                            '11%',
                            '9%',
                            '11%',
                            '11%'
                        ]}
                        rowItems={this.getPeerVisitRowItems(this.state.peerVisits)}
                        style={listStyle}
                        titleRowButtons={[

                            <div key={'sort'}>

                                <CustomButton
                                    key={COPY_CONSTANTS.sort}
                                    style={panelButtonStyle}
                                    styleClassName={STYLE_CLASSES.BUTTON_PANEL}
                                    onClick={() => {
                                        this.setState({ isSortMenuOpen: true });
                                    }}
                                >
                                    {COPY_CONSTANTS.sort}
                                </CustomButton>

                                <FloatingMenu
                                    isOpen={this.state.isSortMenuOpen}
                                    options={[
                                        COPY_CONSTANTS.name,
                                    //    COPY_CONSTANTS.category,
                                        COPY_CONSTANTS.date,
                                        COPY_CONSTANTS.time]}
                                    highlightedOption={this.state.sortedBy.toString()}
                                    onOptionClicked={(sortField: string) => {
                                        this.setState({
                                            sortedBy: SortField[sortField],
                                            isSortMenuOpen: false
                                        });
                                    }}
                                    onExternalClick={() => this.setState({ isSortMenuOpen: false })}
                                    backgroundColor={STYLE_CONSTANTS.white}
                                    style={sortMenuStyle}
                                    optionStyle={sortMenuOptionStyle}
                                    optionStyleClassName={STYLE_CLASSES.BUTTON_WHITE}
                                    highlightedOptionStyleClassName={STYLE_CLASSES.BUTTON_MEDIUM_LIGHT_GRAY}
                                />

                            </div>,

                            <CustomButton
                                key={COPY_CONSTANTS.export}
                                style={panelButtonStyle}
                                styleClassName={STYLE_CLASSES.BUTTON_PANEL}
                                onClick={() => {
                                    this.setState({ isExporting: true });
                                }}
                            >

                                {COPY_CONSTANTS.export}

                            </CustomButton>
                        ]}
                        titleRowButtonContainerStyle={titleRowStyle}
                    />

                </PeerPage>

                <PeerVisitViewEditNotesModal
                    peerVisitToEdit={this.state.peerVisits.find((peerVisit: PeerVisit) => peerVisit.peerVisitFirebaseID === this.state.viewingNotesPeerVisitFirebaseID)}
                    onComplete={() => {
                        this.setState({ viewingNotesPeerVisitFirebaseID: undefined });
                    }}
                />

                <PeerVisitAddEditModal
                    isVisible={this.state.isAddingTime || undefined != this.state.editingPeerVisitFirebaseID}
                    peerUserFirebaseID={this.state.peerUserFirebaseID}
                    peerVisitToEdit={this.state.peerVisits.find((peerVisit: PeerVisit) => peerVisit.peerVisitFirebaseID === this.state.editingPeerVisitFirebaseID)}
                    onComplete={() => {
                        this.setState({
                            isAddingTime: false,
                            editingPeerVisitFirebaseID: undefined
                        });
                        this.refreshData();
                    }}
                />

                <PeerVisitExportModal
                    isVisible={this.state.isExporting}
                    peerUserFirebaseID={this.state.peerUserFirebaseID}
                    onComplete={() => this.setState({ isExporting: false })}
                />
            </div>
        );

    }
}

const listStyle: CSSProperties = {
    marginTop: '50px'
};

const cellMessageTextStyle: CSSProperties = {
    fontStyle: 'italic'
};

const sortMenuStyle: React.CSSProperties = {
    top: '5px',
    left: '5px',
    width: '100px'
};

const sortMenuOptionStyle: CSSProperties = {
    color: STYLE_CONSTANTS.darkGray,
    fontSize: STYLE_CONSTANTS.fontSizeSmall
};

const titleRowStyle: CSSProperties = {
    display: 'flex'
};
