import { computed, observable } from 'mobx';

export abstract class AbstractStore {
    @observable protected hasLoadedData: boolean = false;

    @computed
    public get storeIsReady(): boolean {
        return this.hasLoadedData;
    }

    protected abstract loadData(): Promise<void>;

    public initializeStore(): Promise<void> {
        return this.loadData()
            .then(() => {
                this.hasLoadedData = true;
            })
            .catch(err => {
                console.log('AbstractStore initializeStore err: ', err);
                throw(err);
            });
    }
}
