import Reactotron from 'reactotron-react-js'

// maybe we shouldn't be using reactotron, but hey!
if (process.env.NODE_ENV !== 'production') {
    Reactotron
        .configure() // we can use plugins here -- more on this later
        .connect() // let's connect!
        .clear()
} else {
    Reactotron.configure()
}