import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { STYLE_CLASSES, STYLE_CONSTANTS } from '../../assets/StyleConstants';

interface Props {
    isActive: boolean;
}

interface State {
    isVisible: boolean;
    isFading: boolean;
}

@inject('store')
@observer
export class Darkener extends React.Component<Props, State> {

    public render(): JSX.Element | null {

        return (
            <div
                style={darkeningStyle}
                className={this.props.isActive ? STYLE_CLASSES.DIV_DARKEN : STYLE_CLASSES.DIV_NO_DARKEN}
            >
                &nbsp;
            </div>
        );
    }
}

const darkeningStyle: React.CSSProperties = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100vw',
    minHeight: '100vh',
    backgroundColor: STYLE_CONSTANTS.black
};
