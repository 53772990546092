import * as assert from 'assert';
import { computed, observable } from 'mobx';
import { AbstractStore } from './AbstractStore';
import { AuthorizationStore } from './AuthorizationStore';
import { FacilityStore } from './FacilityStore';
import { PeerVisitStore } from './PeerVisitStore';
import { PolicyStore } from './PolicyStore';
import { UserStore } from './UserStore';
import { StatsStore } from './StatsStore';
import { OrgStore } from './OrgStore';
//import { Org } from '../models/Org';

export interface ISetupApplicationStore {
    authorizationStore: AuthorizationStore;
    initializeStore(): Promise<void>;
    unInitialize(): void;
}

export class ApplicationStore extends AbstractStore {

    @observable private _authorizationStore: AuthorizationStore;
    @observable private _facilityStore: FacilityStore;
    @observable private _userStore: UserStore;
    @observable private _statsStore: StatsStore;
    @observable private _peerVisitStore: PeerVisitStore;
    @observable private _policyStore: PolicyStore;
    @observable private _orgStore: OrgStore;

    @observable public isModalShowing: boolean;

    public constructor() {
        super();
        this._authorizationStore = new AuthorizationStore(this);
        this._facilityStore = new FacilityStore();
        this._userStore = new UserStore();
        this._statsStore = new StatsStore();
        this._peerVisitStore = new PeerVisitStore();
        this._policyStore = new PolicyStore();
        this._orgStore = new OrgStore();
    }

    @computed
    public get authorizationStore(): AuthorizationStore {
        return this._authorizationStore;
    }

    @computed
    public get facilityStore(): FacilityStore {
        return this._facilityStore;
    }

    @computed
    public get userStore(): UserStore {
        return this._userStore;
    }

    @computed
    public get statsStore(): StatsStore {
        return this._statsStore;
    }

    @computed
    public get peerVisitStore(): PeerVisitStore {
        return this._peerVisitStore;
    }

    @computed public get policyStore(): PolicyStore {
        return this._policyStore;
    }

    @computed public get orgStore(): OrgStore {
        return this._orgStore;
    }

    protected async loadData(): Promise<void> {
        // authorization store is responsible for calling this method when we have a usable auth token.
        assert(this._authorizationStore.isAuthenticated, 'ApplicationStore - initApp called before auth');
    }

    public unInitialize(): void {
        this._userStore.reset();
        this.hasLoadedData = false;
    }
}
