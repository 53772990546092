import * as firebase from 'firebase';
import { assertNotNull, assertTypeof } from '../../util/assert';
import { IFirebasePeerVisitDays, IFirebasePeerVisit } from '../models/firebase/FirebasePeerVisitDays';
import { FIREBASE_CONSTANTS } from '../../assets/FirebaseConstants';

const PEER_VISITS_PATH = `${FIREBASE_CONSTANTS.environment}/peerVisits`;
const CLASS_NAME = 'PeerVisitService';

export class PeerVisitService {

    private static readonly assertNotNull = assertNotNull(CLASS_NAME);
    private static readonly assertTypeof = assertTypeof(CLASS_NAME);

    private static getPeerNodeRef(peerUserFirebaseID: string): firebase.database.Reference {
        return firebase.database().ref(`${PEER_VISITS_PATH}/${peerUserFirebaseID}`);
    }

    private static getDayRef(peerUserFirebaseID: string, peerVisitDateString: string): firebase.database.Reference {
        return firebase.database().ref(`${PEER_VISITS_PATH}/${peerUserFirebaseID}/${peerVisitDateString}`);
    }

    private static getPeerVisitPath(peerVisitDateString: string, peerVisitFirebaseID: string): string {
        return `${peerVisitDateString}/${peerVisitFirebaseID}`;
    }

    public static async getPeerVisitsForPeer(peerUserFirebaseID: string): Promise<IFirebasePeerVisitDays | null> {

        this.assertNotNull('getPeerVisitsForPeer')(peerUserFirebaseID, 'peerUserFirebaseID');
        this.assertTypeof('getPeerVisitsForPeer')('string')(peerUserFirebaseID, 'peerUserFirebaseID');

        const snapshot =
            await this.getPeerNodeRef(peerUserFirebaseID)
                .once(FIREBASE_CONSTANTS.value);
        
        if (null == snapshot) {
            throw new Error('getPeerVisitsForPeer: null snapshot');
        }

        return snapshot.val();
    }

    public static async addPeerVisit(peerUserFirebaseID: string, peerVisitDateString: string, firebasePeerVisit: IFirebasePeerVisit): Promise<void> {

        this.assertTypeof('addPeerVisit')('string')(peerUserFirebaseID, 'peerUserFirebaseID');
        this.assertNotNull('addPeerVisit')(peerUserFirebaseID, 'peerUserFirebaseID');
        this.assertTypeof('addPeerVisit')('string')(peerVisitDateString, 'peerVisitDateString');
        this.assertNotNull('addPeerVisit')(peerVisitDateString, 'peerVisitDateString');
        this.assertNotNull('addPeerVisit')(firebasePeerVisit, 'firebasePeerVisit');

        return this.getDayRef(peerUserFirebaseID, peerVisitDateString)
            .push({
                ...firebasePeerVisit,
                createdTimestamp: firebase.database.ServerValue.TIMESTAMP
        });
    }

    public static async updatePeerVisit(peerUserFirebaseID: string, peerVisitDateString: string, peerVisitFirebaseID: string, firebasePeerVisit: IFirebasePeerVisit, previousDateString: string): Promise<void> {

        this.assertTypeof('updatePeerVisit')('string')(peerUserFirebaseID, 'peerUserFirebaseID');
        this.assertNotNull('updatePeerVisit')(peerUserFirebaseID, 'peerUserFirebaseID');
        this.assertTypeof('updatePeerVisit')('string')(peerVisitDateString, 'peerVisitDateString');
        this.assertNotNull('updatePeerVisit')(peerVisitDateString, 'peerVisitDateString');
        this.assertTypeof('updatePeerVisit')('string')(peerVisitFirebaseID, 'peerVisitFirebaseID');
        this.assertNotNull('updatePeerVisit')(peerVisitFirebaseID, 'peerVisitFirebaseID');
        this.assertNotNull('updatePeerVisit')(firebasePeerVisit, 'firebasePeerVisit');
        this.assertTypeof('updatePeerVisit')('string')(previousDateString, 'previousDateString');
        this.assertNotNull('updatePeerVisit')(previousDateString, 'previousDateString');

        const nodeUpdates = {};

        // delete the object from its previous location if the date-key is changing
        if (previousDateString !== peerVisitDateString) {
            nodeUpdates[this.getPeerVisitPath(previousDateString, peerVisitFirebaseID)] = null;
        }

        nodeUpdates[this.getPeerVisitPath(peerVisitDateString, peerVisitFirebaseID)] = firebasePeerVisit;

        return this.getPeerNodeRef(peerUserFirebaseID).update(nodeUpdates);
    }

}
