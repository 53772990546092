import * as firebase from 'firebase';
import { FIREBASE_CONSTANTS } from '../../assets/FirebaseConstants';
import { assertNotNull, assertTypeof } from '../../util/assert';
import { IFirebaseUser, IFirebaseUsers, IFirebaseUserWithID } from '../models/firebase/FirebaseUser';
import { UserType } from '../models/UserType';

const USERS_PATH = `${FIREBASE_CONSTANTS.environment}/users`;
const AUTH_USERS_PATH = 'authUsers';

const USER_TYPE_FIELD = 'userType';
const EMAIL_ADDRESS_FIELD = 'emailAddress';
const PEER_USER_KEY = 'peerUser';
const FACILITY_FIREBASE_ID_FIELD = 'facilityFirebaseID';
const IS_ACTIVE_FIELD = 'isActive';
const CLIENT_USER_KEY = 'clientUser';
const PEER_USER_FIREBASE_ID_FIELD = 'peerUserFirebaseID';
const CLASS_NAME = 'UserService';

export class UserService {

    private static readonly assertNotNull = assertNotNull(CLASS_NAME);
    private static readonly assertTypeof = assertTypeof(CLASS_NAME);

    private static get usersRef(): firebase.database.Reference {
        return firebase.database().ref(USERS_PATH);
    }

    private static getUserRef(userFirebaseID: string): firebase.database.Reference {
        return (firebase.database().ref(`${USERS_PATH}/${userFirebaseID}`));
    }

    private static getAuthUserRef(emailAddress: string): firebase.database.Reference {
        return (firebase.database().ref(`${AUTH_USERS_PATH}/${emailAddress}`));
    }

    public static async getUser(userFirebaseID: string): Promise<IFirebaseUser> {

        this.assertNotNull('getUser')(userFirebaseID, 'userFirebaseID');
        this.assertTypeof('getUser')('string')(userFirebaseID, 'userFirebaseID');

        const snapshot =
            await this.getUserRef(userFirebaseID)
                .once(FIREBASE_CONSTANTS.value);

        if (null == snapshot) {
            throw new Error('getUser: null snapshot');
        }

        return snapshot.val();
    }

    public static async getPeersForFacility(facilityFirebaseID: string): Promise<IFirebaseUsers> {

        this.assertNotNull('getPeersForFacility')(facilityFirebaseID, 'facilityFirebaseID');
        this.assertTypeof('getPeersForFacility')('string')(facilityFirebaseID, 'facilityFirebaseID');

        const snapshot =
            await this.usersRef
                .orderByChild(`${PEER_USER_KEY}/${FACILITY_FIREBASE_ID_FIELD}`)
                .equalTo(facilityFirebaseID)
                .once(FIREBASE_CONSTANTS.value);

        if (null == snapshot) {
            throw new Error('getPeersForFacility: null snapshot');
        }

        return snapshot.val();
    }

    public static async getClientsForPeer(peerUserFirebaseID: string): Promise<IFirebaseUsers> {

        this.assertNotNull('getClientsForPeer')(peerUserFirebaseID, 'peerUserFirebaseID');
        this.assertTypeof('getClientsForPeer')('string')(peerUserFirebaseID, 'peerUserFirebaseID');

        const snapshot =
                await this.usersRef
                    .orderByChild(`${CLIENT_USER_KEY}/${PEER_USER_FIREBASE_ID_FIELD}`)
                    .equalTo(peerUserFirebaseID)
                    .once(FIREBASE_CONSTANTS.value);

        if (null == snapshot) {
            throw new Error('getClientsForPeer: null snapshot');
        }

        return snapshot.val();
    }

    public static async getClientsByIDs(clientUserFirebaseIDs: Set<string>): Promise<IFirebaseUsers> {

        this.assertNotNull('getClientsByIDs')(clientUserFirebaseIDs, 'clientUserFirebaseIDs');

        const firebaseUsers: IFirebaseUsers = {};

        let operations = Array.from(clientUserFirebaseIDs)
            .map(async (clientUserFirebaseID: string) => {
                const user = await this.getUser(clientUserFirebaseID);
                firebaseUsers[clientUserFirebaseID] = user;
            });

        await Promise.all(operations);

        return firebaseUsers;
    }

    public static listenPeers(
        onPeerAddedHandler: (firebasePeerUserWithID: IFirebaseUserWithID) => void,
        onPeerChangedHandler: (firebasePeerUserWithID: IFirebaseUserWithID) => void,
        onPeerRemovedHandler: (firebaseID: string) => void
    ): void {

        this.assertNotNull('listenToPeers')(onPeerAddedHandler, 'onPeerAddedHandler');
        this.assertNotNull('listenToPeers')(onPeerChangedHandler, 'onPeerChangedHandler');
        this.assertNotNull('listenToPeers')(onPeerRemovedHandler, 'onPeerRemovedHandler');

        this.usersRef.off();

        this.usersRef
            .orderByChild(USER_TYPE_FIELD)
            .equalTo(UserType.Peer)
            .on(
                FIREBASE_CONSTANTS.eventChildAdded,
                async snapshot =>
                    this.handleUserAddedOrChanged(snapshot, onPeerAddedHandler)
            );

        this.usersRef
            .orderByChild(USER_TYPE_FIELD)
            .equalTo(UserType.Peer)
            .on(
                FIREBASE_CONSTANTS.eventChildChanged,
                async snapshot =>
                    this.handleUserAddedOrChanged(snapshot, onPeerChangedHandler)
            );

        this.usersRef
            .orderByChild(USER_TYPE_FIELD)
            .equalTo(UserType.Peer)
            .on(
                FIREBASE_CONSTANTS.eventChildRemoved,
                async snapshot =>
                    this.handleUserRemoved(snapshot, onPeerRemovedHandler)
            );
    }

    public static listenClientsForPeer(
        peerFirebaseID: string,
        onClientAddedHandler: (firebaseClientUserWithID: IFirebaseUserWithID) => void,
        onClientChangedHandler: (firebaseClientUserWithID: IFirebaseUserWithID) => void,
        onClientRemovedHandler: (firebaseID: string) => void
    ): void {
        this.assertNotNull('listenToClientsForPeer')(onClientAddedHandler, 'onClientAddedHandler');
        this.assertNotNull('listenToClientsForPeer')(onClientChangedHandler, 'onClientChangedHandler');
        this.assertNotNull('listenToClientsForPeer')(onClientRemovedHandler, 'onClientRemovedHandler');

        const peerIDChildKey = `${CLIENT_USER_KEY}/${PEER_USER_FIREBASE_ID_FIELD}`;
        console.log('listen clients', peerIDChildKey);

        this.usersRef.off();

        this.usersRef
            .orderByChild(peerIDChildKey)
            .equalTo(peerFirebaseID)
            .on(
                FIREBASE_CONSTANTS.eventChildAdded,
                async snapshot =>
                    this.handleUserAddedOrChanged(snapshot, onClientAddedHandler)
            );

        this.usersRef
            .orderByChild(peerIDChildKey)
            .equalTo(peerFirebaseID)
            .on(
                FIREBASE_CONSTANTS.eventChildChanged,
                async snapshot =>
                    this.handleUserAddedOrChanged(snapshot, onClientChangedHandler)
            );

        this.usersRef
            .orderByChild(peerIDChildKey)
            .equalTo(peerFirebaseID)
            .on(
                FIREBASE_CONSTANTS.eventChildRemoved,
                async snapshot =>
                    this.handleUserRemoved(snapshot, onClientRemovedHandler)
            );
    }

    public static unlistenClients(): void {
        this.unlistenPeers();
    }

    public static unlistenPeers(): void {

        this.usersRef.off();
    }

    private static async handleUserAddedOrChanged(
        snapshot: firebase.database.DataSnapshot | null,
        onUserAddedOrChangedHandler: (firebaseUserWithID: IFirebaseUserWithID) => void
    ): Promise<void> {

        this.assertNotNull('handleUserAddedOrChanged')(onUserAddedOrChangedHandler, 'onUserAddedOrChangedHandler');

        if (null == snapshot) {
            throw new Error('handleUserAdded: null snapshot');
        }

        if (null == snapshot.key) {
            throw new Error('handleUserAdded: null firebaseID');
        }

        const user: IFirebaseUser = snapshot.val();

        if (null == user) {
            throw new Error('handleUserAdded: null user');
        }

        const userWithID: IFirebaseUserWithID = {
            user: user,
            firebaseID: snapshot.key
        };

        onUserAddedOrChangedHandler(userWithID);
    }

    private static async handleUserRemoved(
        snapshot: firebase.database.DataSnapshot | null,
        onUserRemovedHandler: (firebaseID: string) => void
    ): Promise<void> {

        this.assertNotNull('handleUserRemoved')(onUserRemovedHandler, 'onUserRemovedHandler');

        if (null == snapshot) {
            throw new Error('handleUserRemoved: null snapshot');
        }

        if (null == snapshot.key) {
            throw new Error('handleUserAddedOrChanged: null firebaseID');
        }

        onUserRemovedHandler(snapshot.key);
    }

    public static async getCurrentUser(): Promise<IFirebaseUserWithID> {

        if (null == firebase.auth().currentUser) {
            throw new Error('UserService.get(): no current auth user');
        }

        const firebaseUid = firebase.auth().currentUser!.uid;
        const snapshot = await this.getUserRef(firebaseUid)
            .once(FIREBASE_CONSTANTS.value);

        return {
            user: snapshot.val(),
            firebaseID: snapshot.key
        };
    }

    public static async upsertUser(userFirebaseID: string, firebaseUser: IFirebaseUser): Promise<void> {

        this.assertNotNull('upsertUser')(userFirebaseID, 'userFirebaseID');
        this.assertNotNull('upsertUser')(firebaseUser, 'firebaseUser');

        return (
            this.getUserRef(userFirebaseID)
                .update({
                    ...firebaseUser,
                    createdTimestamp: firebaseUser.createdTimestamp
                        ? firebaseUser.createdTimestamp
                        : firebase.database.ServerValue.TIMESTAMP
                })
        );
    }

    public static async deactivatePeer(peerUserFirebaseID: string, clientUserFirebaseIDs: Set<string>): Promise<void> {

        const nodeUpdates = {};

        nodeUpdates[`${peerUserFirebaseID}/${IS_ACTIVE_FIELD}`] = false;

        clientUserFirebaseIDs.forEach((clientUserFirebaseID: string) => {
            nodeUpdates[`${clientUserFirebaseID}/${CLIENT_USER_KEY}/${PEER_USER_FIREBASE_ID_FIELD}`] = null;
        });

        return this.usersRef.update(nodeUpdates);
    }

    public static async removeClientFromPeer(clientUserFirebaseID: string, peerUserFirebaseID: string): Promise<void> {

        return (
            this.getUserRef(clientUserFirebaseID)
                .child(CLIENT_USER_KEY)
                .child(PEER_USER_FIREBASE_ID_FIELD)
                .remove()
        );
    }

    public static async doesUserExistWithEmail(emailAddress: string): Promise<boolean> {

        const existingUser = await this.getUserForEmail(emailAddress);
        return null != existingUser;
    }

    public static async isEmailForPeerOrFounder(emailAddress: string): Promise<boolean> {
        const existingUser = await this.getUserForEmail(emailAddress);

        return null != existingUser && existingUser[1].userType !== UserType.Client;
    }

    public static async getUserForEmail(emailAddress: string): Promise<[string, IFirebaseUser] | null> {
        const emailAddressLowerCase = emailAddress.toLowerCase();

        const snapshot = await this.usersRef
            .orderByChild(EMAIL_ADDRESS_FIELD)
            .limitToFirst(1)
            .equalTo(emailAddressLowerCase)
            .once(FIREBASE_CONSTANTS.value);

        if (null == snapshot) {
            throw new Error('doesUserExistWithEmail: null snapshot');
        }

        const firebaseUsers = snapshot.val();
        if (null == firebaseUsers) {
            return null;
        }
        const firstUserID = Object.keys(firebaseUsers)[0];
        return [firstUserID, firebaseUsers[firstUserID]];
    }

    public static async addClientToPeer(newClientFirebaseID: string, peerUserFirebaseID: string): Promise<void> {

        return (
            this.getUserRef(newClientFirebaseID)
                .child(CLIENT_USER_KEY)
                .child(PEER_USER_FIREBASE_ID_FIELD)
                .set(peerUserFirebaseID)
        );
    }

    public static async addAuthUser(emailAddress: string, firebaseUid: string): Promise<void> {

        this.assertTypeof('addAuthUser')('string')(emailAddress, 'emailAddress');
        this.assertNotNull('addAuthUser')(emailAddress, 'emailAddress');
        this.assertTypeof('addAuthUser')('string')(firebaseUid, 'firebaseUid');
        this.assertNotNull('addAuthUser')(firebaseUid, 'firebaseUid');

        return (
            this.getAuthUserRef(this.makeEmailAddressKeySafe(emailAddress))
                .set(firebaseUid)
        );
    }

    public static async getAuthUserFirebaseUid(emailAddress: string): Promise<string | undefined> {

        this.assertTypeof('getAuthUserFirebaseUid')('string')(emailAddress, 'emailAddress');
        this.assertNotNull('getAuthUserFirebaseUid')(emailAddress, 'emailAddress');

        const snapshot =
            await this.getAuthUserRef(this.makeEmailAddressKeySafe(emailAddress))
                .once(FIREBASE_CONSTANTS.value);

        if (null == snapshot) {
            throw new Error('getAuthUserFirebaseUid: null snapshot');
        }

        return snapshot.val();
    }

    public static async getUserOnboardedStatus(email: string): Promise<{ isOnboarded?: boolean; isClient?: boolean }> {
        const encodedEmail = encodeURIComponent(email);
        const encodedEnvironment = encodeURIComponent(`${FIREBASE_CONSTANTS.environment}`);

        const response = await fetch(
            `${process.env.REACT_APP_FIREBASE_CLOUD_FUNCTIONS_URL}/checkUserOnboarded?email=${encodedEmail}&dbnode=${encodedEnvironment}`
        );

        const userInfo: { isOnboarded: boolean; isClient: boolean; } = await response.json();

        return userInfo;
    }

    public static makeEmailAddressKeySafe(emailAddress: string): string {
        return emailAddress.replace(/\./g, ',');
    }
}
