import * as React from 'react';
import { CSSProperties } from 'react';
import { STYLE_CONSTANTS, STYLE_CLASSES } from '../../assets/StyleConstants';
import { CustomButton } from '../components/Button';
import { COPY_CONSTANTS } from '../../assets/CopyConstants';
import { Fadeable } from './Fadeable';
import { pageTitleStyle, modalStyle } from '../../assets/CommonStyles';

interface Props {
    isVisible: boolean;
    title: string;
    message: string;
    confirmButtonText: string;
    cancelButtonText?: string;
    confirmButtonStyleClassName: string;
    cancelButtonStyleClassName: string;
    onConfirm: () => void;
    onCancel?: () => void;
    isInsideAnotherModal?: boolean;
    isFullScreen?: boolean;
}

export class ConfirmationModal extends React.Component<Props, {}> {

    public render(): JSX.Element | null {

        return (
            <Fadeable
                shouldDisplay={this.props.isVisible}
                style={this.props.isFullScreen ? modalFullScreenStyle : confirmationModalStyle}
                isInsideAnotherModal={this.props.isInsideAnotherModal}
            >

                <div style={this.props.isFullScreen ? contentFullScreenStyle : contentStyle}>

                    <div style={titleStyle} className={STYLE_CLASSES.DIV_LIGHT_BOLD}>
                        {this.props.title}
                    </div>

                    <div style={messageStyle}>
                        {this.props.message}
                    </div>

                    <div style={buttonRowStyle}>

                        <CustomButton
                            isDisabled={false}
                            style={confirmButtonStyle}
                            styleClassName={this.props.confirmButtonStyleClassName}
                            onClick={() => this.props.onConfirm()}
                        >
                            {this.props.confirmButtonText}
                        </CustomButton>

                        {this.props.onCancel &&
                            <CustomButton
                                isDisabled={false}
                                style={cancelButtonStyle}
                                styleClassName={this.props.cancelButtonStyleClassName}
                                onClick={this.props.onCancel}
                            >
                                {this.props.cancelButtonText || COPY_CONSTANTS.cancel}
                            </CustomButton>
                        }
                    </div>
                
                </div>

            </Fadeable>
        );
    }
}

const confirmationModalStyle: CSSProperties = {
    ...modalStyle,
    zIndex: 4
};

const modalFullScreenStyle: CSSProperties = {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100vw',
    minHeight: '100vh',
    paddingBottom: '50px',
    backgroundColor: STYLE_CONSTANTS.lightGray,
    boxSizing: 'border-box',
    zIndex: 5
};

const contentStyle: CSSProperties = {
    margin: 'auto',
    marginTop: '100px',
    marginBottom: '100px',
    width: '40%',
    maxWidth: '1000px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
};

const contentFullScreenStyle: CSSProperties = {
    ...contentStyle,
    marginTop: '30vh'
};

const titleStyle: CSSProperties = {
    ...pageTitleStyle,
    textAlign: 'center'
};

const messageStyle: CSSProperties = {
    fontSize: STYLE_CONSTANTS.fontSizeMedium,
    color: STYLE_CONSTANTS.mediumGray,
    marginTop: '30px',
    textAlign: 'center'
};

const buttonRowStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: '30px',
};

const confirmButtonStyle: CSSProperties = {
    border: 'none',
    textTransform: 'none',
    width: '200px',
    height: '60px',
    fontSize: STYLE_CONSTANTS.fontSizePrimaryButton,
    color: STYLE_CONSTANTS.white
};

const cancelButtonStyle: CSSProperties = {
    ...confirmButtonStyle,
    color: STYLE_CONSTANTS.black,
    marginLeft: '20px'
};
