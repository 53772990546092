import * as assert from 'assert';
import * as firebase from 'firebase';
import { computed, observable } from 'mobx';
import { FIREBASE_CONSTANTS } from '../../assets/FirebaseConstants';
import { UserType } from '../models/UserType';
import { UserService } from '../services/UserService';
import { ISetupApplicationStore } from './ApplicationStore';
import  {User as FirebaseUser} from 'firebase';

const config = {
    apiKey: process.env.REACT_APP_FIREBASE_WEB_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_CLOUD_MESSAGING_SENDER_ID
};

firebase.initializeApp(config);

export class AuthorizationStore {
    private _applicationStore: ISetupApplicationStore;
    @observable private _isAuthenticated: boolean;
    @observable private _autoLogOnComplete: boolean = false;
    @observable private _firebaseUser?: FirebaseUser;

    constructor(applicationStore: ISetupApplicationStore) {
        this._isAuthenticated = false;
        this._applicationStore = applicationStore;
        this.startAuthListener();
    }

    @computed
    public get isAuthenticated(): boolean {
        return this._isAuthenticated;
    }

    @computed
    public get autoLogOnComplete(): boolean {
        return this._autoLogOnComplete;
    }

    @computed
    public get firebaseUser(): FirebaseUser {
        assert(null != this._firebaseUser, 'called get firebaseUser before it was available');
        if (this._firebaseUser) {
            return this._firebaseUser;
        } else {
            throw new Error('called get firebaseUser before it was available');
        }
    }

    public startAuthListener(): void {
        firebase.auth().onAuthStateChanged(
            async (authUser: firebase.User | null) => {
                try {
                    await this._authCallback();
                } catch { }
            },
            (error: firebase.auth.Error) => {
                this.logOut();
                this._autoLogOnComplete = true;
            },
            () => {
                this._autoLogOnComplete = true;
            }
        );
    }

    private async _authCallback(): Promise<void> {
        this._firebaseUser = firebase.auth().currentUser || undefined;
        if (null != this._firebaseUser) {
            const userProfile = await UserService.getUser(this.firebaseUser.uid);
            if (false === userProfile.isActive) {
                throw new Error('User is inactive');
            }
            if (UserType.Client === userProfile.userType) {
                throw new Error('Access is not permitted');
            }
            return this._handleAuthenticatedUser();
        }
        return this.logOut();
    }

    private async _handleAuthenticatedUser(): Promise<void> {

        try {
            this._isAuthenticated = true;
            this._applicationStore.initializeStore();
            this._autoLogOnComplete = true;
        } catch (error) {
            this.logOut();
        }
    }

    public async logIn(userName: string, password: string): Promise<void> {
        await firebase.auth().signInWithEmailAndPassword(userName, password);

        return this._authCallback();
    }

    public logOut(): void {
        this._applicationStore.unInitialize();
        this._isAuthenticated = false;
        this._autoLogOnComplete = true;
        this._firebaseUser = undefined;
        firebase.auth().signOut();
    }

    public async setInitialPassword(password: string): Promise<void> {
        const currentUser = firebase.auth().currentUser;
        if (currentUser) {
            await currentUser.updatePassword(password);
        } else {
            throw new Error('AuthorizationStore.setInitialPassword: no user logged in');
        }
    }

    public async sendPasswordResetEmail(emailAddress: string): Promise<void> {
        return firebase.auth().sendPasswordResetEmail(emailAddress);
    }

    public async createUserWithEmailAndPassword(emailAddress: string): Promise<string> {

        // use a secondary app reference so that current user isn't logged out of the main app ref automatically
        //  when the new user is created (and automically logged in)
        const tempAppReference = firebase.initializeApp(config, FIREBASE_CONSTANTS.secondaryAppReferenceName);

        let newFirebaseUid = '';

        try {
            const newFirebaseUser = await tempAppReference.auth()
                .createUserWithEmailAndPassword(emailAddress, FIREBASE_CONSTANTS.defaultPassword);
            newFirebaseUid = newFirebaseUser.user.uid; // MJM updated to match current firebase model?
        } finally {
            await tempAppReference.delete();
        }

        return newFirebaseUid;
    }

}
