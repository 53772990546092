import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { ApplicationStore } from '../../data/stores/ApplicationStore';
import { CustomButton } from '../components/Button';
import { COPY_CONSTANTS } from '../../assets/CopyConstants';
import { STYLE_CONSTANTS, STYLE_CLASSES } from '../../assets/StyleConstants';
import { FloatingMenu } from './FloatingMenu';

interface Props {
    store?: ApplicationStore;
}

interface State {
    userInitials: string;
    isMenuOpen: boolean;
}

@inject('store')
@observer
export class ProfileButton extends React.Component<Props, State> {

    public state: State = {
        userInitials: '',
        isMenuOpen: false
    };

    private profileButtonDiv: HTMLDivElement;

    public async componentWillMount(): Promise<void> {

        if (null == this.props.store) {
            throw new Error('ProfileButton.componentWillMount: no store');
        }

        const currentUser = await this.props.store.userStore.getCurrentUser();

        this.setState({
            userInitials: currentUser.initials
        });
    }

    public componentDidMount(): void {
        document.addEventListener('mousedown', this.handleClickOutside);
    }
    
    public componentWillUnmount(): void {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }
    
    private handleClickOutside = (event: any): void => {
        if (
            this.profileButtonDiv && false === this.profileButtonDiv.contains(event.target)
        ) {
          this.setState({ isMenuOpen: false });
        }
    }

    public render(): JSX.Element | null {

        return (
            <div
                ref={(div: HTMLDivElement): void => { this.profileButtonDiv = div; }}
            >
                <CustomButton
                    styleClassName={STYLE_CLASSES.BUTTON_PROFILE}
                    isCircle={true}
                    onClick={() => this.setState({
                        isMenuOpen: false === this.state.isMenuOpen
                    })}
                >

                    {this.state.userInitials}
                </CustomButton>

                <FloatingMenu
                    isOpen={this.state.isMenuOpen}
                    options={[COPY_CONSTANTS.logOut]}
                    onOptionClicked={() => {
                        if (this.props.store) {
                            this.props.store.authorizationStore.logOut();
                        }
                    }}
                    backgroundColor={STYLE_CONSTANTS.white}
                    style={menuStyle}
                    optionStyle={menuOptionStyle}
                    optionStyleClassName={STYLE_CLASSES.BUTTON_WHITE}

                />

            </div>
        );
    }
}

const menuStyle: React.CSSProperties = {
    top: '5px',
    left: '-32px',
    width: '100px'
};

const menuOptionStyle: React.CSSProperties = {
    color: STYLE_CONSTANTS.darkGray,
    fontSize: STYLE_CONSTANTS.fontSizeSmall
};
