import * as assert from 'assert';
import { PeerVisitCategory } from '../models/PeerVisitCategory';
import { IFirebasePeerVisit } from './firebase/FirebasePeerVisitDays';
import { Date } from '../models/Date';
import { durationMinutesToDisplay } from '../../util/common';

export class PeerVisit {

    private _peerVisitFirebaseID: string;
    private _peerUserFirebaseID: string;
    private _category: PeerVisitCategory;
    private _date: Date;
    private _durationMinutes: number;
    private _notes: string;
    private _createdTimestamp: number;
    private _clientUserFirebaseID: string | undefined;

    private constructor() {}

    public get peerVisitFirebaseID(): string {
        return this._peerVisitFirebaseID;
    }

    public get peerUserFirebaseID(): string {
        return this._peerUserFirebaseID;
    }

    public get category(): PeerVisitCategory {
        return this._category;
    }

    public get date(): Date {
        return this._date;
    }

    public get dateTimeCompareString(): string {
        return this._date.moment.toISOString();
    }

    public get durationMinutes(): number {
        return this._durationMinutes;
    }

    public get durationDisplay(): string {
        
        return durationMinutesToDisplay(this._durationMinutes);
    }

    public get notes(): string {
        return this._notes;
    }

    public set notes(value: string) {
        this._notes = value;
    }

    public get clientUserFirebaseID(): string | undefined {
        return this._clientUserFirebaseID;
    }

    public static createFromFirebase(firebasePeerUserID: string, dateString: string, firebasePeerVisitID: string, firebasePeerVisit: IFirebasePeerVisit): PeerVisit {

        const peerVisit = new PeerVisit();

        assert(typeof firebasePeerVisitID === 'string', 'PeerVisit constructor passed args with invalid firebasePeerVisitID');
        assert(typeof dateString === 'string', 'PeerVisit constructor passed args with invalid dateString');
        assert(typeof firebasePeerUserID === 'string', 'PeerVisit constructor passed args with invalid firebasePeerUserID');
        assert(typeof firebasePeerVisit.category === 'string', 'PeerVisit constructor passed args with invalid category');
        assert(typeof firebasePeerVisit.durationMinutes === 'number', 'PeerVisit constructor passed args with invalid durationMinutes');
        assert(typeof firebasePeerVisit.notes === 'string', 'PeerVisit constructor passed args with invalid notes');
        assert(typeof firebasePeerVisit.createdTimestamp === 'number', 'PeerVisit constructor passed args with invalid createdTimestamp');
        peerVisit._peerVisitFirebaseID = firebasePeerVisitID;
        peerVisit._peerUserFirebaseID = firebasePeerUserID;
        peerVisit._category = firebasePeerVisit.category;
        peerVisit._date = new Date(dateString);
        peerVisit._durationMinutes = firebasePeerVisit.durationMinutes;
        peerVisit._notes = firebasePeerVisit.notes;
        peerVisit._createdTimestamp = firebasePeerVisit.createdTimestamp;

        if (firebasePeerVisit.clientUserFirebaseID) {
            peerVisit._clientUserFirebaseID = firebasePeerVisit.clientUserFirebaseID;
        }

        return peerVisit;
    }

    public static create(peerUserFirebaseID: string, clientUserFirebaseID: string | undefined, category: PeerVisitCategory, date: Date, durationMinutes: number, notes: string, existingPeerVisit: PeerVisit | undefined): PeerVisit {
        
        const peerVisit = existingPeerVisit  || new PeerVisit();
        peerVisit._peerUserFirebaseID = peerUserFirebaseID;
        peerVisit._clientUserFirebaseID = clientUserFirebaseID;
        peerVisit._category = category;
        peerVisit._date = date;
        peerVisit._durationMinutes = durationMinutes;
        peerVisit._notes = notes;

        if (undefined === existingPeerVisit) {
            peerVisit._createdTimestamp = 0;
        }

        return peerVisit;
    }

    public toFirebasePeerVisit(): IFirebasePeerVisit {

        return {
            category: this._category,
            durationMinutes: this._durationMinutes,
            notes: this._notes,
            createdTimestamp: this._createdTimestamp,
            clientUserFirebaseID: this._clientUserFirebaseID || null
        };
    }

    public compareToForDescendingDateSort(peerVisit: PeerVisit): number {

        const dateCompare = this.date.dateString.localeCompare(peerVisit.date.dateString);

        if (dateCompare !== 0) {
            return -1 * dateCompare;
        }

        return peerVisit._createdTimestamp - this._createdTimestamp;
    }
    
}
