import * as React from 'react';
import { CSSProperties } from 'react';
import { inject, observer } from 'mobx-react';
import { ApplicationStore } from '../../data/stores/ApplicationStore';
import { RouteComponentProps } from 'react-router-dom';
import { STYLE_CONSTANTS } from '../../assets/StyleConstants';
import { Header } from '../components/Header';
import { COPY_CONSTANTS } from '../../assets/CopyConstants';
import { StatsGraph } from '../components/StatsGraph';
import * as Airtable from 'airtable';
import { Panel } from '../components/Panel';
import { CustomButton } from '../components/Button';
import { Founder } from '../../data/models/Founder';

const APP_ID = process.env.REACT_APP_AIRTABLE_APP_ID;
const API_KEY = process.env.REACT_APP_AIRTABLE_API_KEY;

interface Props extends RouteComponentProps<{}> {
    store: ApplicationStore;
}

interface State {
    isFounder?: boolean;
    stats?: any;
    yesFrequency: {
        x: number;
        y: number;
        label: string;
    }[];
    questionFrequency: {
        x: number;
        y: number;
        label: string;
    }[];
    invalidResponsesCount: number;
    viewingAll: boolean;
}

interface Row extends Airtable.FieldSet {
    initial_message_body: string;
}

@inject('store')
@observer
export class Stats extends React.Component<Props, State> {

    public state: State = {
        isFounder: undefined,
        stats: [],
        yesFrequency: [],
        questionFrequency: [],
        invalidResponsesCount: 0,
        viewingAll: false
    };

    private async refreshData(): Promise<void> {

        const currentUser = await this.props.store!.userStore.getCurrentUser();
        if (undefined === currentUser.firebaseUid) {
            return;
        }

        if (false === currentUser instanceof Founder) {
            throw new Error('Only Founders may access this page');
        }

        this.setState({
            isFounder: currentUser instanceof Founder
        });

        this.refreshResponses();
    }

    public async componentWillMount(): Promise<void> {

        if (null == this.props.store) {
            throw new Error('Stats: no store');
        }

        this.refreshData();
    }

    private isValidResponse(record: Row): boolean {
        // a response is valid if there is any data for any of the questions. if none of the questions have any data
        // then it's possible they used the wrong trigger and got the default Recovree response back
        return !!(record.fields!['q1'] || record.fields!['q2'] || record.fields!['q3'] || record.fields!['q4'] || record.fields!['q5'] || record.fields!['q6']);
    }

    private isQuestionAnswered(answer: string): boolean {
        return !!answer;
    }

    private isYes(fieldValue: string): boolean {
        const yesRegex = /^(?:1|t(?:rue)?|y(?:es|a|ay|eah)?|ok(?:ay)?|sure)$/i;
        if (fieldValue) {
            const trimmedValue = fieldValue.trim();
            const match = trimmedValue.match(yesRegex);
            return !!match;
        }
        return false;
    }

    private countYeses(allRecords: [Row], fieldName: string): number {
        return allRecords.reduce((accumulator: number, currentRecord: Row) => {
            const fieldValue = currentRecord.fields![fieldName];
            if (this.isYes(fieldValue)) {
                return accumulator + 1;
            } else {
                return accumulator + 0;
            }
        }, 0);
    }

    private async refreshResponses(): Promise<void> {
        await this.loadResponses();
        this.processResponses();
    }

    private async loadResponses(): Promise<void> {
        const base = new Airtable({ apiKey: API_KEY }).base(APP_ID as string);

        const table = base('RECOVREE_BOT_RESPONSES');
        let selectOptions = {}; // fields: [''] - this needs to be added to the type definition
        if (!this.state.viewingAll) {
            selectOptions = { view: 'Minnedemo' };
        }
        const query = table.select(selectOptions);
        const rows = await query.all();
        this.setState({ stats: rows });
    }

    private processResponses(): void {
        let yesFrequencyData = [{x: 0, y: 0, label: ''}, {x: 1, y: 0, label: ''}, {x: 2, y: 0, label: ''}, {x: 3, y: 0, label: ''}, {x: 4, y: 0, label: ''}, {x: 5, y: 0, label: ''}, {x: 6, y: 0, label: ''}];
        let invalidResponsesCount = 0;
        let validAnswers = [0, 0, 0, 0, 0, 0, 0];
        this.state.stats!.map((record: Row) => {
            if (this.isValidResponse(record)) {
                let numYeses = 0;
                if (this.isQuestionAnswered(record.fields!['q1'])) {
                    validAnswers[1] = validAnswers[1] + 1;
                    if (this.isYes(record.fields!['q1'])) {
                        numYeses = numYeses + 1;
                    }
                }
                if (this.isQuestionAnswered(record.fields!['q2'])) {
                    validAnswers[2] = validAnswers[2] + 1;
                    if (this.isYes(record.fields!['q2'])) {
                        numYeses = numYeses + 1;
                    }
                }
                if (this.isQuestionAnswered(record.fields!['q3'])) {
                    validAnswers[3] = validAnswers[3] + 1;
                    if (this.isYes(record.fields!['q3'])) {
                        numYeses = numYeses + 1;
                    }
                }
                if (this.isQuestionAnswered(record.fields!['q4'])) {
                    validAnswers[4] = validAnswers[4] + 1;
                    if (this.isYes(record.fields!['q4'])) {
                        numYeses = numYeses + 1;
                    }
                }
                if (this.isQuestionAnswered(record.fields!['q5'])) {
                    validAnswers[5] = validAnswers[5] + 1;
                    if (this.isYes(record.fields!['q5'])) {
                        numYeses = numYeses + 1;
                    }
                }
                if (this.isQuestionAnswered(record.fields!['q6'])) {
                    validAnswers[6] = validAnswers[6] + 1;
                    if (this.isYes(record.fields!['q6'])) {
                        numYeses = numYeses + 1;
                    }
                }
                switch (numYeses) {
                case 0:
                    yesFrequencyData[0].y = yesFrequencyData[0].y + 1;
                    break;
                case 1:
                    yesFrequencyData[1].y = yesFrequencyData[1].y + 1;
                    break;
                case 2:
                    yesFrequencyData[2].y = yesFrequencyData[2].y + 1;
                    break;
                case 3:
                    yesFrequencyData[3].y = yesFrequencyData[3].y + 1;
                    break;
                case 4:
                    yesFrequencyData[4].y = yesFrequencyData[4].y + 1;
                    break;
                case 5:
                    yesFrequencyData[5].y = yesFrequencyData[5].y + 1;
                    break;
                case 6:
                    yesFrequencyData[6].y = yesFrequencyData[6].y + 1;
                    break;
                default:
                    break;
                }
            } else {
                invalidResponsesCount++;
            }
        });

        this.setState({ invalidResponsesCount: invalidResponsesCount });

        let statsTotal = this.state.stats.length - this.state.invalidResponsesCount;
        yesFrequencyData[0].label = `${(yesFrequencyData[0].y / statsTotal * 100).toFixed(2)}%`;
        yesFrequencyData[1].label = `${(yesFrequencyData[1].y / statsTotal * 100).toFixed(2)}%`;
        yesFrequencyData[2].label = `${(yesFrequencyData[2].y / statsTotal * 100).toFixed(2)}%`;
        yesFrequencyData[3].label = `${(yesFrequencyData[3].y / statsTotal * 100).toFixed(2)}%`;
        yesFrequencyData[4].label = `${(yesFrequencyData[4].y / statsTotal * 100).toFixed(2)}%`;
        yesFrequencyData[5].label = `${(yesFrequencyData[5].y / statsTotal * 100).toFixed(2)}%`;
        yesFrequencyData[6].label = `${(yesFrequencyData[6].y / statsTotal * 100).toFixed(2)}%`;

        this.setState({ yesFrequency: yesFrequencyData });

        let questionFrequencyData = [{x: 0, y: 0, label: ''}, {x: 1, y: 0, label: ''}, {x: 2, y: 0, label: ''}, {x: 3, y: 0, label: ''}, {x: 4, y: 0, label: ''}, {x: 5, y: 0, label: ''}, {x: 6, y: 0, label: ''}];
        const finalQ1 = this.countYeses(this.state.stats, 'q1');
        questionFrequencyData[1].y = finalQ1;
        const finalQ2 = this.countYeses(this.state.stats, 'q2');
        questionFrequencyData[2].y = finalQ2;
        const finalQ3 = this.countYeses(this.state.stats, 'q3');
        questionFrequencyData[3].y = finalQ3;
        const finalQ4 = this.countYeses(this.state.stats, 'q4');
        questionFrequencyData[4].y = finalQ4;
        const finalQ5 = this.countYeses(this.state.stats, 'q5');
        questionFrequencyData[5].y = finalQ5;
        const finalQ6 = this.countYeses(this.state.stats, 'q6');
        questionFrequencyData[6].y = finalQ6;

        questionFrequencyData[1].label = `${(questionFrequencyData[1].y / validAnswers[1] * 100).toFixed(2)}%`;
        questionFrequencyData[2].label = `${(questionFrequencyData[2].y / validAnswers[2] * 100).toFixed(2)}%`;
        questionFrequencyData[3].label = `${(questionFrequencyData[3].y / validAnswers[3] * 100).toFixed(2)}%`;
        questionFrequencyData[4].label = `${(questionFrequencyData[4].y / validAnswers[4] * 100).toFixed(2)}%`;
        questionFrequencyData[5].label = `${(questionFrequencyData[5].y / validAnswers[5] * 100).toFixed(2)}%`;
        questionFrequencyData[6].label = `${(questionFrequencyData[6].y / validAnswers[6] * 100).toFixed(2)}%`;

        this.setState({ questionFrequency: questionFrequencyData });
    }

    public render(): JSX.Element | null {

        if (undefined == this.state.isFounder) {
            return null;
        }

        return (
            <div>
                <div style={containerStyle}>
                    <Header
                        title={COPY_CONSTANTS.stats}
                        addButtons={[]}
                    />

                    <Panel
                        title={COPY_CONSTANTS.our_community}
                        titleRowButtonContainerStyle={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}
                        titleRowButtons={[
                        (
                            <CustomButton
                                key={'minnedemo'}
                                style={{
                                    ...leftGraphSwitchStyle,
                                    backgroundColor: !this.state.viewingAll ? STYLE_CONSTANTS.darkestPurple : STYLE_CONSTANTS.lightPurple
                                }}
                                onClick={() => {
                                    this.setState({ viewingAll: false }, () => {
                                        this.refreshResponses();
                                        });
                                    }
                                }
                            >
                                {COPY_CONSTANTS.minnedemo}
                            </CustomButton>
                        ),
                        (
                            <CustomButton
                                key={'all'}
                                style={{
                                    ...rightGraphSwitchStyle,
                                    backgroundColor: this.state.viewingAll ? STYLE_CONSTANTS.darkestPurple : STYLE_CONSTANTS.lightPurple
                                }}
                                onClick={() => {
                                    this.setState({ viewingAll: true }, () => {
                                        this.refreshResponses();
                                        });
                                    }
                                }
                            >
                                {COPY_CONSTANTS.all}
                            </CustomButton>
                        )
                        ]}
                    >
                        <div style={totalResponsesStyle}>
                            <p style={totalResponsesTextStyle}>{COPY_CONSTANTS.totalResponses} {this.state.stats.length - this.state.invalidResponsesCount}</p>
                        </div>
                        <div style={panelBodyStyle}>
                            <StatsGraph
                                graphTitle={COPY_CONSTANTS.yesFrequencyTitle}
                                statsData={this.state.yesFrequency}
                                statsTotal={this.state.stats.length - this.state.invalidResponsesCount}
                                xAxisLabel={COPY_CONSTANTS.questionsAnswered}
                                yAxisLabel={COPY_CONSTANTS.responses}
                                xTickValues={[0, 1, 2, 3, 4, 5, 6]}
                                company={'minnedemo'}
                                style={graphPanelStyle}
                                headingStyle={headingTextStyle}
                            />
                        </div>
                        <div style={panelBodyStyle}>
                            <StatsGraph
                                graphTitle={COPY_CONSTANTS.questionFrequencyTitle}
                                statsData={this.state.questionFrequency}
                                xAxisLabel={COPY_CONSTANTS.question}
                                yAxisLabel={COPY_CONSTANTS.numberOfYesAnswers}
                                xTickValues={[1, 2, 3, 4, 5, 6]}
                                company={'minnedemo'}
                                style={graphPanelStyle}
                                headingStyle={headingTextStyle}
                            />
                        </div>
                    </Panel>

                </div>
            </div>
        );

    }
}

const bigRowStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
};

const containerStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    padding: '50px',
    backgroundColor: STYLE_CONSTANTS.lightGray
};

const panelBodyStyle: CSSProperties = {
    ...bigRowStyle,
    backgroundColor: STYLE_CONSTANTS.lightestGray
};

const headingTextStyle: CSSProperties = {
    fontSize: STYLE_CONSTANTS.fontSizeMedium,
    color: STYLE_CONSTANTS.darkerGray,
    textAlign: 'center'
};

const graphPanelStyle: CSSProperties = {
    width: '100%',
    paddingLeft: '30px',
    paddingTop: '10px'
};

const graphSwitchStyle: CSSProperties = {
    width: '110px',
    height: '30px'
};

const leftGraphSwitchStyle: CSSProperties = {
    ...graphSwitchStyle,
    borderTopLeftRadius: '5px',
    borderBottomLeftRadius: '5px',
    borderBottomRightRadius: '0px',
    borderTopRightRadius: '0px'
};

const rightGraphSwitchStyle: CSSProperties = {
    ...graphSwitchStyle,
    borderBottomRightRadius: '5px',
    borderTopRightRadius: '5px',
    borderTopLeftRadius: '0px',
    borderBottomLeftRadius: '0px',
};

const totalResponsesStyle: CSSProperties = {
    paddingLeft: '30px'
};

const totalResponsesTextStyle: CSSProperties = {
    fontSize: STYLE_CONSTANTS.fontSizeTitle,
    color: STYLE_CONSTANTS.darkerGray,
    textAlign: 'center',
    marginTop: '20px',
    marginBottom: 0
};