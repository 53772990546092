import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { RouteProps } from 'react-router';
import { UnauthenticatedPageContainer } from '../../assets/CommonStyles';
import { STYLE_CONSTANTS } from '../../assets/StyleConstants';
import { ApplicationStore } from '../../data/stores/ApplicationStore';
import { ROUTES } from '../../Routes';

interface Props extends RouteProps {
    store: ApplicationStore;
}

@inject('store')
@observer
export class PoliciesPage extends React.Component<Props> {
    private get policy(): string {
        const { policyStore } = this.props.store;
        switch (this.props.location!.pathname) {
            case ROUTES.termsAndConditions:
                return policyStore.termsAndConditions;
            case ROUTES.privacyPolicy:
                return policyStore.privacyPolicy;
            default:
                return policyStore.privacyNotice;
        }
    }

    public render(): JSX.Element {
        return (
            <UnauthenticatedPageContainer>
                <div style={policyContainerStyle} dangerouslySetInnerHTML={{ __html: this.policy }} />
            </UnauthenticatedPageContainer>
        );
    }
}
const policyContainerStyle: React.CSSProperties = {
    backgroundColor: STYLE_CONSTANTS.white,
    color: STYLE_CONSTANTS.darkGray,
    fontSize: STYLE_CONSTANTS.fontSizeMedium,
    padding: '30px',
    border: 0,
    borderRadius: '10px',
    outline: 'none',
    marginTop: '5vh',
    height: '80vh',
    width: '80vw',
    overflowY: 'scroll',
    boxShadow: '0 15px 35px 0 rgba(50,50,93,0.1)',
    resize: 'none'
};

