export const STYLE_CONSTANTS = {

    snowWhite: '#FEFEFE',
    purpleGray: '#9f99b1',
    purpleBlack: '#4d416c',
    lightPurple: 'rgb(131, 128, 212)',

    brightGreen: '#4bdfa2',
    midGradientGreen: '#74d3af',
    darkGreen: '#4ab39b',

    darkestGray: '#303030',
    darkerGray: '#484848',
    darkGray: '#555555',
    mediumGray: 'rgba(77, 65, 108, 0.5)',
    mediumLightGray: '#e0e0e0',
    lightGray: '#f0f0f0',
    lighterGray: '#f8f8f8',
    lightestGray: '#fdfdfd',

    white: 'white',

    semiOpaqueWhite30: 'rgba(255, 255, 255, 0.3)',

    black: 'black',

    invalidRed: 'red',

    transparent: 'rgba(255, 255, 255, 0)',

    darkPurple: '#6e65d3',
    darkerPurple: '#6960c8',
    darkestPurple: '#5B53B0',

    fontSizeTitle: '32px',
    fontSizeMedium: '20px',
    fontSizeFormField: '22px',
    fontSizePrimaryButton: '16px',
    fontSizeSmall: '14px',
    fontSizeSmaller: '12px',
    fontSizeBarChartLabel: '8px',
};

export const STYLE_CLASSES = {
    BUTTON_GREEN: 'green',
    BUTTON_LIGHT_GREEN: 'lightGreen',
    BUTTON_PROFILE: 'profile',
    BUTTON_PANEL: 'panel',
    BUTTON_MAGENTA: 'magenta',
    BUTTON_CLOSE: 'close',
    BUTTON_MEDIUM_LIGHT_GRAY: 'mediumLightGray',
    BUTTON_WHITE: 'white',
    DIV_FADE_SHOWN: 'fadeShown',
    DIV_FADE_HIDDEN: 'fadeHidden',
    DIV_BLUR: 'blur',
    DIV_BLUR_NAV_BAR: 'blurNavBar',
    DIV_LIGHT_BOLD: 'lightBold',
    DIV_DARKEN: 'darken',
    DIV_NO_DARKEN: 'noDarken',
    FONT_WEIGHT_LIGHTER: 'fontWeightLighter'
};
