import * as firebase from 'firebase';
import { computed, observable } from 'mobx';
import { FIREBASE_CONSTANTS } from '../../assets/FirebaseConstants';
import { FirebaseCopy } from '../models/firebase/FirebaseCopy';

export class PolicyStore {
    @observable
    private _privacyPolicy: string = '';
    @computed
    public get privacyPolicy(): string {
        return this._privacyPolicy;
    }
    @observable
    private _privacyNotice: string = '';
    @computed
    public get privacyNotice(): string {
        return this._privacyNotice;
    }
    @observable
    private _termsAndConditions: string = '';
    @computed
    public get termsAndConditions(): string {
        return this._termsAndConditions;
    }

    @observable
    private _notesTemplates: string[] = [];
    @computed
    public get notesTemplates(): string[] {
        return this._notesTemplates;
    }

    @observable
    private _notesTemplateTitles: string[] = [];
    @computed
    public get notesTemplateTitles(): string[] {
        return this._notesTemplateTitles;
    }

    public constructor() {
        this.setup();
    }

    private async setup(): Promise<void> {
        const copySnapshot: firebase.database.DataSnapshot = await firebase.database().ref(FIREBASE_CONSTANTS.environment).child('copy').once(FIREBASE_CONSTANTS.value);
        const copy: FirebaseCopy | null = copySnapshot.val();
        if (null == copy) {
            return;
        }
        this._privacyPolicy = copy.privacyPolicy || '';
        this._privacyNotice = copy.privacyNotice || '';
        this._termsAndConditions = copy.termsAndConditions || '';
        for (const key in copy) {
            if (!(['privacyPolicy', 'privacyNotice', 'termsAndConditions'].includes(key))) {
                if (undefined != key) {
                    this._notesTemplateTitles.push(key);
                    this._notesTemplates.push(copy[key]);               
                }
            }
        }
    }
}

