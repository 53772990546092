import * as moment from 'moment';

export interface ItemKeyAndDisplayValue {
    key: string;
    display: string;
}

export const DATE_DISPLAY_FORMAT = 'M/D/YYYY';
const ISO_DATE_FORMAT = 'YYYY-MM-DD';

export function momentToDateString(m: moment.Moment): string {
    return m.format(DATE_DISPLAY_FORMAT);
}

export function timestampToDateString(timestamp: number): string {

    return momentToDateString(moment(timestamp));
}

export function durationMinutesToDisplay(durationMinutes: number): string {

    const hours = Math.floor(durationMinutes / 60);
    let minutesString = (durationMinutes % 60).toString();
    if (minutesString.length < 2) {
        minutesString = '0' + minutesString;
    }

    return `${hours}:${minutesString} hrs`;
}

export function buildCsvRow(cells: string[]): string {

    const cellsInQuotes = cells.map((cell: string) => `"${encodeURIComponent(cell)}"`);

    return cellsInQuotes.join('%2C');
}

export function buildCsvFile(rows: string[]): string {

    return rows.join('%0A') + '%0A';
}

export function momentToISODate(m: moment.Moment): string {
    return m.format(ISO_DATE_FORMAT);
}
