import * as React from 'react';
import { VictoryBar, VictoryChart, VictoryAxis } from 'victory';
import { STYLE_CONSTANTS } from '../../assets/StyleConstants';

interface Props {
    company: string;
    style?: React.CSSProperties;
    headingStyle?: React.CSSProperties;
    statsData: {
        x: number;
        y: number;
    }[];
    statsTotal?: number;
    xAxisLabel: string;
    yAxisLabel: string;
    xTickValues: number[];
    graphTitle: string;
}

interface State { }

export class StatsGraph extends React.Component<Props, State> {
    public render(): JSX.Element {
        return (
            <div style={this.props.style}>
                <p style={this.props.headingStyle}>{this.props.graphTitle}</p>
                <div style={{ display: 'flex', flex: 1, }}>
                    <VictoryChart>
                        <VictoryAxis
                            tickValues={this.props.xTickValues}
                            label={this.props.xAxisLabel}
                            style={{
                                axis: {stroke: '#5C5C5C'},
                                axisLabel: {fontSize: 8, padding: 30, fontWeight: 'lighter'},
                                tickLabels: {fontSize: 8, padding: 5, fontWeight: 'lighter'}
                            }}
                        />
                        <VictoryAxis
                            dependentAxis
                            tickFormat={(t) => `${Math.round(t)}`}
                            label={this.props.yAxisLabel}
                            style={{
                                axis: {stroke: '#5C5C5C'},
                                axisLabel: {fontSize: 8, padding: 35, fontWeight: 'lighter'},
                                tickLabels: {fontSize: 8, padding: 15, fontWeight: 'lighter'}
                            }}
                        />
                        <VictoryBar
                            // animate={{ duration: 500 }} // this is sorta broken
                            data={this.props.statsData}
                            padding={{ top: 30, bottom: 50, left: 100, right: 100 }}
                            height={220}
                            width={400}
                            style={{ labels: graphLabelStyle, data: dataStyle }}
                        />
                    </VictoryChart>
                </div>
            </div>
        );
    }
}

const graphLabelStyle: React.CSSProperties = {
    fontSize: STYLE_CONSTANTS.fontSizeBarChartLabel,
    color: '#5C5C5C',
    fontFamily: 'Avenir, Nunito, sans-serif',
    fontWeight: 'lighter'
};

const dataStyle: React.CSSProperties = {
    fill: '#9C71FF'
};