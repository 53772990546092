export const LOGIN_BACKGROUND = require('./bg@2x.png');
export const LEFT_NAV_BACKGROUND = require('./bg_left@2x.png');
export const POPOVER_TRIANGLE = require('./popover_triangle.png');
export const LOGO = require('./logo@2x.svg');
export const EMAIL_ICON = require('./email@2x.svg');
export const PASSWORD_ICON = require('./padlock@2x.svg');
export const DASHBOARD_ON_ICON = require('./dashboard_on@2x.svg');
export const DASHBOARD_OFF_ICON = require('./dashboard_off@2x.svg');
export const STATS_ON_ICON = require('./chart-bar-on.svg');
export const STATS_OFF_ICON = require('./chart-bar-off.svg');
export const CLOSE_ICON = require('./close@2x.svg');
export const SEARCH_ICON = require('../../src/images/search@2x.svg');
export const CLIENTS_ON_ICON = require('./clients_on@2x.svg');
export const CLIENTS_OFF_ICON = require('./clients_off@2x.svg');
export const FLAG_ICON = require('../../src/images/flag@2x.svg');
export const TIME_LOG_ON_ICON = require('./time_log_on@2x.svg');
export const TIME_LOG_OFF_ICON = require('./time_log_off@2x.svg');
export const VIEW_ALL_ICON = require('../../src/images/view_all@2x.svg');
export const LEFT_ARROW_ICON = require('../../src/images/left@2x.svg');
export const RIGHT_ARROW_ICON = require('../../src/images/right@2x.svg');
export const RIGHT_ARROW_GREY_ICON = require('../../src/images/Icon_RightArrow.svg');
export const CHECKMARK_ICON = require('../../src/images/Icon_CheckMark.svg');
export const X_ICON = require('../../src/images/Icon_X.svg');
export const LIGHT_CLOSE_ICON = require('../../src/images/close_icon.svg');
