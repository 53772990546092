import { assertNotNull } from '../../util/assert';
import { computed, observable, action, ObservableMap } from 'mobx';
import { Org } from '../models/Org';
import { IFirebaseOrg } from '../models/firebase/FirebaseOrg';
import { OrgService } from '../services/OrgService';
import { ItemKeyAndDisplayValue } from '../../util/common';

const CLASS_NAME = 'OrgStore';

export class OrgStore {

    private readonly assertNotNull = assertNotNull(CLASS_NAME);

    @observable
    private _orgsByID: ObservableMap<string, Org> = new ObservableMap<string, Org>();

    @computed
    public get orgsByID(): Map<string, Org> {

        return this._orgsByID;
    }

    @computed
    get orgNames(): Set<string> {

        return new Set<string>(
            Array.from(this._orgsByID.values())
                .map((org: Org) => org.name));
    }

    @computed
    public get activeOrgs(): Org[] {
        return Array.from(this._orgsByID.values());
    }

    public async getOrgIDsAndDisplayValues(): Promise<ItemKeyAndDisplayValue[]> {

        const firebaseOrgs = await OrgService.getAll();

        if (undefined == firebaseOrgs) {
            return [];
        }

        return Object.keys(firebaseOrgs)
            .map((orgFirebaseID: string) => {

                const firebaseOrg = firebaseOrgs[orgFirebaseID];

                return {
                    key: orgFirebaseID,
                    display: firebaseOrg.name
                };
            });
    }

    public async getOrg(orgFirebaseID: string): Promise<Org> {

        const firebaseOrg = await OrgService.getOrg(orgFirebaseID);

        return Org.createFromFirebase(orgFirebaseID, firebaseOrg);
    }

    public listen(): void {

        OrgService.listen(

            (firebaseID: string, firebaseOrg: IFirebaseOrg) =>
                this.onOrgAddedOrUpdated(firebaseID, firebaseOrg),

            (firebaseID: string) =>
                this.onOrgRemoved(firebaseID),
        );
    }

    public unlisten(): void {

        OrgService.unlisten();
    }

    @action
    public onOrgAddedOrUpdated(firebaseID: string, firebaseOrg: IFirebaseOrg): void {

        this.assertNotNull('onOrgAddedOrUpdated')(firebaseID, 'firebaseID');
        this.assertNotNull('firebaseOrg')(firebaseID, 'firebaseOrg');

        const org = Org.createFromFirebase(firebaseID, firebaseOrg);
        this._orgsByID.set(firebaseID, org);
    }

    @action
    public onOrgRemoved(firebaseID: string): void {

        this.assertNotNull('onOrgRemoved')(firebaseID, 'firebaseID');

        if (this._orgsByID.has(firebaseID)) {
            this._orgsByID.delete(firebaseID);
        }
    }

    public async addOrg(org: Org): Promise<void> {

        this.assertNotNull('addOrg')(org, 'org');

        return OrgService.addOrg(org.toFirebaseOrg());
    }

    public async updateOrg(org: Org): Promise<void> {

        this.assertNotNull('updateOrg')(org, 'org');
        this.assertNotNull('updateOrg')(org.firebaseOrgID, 'org');

        return OrgService.updateOrg(org.firebaseOrgID, org.toFirebaseOrg());
    }

}
