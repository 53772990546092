import * as assert from 'assert';
import { PortalUser } from './PortalUser';
import { IFirebaseUser } from './firebase/FirebaseUser';
import { UserType } from '../models/UserType';


export class Peer extends PortalUser {

    private _isOnboarded: boolean;
    private _facilityFirebaseID: string;

    private constructor() {
        super();
    }

    public get facilityFirebaseID(): string {
        return this._facilityFirebaseID;
    }

    public get isOnboarded(): boolean {
        return this._isOnboarded;
    }

    public set isOnboarded(value: boolean) {
        this._isOnboarded = value;
    }

    public static createFromFirebase(firebaseUid: string, firebaseUser: IFirebaseUser): Peer {

        const peer = new Peer();
        super.createFromFirebase(firebaseUid, firebaseUser, peer);

        assert(typeof firebaseUser.isOnboarded === 'boolean', 'Peer createFromFirebase passed args with invalid isOnboarded');
        peer._isOnboarded = firebaseUser.isOnboarded;

        assert(undefined !== firebaseUser.peerUser, 'Peer createFromFirebase passed args with undefined peerUser object');
        const peerUser = firebaseUser.peerUser;

        assert(typeof peerUser.facilityFirebaseID === 'string', 'Peer createFromFirebase passed args with invalid facilityFirebaseID');
        peer._facilityFirebaseID = peerUser.facilityFirebaseID;

        return peer;
    }

    public static create(
        firstName: string, lastName: string, emailAddress: string, facilityFirebaseID: string,
        existingPeer: Peer | undefined, newPeerUserFirebaseID: string | undefined)
        : Peer {
        
        assert(typeof facilityFirebaseID === 'string', 'Peer create passed args with invalid facilityFirebaseID');
        assert(undefined != existingPeer || undefined != newPeerUserFirebaseID, 'Peer createNew passed neither existingPeer nor newPeerUserFirebaseID');
        assert(undefined == existingPeer || undefined == newPeerUserFirebaseID, 'Peer createNew passed both existingPeer and newPeerUserFirebaseID');

        const isNew = (undefined === existingPeer);        
        const peer = existingPeer || new Peer();
        
        super.createPortalUser(firstName, lastName, emailAddress, peer, newPeerUserFirebaseID);

        peer._facilityFirebaseID = facilityFirebaseID;

        if (isNew) {
            peer._isOnboarded = false;
        }

        return peer;
    }

    public toFirebaseUser(): IFirebaseUser {

        let firebaseUser = super.getFirebaseUser();

        firebaseUser.userType = UserType.Peer;
        firebaseUser.isOnboarded = this._isOnboarded;
        firebaseUser.peerUser = {
                facilityFirebaseID: this._facilityFirebaseID
        };

        return firebaseUser;
    }

}
