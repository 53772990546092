export enum ROUTES {
    login = '/login',
    dashboard = '/dashboard',
    stats = '/stats',
    facilities = '/facilities',
    facility = '/facility',
    org = '/org',
    peers = '/peers',
    peer = '/peer',
    clients = '/clients',
    client = '/client',
    timeLog = '/time-log',
    termsAndConditions = '/terms-and-conditions',
    privacyPolicy = '/privacy-policy',
    privacyNotice = '/privacy-notice'
}

export enum NavSection {
    Dashboard,
    Stats,
    Clients,
    TimeLog,
    Facilities,
    Peers
}
