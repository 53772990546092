import * as React from 'react';
import { CSSProperties } from 'react';
import { inject, observer } from 'mobx-react';
import { ApplicationStore } from '../../data/stores/ApplicationStore';
import { STYLE_CONSTANTS } from '../../assets/StyleConstants';
import { COPY_CONSTANTS } from '../../assets/CopyConstants';
import { FormTextField } from '../components/FormTextField';
import { isEmailValid } from '../../util/validate';
import { Modal } from './Modal';

interface Props {
    store?: ApplicationStore;
    isVisible: boolean;
    onResetSent: () => void;
    onCancel: () => void;
}

interface State {
    email: string;
    userStateErrorMessage?: string;
    forceDisplayValidationErrors: boolean;
}

@inject('store')
@observer
export class PasswordResetModal extends React.Component<Props, State> {

    public state: State = {
        email: '',
        forceDisplayValidationErrors: false
    };

    private getEmailValidationError(): string {

        if (null == this.props.store) {
            throw new Error('PasswordResetModal: no store');
        }

        if (false === isEmailValid(this.state.email)) {
            return COPY_CONSTANTS.emailInvalid;
        }
        
        return '';
    }

    private async validateAndSend(): Promise<void> {

        if (this.getEmailValidationError().length > 0) {
            this.setState({ forceDisplayValidationErrors: true });
            return;
        }

        if (undefined === this.props.store) {
            throw new Error('PasswordResetModal: no store');
        }

        const userInfo: { isOnboarded?: boolean; isClient?: boolean } =
            await this.props.store!.userStore.getUserOnboardedStatus(this.state.email);
        if (userInfo) {
            if (userInfo.isClient) {
                this.setState({ userStateErrorMessage: COPY_CONSTANTS.signupFailedClient });
                return;
            } else if (false === userInfo.isOnboarded) {
                this.setState({ userStateErrorMessage: COPY_CONSTANTS.passwordResetFailedSignUpFirst });
                return;
            }
        }

        try {
            await this.props.store.authorizationStore.sendPasswordResetEmail(this.state.email);
        } catch {
            // don't reveal when the account doesn't actually exist
        }

        this.props.onResetSent();
    }

    public render(): JSX.Element | null {

        return (
            <Modal
                isVisible={this.props.isVisible}
                title={COPY_CONSTANTS.passwordReset}
                primaryButtonText={COPY_CONSTANTS.send}
                onPrimaryButtonClick={() => this.validateAndSend()}
                onCloseButtonClick={() => this.props.onCancel()}
            >

                <div>

                    <div style={messageStyle}>
                        {COPY_CONSTANTS.passwordResetInstructions}
                    </div>

                    <FormTextField
                        style={emailFieldStyle}
                        label={COPY_CONSTANTS.email}
                        value={this.state.email}
                        onValueChanged={(newValue: string) =>
                            this.setState({ email: newValue, userStateErrorMessage: undefined })
                        }
                        validationError={this.state.userStateErrorMessage || this.getEmailValidationError()}
                        forceDisplayValidationError={this.state.forceDisplayValidationErrors}
                    />
                
                </div>

            </Modal>
        );
    }
}

const messageStyle: CSSProperties = {
    fontSize: STYLE_CONSTANTS.fontSizeMedium,
    color: STYLE_CONSTANTS.mediumGray
};

const emailFieldStyle: CSSProperties = {
    width: '50%',
    marginTop: '20px'
};
