import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { ChangeEvent, CSSProperties } from 'react';
import { COPY_CONSTANTS } from '../../assets/CopyConstants';
import { STYLE_CLASSES, STYLE_CONSTANTS } from '../../assets/StyleConstants';

import { PeerVisit } from '../../data/models/PeerVisit';
import { ApplicationStore } from '../../data/stores/ApplicationStore';
import { ConfirmationModal } from './ConfirmationModal';
import { Modal } from './Modal';
import CKEditor from '@ckeditor/ckeditor5-react';
import InlineEditor from '@ckeditor/ckeditor5-build-inline';
import { clientNotesCreatorStyle } from '../../assets/CommonStyles';

interface Props {
    store?: ApplicationStore;
    peerVisitToEdit?: PeerVisit;
    onComplete: () => void;
}

interface State {
    notes?: string;
    isEditing: boolean;
    originalNotes?: string;
    isConfirmingDiscardChanges: boolean;
}

@inject('store')
@observer
export class PeerVisitViewEditNotesModal extends React.Component<Props, State> {

    private static CLASS_NAME = 'PeerVisitViewEditNotesModal';

    public state: State = {
        isEditing: false,
        isConfirmingDiscardChanges: false
    };

    public static getDerivedStateFromProps(nextProps: Props, prevState: State): State | null {

        if (undefined !== nextProps.peerVisitToEdit && (null == prevState || undefined === prevState.notes)) {

            return {
                notes: nextProps.peerVisitToEdit.notes,
                originalNotes: nextProps.peerVisitToEdit.notes,
                isEditing: null != prevState ? prevState.isEditing : false,
                isConfirmingDiscardChanges: null != prevState ? prevState.isConfirmingDiscardChanges : false
            };
        }

        return null;
    }

    private async save(): Promise<void> {

        const peerVisit = this.props.peerVisitToEdit;

        if (null == peerVisit) {
            return;
        }

        peerVisit.notes = this.state.notes || '';

        if (null == this.props.store) {
            throw new Error(`${PeerVisitViewEditNotesModal.CLASS_NAME}: no store`);
        }

        await this.props.store.peerVisitStore.updatePeerVisit(peerVisit, peerVisit.date.dateString);

        this.close();
    }

    private tryClose(): void {

        if (this.state.notes !== this.state.originalNotes) {
            this.setState({ isConfirmingDiscardChanges: true });
        } else {
            this.close();
        }
    }

    private close(): void {

        this.props.onComplete();
        setTimeout(
            () => this.setState({
                isEditing: false,
                notes: undefined,
                originalNotes: undefined
            }),
            500);
    }

    public render(): JSX.Element | null {

        return (
            <div>

                <div className={this.state.isConfirmingDiscardChanges ? STYLE_CLASSES.DIV_BLUR : undefined}>
                    <Modal
                        isVisible={undefined !== this.props.peerVisitToEdit}
                        title={COPY_CONSTANTS.peerVisit}
                        secondaryTitle={this.props.peerVisitToEdit ? `${COPY_CONSTANTS.notes} ${this.props.peerVisitToEdit.date.displayString}` : ''}
                        primaryButtonText={this.state.isEditing ? COPY_CONSTANTS.save : COPY_CONSTANTS.editNotes}
                        onPrimaryButtonClick={() => {
                            if (this.state.isEditing) {
                                this.save();
                            } else {
                                this.setState({ isEditing: true });
                            }
                        }}
                        onCloseButtonClick={() => this.tryClose()}
                    >
{/* 
                        <textarea

                            readOnly={false === this.state.isEditing}
                            rows={10}
                            value={this.state.notes}
                            style={this.state.isEditing ? notesEditStyle : notesViewStyle}
                            onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                                this.setState({ notes: e.target.value })
                            }
                            maxLength={240}
                        /> */}
                        <div style = {clientNotesCreatorStyle} >
                        <CKEditor
                            label = {COPY_CONSTANTS.notes}
                            onInit={ editor => {
                                editor.setData(this.props.peerVisitToEdit ? 
                                    this.props.peerVisitToEdit.notes : '')}
                            }
                            onChange={ ( event, editor ) => {
                                this.setState({ notes: editor.getData()});
                                this.setState({isEditing: true})} }
                            editor={ InlineEditor }
                            config={{
                                placeholder: 'Notes go here',
                                toolbar: ['bold', 'italic', 'essentials', 'bulletedList', 'blockQuote'],
                                }}
                        />
                        </div>  
                    </Modal>
                </div>

                <ConfirmationModal
                    isVisible={this.state.isConfirmingDiscardChanges}
                    title={COPY_CONSTANTS.discardChanges}
                    message={COPY_CONSTANTS.discardChangesConfirmationMessage}
                    confirmButtonText={COPY_CONSTANTS.discard}
                    cancelButtonText={COPY_CONSTANTS.keepEditing}
                    confirmButtonStyleClassName={STYLE_CLASSES.BUTTON_GREEN}
                    cancelButtonStyleClassName={STYLE_CLASSES.BUTTON_MEDIUM_LIGHT_GRAY}
                    onConfirm={() => {
                        this.setState({ isConfirmingDiscardChanges: false });
                        this.close();
                    }}
                    onCancel={() => {
                        this.setState({ isConfirmingDiscardChanges: false });
                    }}
                    isInsideAnotherModal={true}
                />

            </div>
        );
    }

}

const notesBaseStyle: CSSProperties = {
    backgroundColor: STYLE_CONSTANTS.white,
    color: STYLE_CONSTANTS.mediumGray,
    fontSize: STYLE_CONSTANTS.fontSizeMedium,
    padding: '30px',
    marginBottom: '40px',
    border: 0,
    outline: 'none'
};

const notesViewStyle: CSSProperties = {
    ...notesBaseStyle,
    boxShadow: '0 15px 35px 0 rgba(50,50,93,0.1)',
    resize: 'none'
};

const notesEditStyle: CSSProperties = {
    ...notesBaseStyle,
    border: `1px solid ${STYLE_CONSTANTS.darkPurple}`,
    color: STYLE_CONSTANTS.darkestGray
};
