import * as React from 'react';
import { gridCellWithExtraLeftMarginStyle } from '../../assets/CommonStyles';
import { Client } from '../../data/models/Client';

interface Props {
    client: Client;
}

export const StreakLengthText: React.SFC<Props> = ({ client }) => (
    <p key={client.firebaseUid} style={gridCellWithExtraLeftMarginStyle}>{getLatestStreakDisplay(client)}</p>
);

function getLatestStreakDisplay(client: Client): JSX.Element {
    const streakLength = client.latestStreakLength;
    if (streakLength === 0) {
        return <span>&ndash;</span>;
    }
    if (streakLength === 1) {
        return <span>1 day</span>;
    }
    return <span>{`${streakLength} days`}</span>;
}
