import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { rightColumnFieldStyle, rowStyle } from '../../assets/CommonStyles';
import { COPY_CONSTANTS } from '../../assets/CopyConstants';
import { Org } from '../../data/models/Org';
import { ApplicationStore } from '../../data/stores/ApplicationStore';
import { ItemKeyAndDisplayValue } from '../../util/common';
import { isEmailValid, isPhoneValid } from '../../util/validate';
import { FormTextField } from './FormTextField';
import { Modal } from './Modal';

interface Props {
    store?: ApplicationStore;
    isVisible: boolean;
    orgToEdit?: Org;
    onComplete: () => void;
}

interface State {
    name: string;
    address: string;
    phone: string;
    email: string;
    contactValidationError: string;
    isValid: boolean;
    forceDisplayValidationErrors: boolean;
    orgIDsAndNames?: ItemKeyAndDisplayValue[];
}

@inject('store')
@observer
export class OrgAddEditModal extends React.Component<Props, State> {

    private readonly CLASS_NAME = 'OrgAddEditModal';

    public state = this.getInitialState();

    public static getDerivedStateFromProps(newProps: Props): Partial<State> | null {
        if (null == newProps.orgToEdit) {
            return null;
        }

        return {
            name: newProps.orgToEdit.name,
            address: newProps.orgToEdit.address,
            phone: newProps.orgToEdit.contactPhone || '',
            email: newProps.orgToEdit.contactEmailAddress || '',
        };
    }

    private getInitialState(): State {
        return (
            this.props.orgToEdit
                ? {
                    name: this.props.orgToEdit.name,
                    address: this.props.orgToEdit.address,
                    phone: this.props.orgToEdit.contactPhone || '',
                    email: this.props.orgToEdit.contactEmailAddress || '',
                    contactValidationError: '',
                    isValid: true,
                    forceDisplayValidationErrors: false
                }
                : {
                    name: '',
                    address: '',
                    phone: '',
                    email: '',
                    contactValidationError: '',
                    isValid: false,
                    forceDisplayValidationErrors: false
                }
        );
    }

    private async save(): Promise<void> {

        if (false === this.validate()) {
            this.setState({ forceDisplayValidationErrors: true });
            return;
        }

        const org = Org.create(
            this.state.name,
            this.state.address,
            this.state.phone,
            this.state.email,
            this.props.orgToEdit
        );

        if (null == this.props.store) {
            throw new Error(`${this.CLASS_NAME}: no store`);
        }

        if (this.props.orgToEdit) {
            await this.props.store.orgStore.updateOrg(org);
        } else {
            await this.props.store.orgStore.addOrg(org);
        }

        this.close(org);
    }

    private close(savedOrg: Org | undefined): void {

        this.props.onComplete();

        if (undefined === savedOrg || undefined === this.props.orgToEdit) {
            setTimeout(() => this.setState(this.getInitialState()), 500);
        }
    }

    public componentDidUpdate(prevProps: Props): void {
        this.validate();

        if (this.props !== prevProps) {
            this.refreshFacilityIDsAndDisplayNames();
        }
    }

    private async refreshFacilityIDsAndDisplayNames(): Promise<void> {
        if (null == this.props.store) {
            throw new Error(`${this.CLASS_NAME}: no store`);
        }

        const orgIDsAndDisplayValues = await this.props.store.orgStore.getOrgIDsAndDisplayValues();

        this.setState({ orgIDsAndNames: orgIDsAndDisplayValues });
    }

    private validate(): boolean {

        const contactValidationError = this.getContactValidationError();

        const isValid =
            this.getNameValidationError().length === 0
            && this.getRequiredFieldValidationError(this.state.address).length === 0
            && contactValidationError.length === 0
            && this.getPhoneValidationError().length === 0
            && this.getEmailValidationError().length === 0;

        if (contactValidationError !== this.state.contactValidationError || isValid !== this.state.isValid) {
            this.setState({
                contactValidationError: contactValidationError,
                isValid: isValid
            });
        }

        return isValid;
    }

    private getNameValidationError(): string {

        if (null == this.props.store) {
            throw new Error(`${this.CLASS_NAME}: no store`);
        }

        if (undefined === this.props.orgToEdit || this.state.name !== this.props.orgToEdit.name) {

            if (undefined === this.state.orgIDsAndNames || this.state.orgIDsAndNames.some((orgIDAndDisplay: ItemKeyAndDisplayValue) => orgIDAndDisplay.display === this.state.name)) {
                return COPY_CONSTANTS.orgAlreadyExists;
            }
        }

        return this.getRequiredFieldValidationError(this.state.name);
    }

    private getPhoneValidationError(): string {

        return (
            ('' === this.state.phone || isPhoneValid(this.state.phone))
                ? ''
                : COPY_CONSTANTS.phoneInvalid
        );
    }

    private getEmailValidationError(): string {

        return (
            ('' === this.state.email || isEmailValid(this.state.email))
                ? ''
                : COPY_CONSTANTS.emailInvalid
        );
    }

    private getContactValidationError(): string {

        return (
            (this.state.phone.length === 0 && this.state.email.length === 0)
                ? COPY_CONSTANTS.phoneOrEmailRequired
                : ''
        );
    }

    private getRequiredFieldValidationError(value: string): string {
        if (value.length === 0) {
            return COPY_CONSTANTS.requiredField;
        }
        return '';
    }

    public render(): JSX.Element {

        const contactValidationError = this.getContactValidationError();

        return (
            <Modal
                isVisible={this.props.isVisible}
                title={this.props.orgToEdit
                    ? COPY_CONSTANTS.editOrg
                    : COPY_CONSTANTS.addNewOrg}
                primaryButtonText={this.props.orgToEdit
                    ? COPY_CONSTANTS.save
                    : COPY_CONSTANTS.addOrg}
                onPrimaryButtonClick={() => this.save()}
                onCloseButtonClick={() => this.close(undefined)}
            >

                <div style={rowStyle}>

                    <FormTextField
                        label={COPY_CONSTANTS.orgName}
                        value={this.state.name}
                        initialValue={this.props.orgToEdit ? this.props.orgToEdit.name : ''}
                        onValueChanged={(newValue: string) =>
                            this.setState({ name: newValue })
                        }
                        validationError={this.getNameValidationError()}
                        forceDisplayValidationError={this.state.forceDisplayValidationErrors}
                    />
                </div>

                <div style={rowStyle}>

                    <FormTextField
                        label={COPY_CONSTANTS.telephone}
                        value={this.state.phone}
                        initialValue={this.props.orgToEdit ? this.props.orgToEdit.contactPhone : ''}
                        onValueChanged={(newValue: string) =>
                            this.setState({ phone: newValue })
                        }
                        validationError={
                            contactValidationError.length > 0
                                ? contactValidationError
                                : this.getPhoneValidationError()
                        }
                        forceDisplayValidationError={this.state.forceDisplayValidationErrors}
                    />

                    <FormTextField
                        style={rightColumnFieldStyle}
                        label={COPY_CONSTANTS.email}
                        value={this.state.email}
                        initialValue={this.props.orgToEdit ? this.props.orgToEdit.contactEmailAddress : ''}
                        onValueChanged={(newValue: string) =>
                            this.setState({ email: newValue })
                        }
                        validationError={
                            contactValidationError.length > 0
                                ? contactValidationError
                                : this.getEmailValidationError()
                        }
                        forceDisplayValidationError={this.state.forceDisplayValidationErrors}
                    />

                </div>

                <div style={rowStyle}>

                    <FormTextField
                        label={COPY_CONSTANTS.address}
                        isMultiLine={true}
                        value={this.state.address}
                        initialValue={this.props.orgToEdit ? this.props.orgToEdit.address : ''}
                        onValueChanged={(newValue: string) =>
                            this.setState({ address: newValue })
                        }
                        validationError={this.getRequiredFieldValidationError(this.state.address)}
                        forceDisplayValidationError={this.state.forceDisplayValidationErrors}
                    />

                </div>

            </Modal>
        );
    }

}
