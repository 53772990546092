import * as React from 'react';
import { cellTextStyle, modalStyle } from '../../assets/CommonStyles';
import { CLIENT_REFLECTION_CONSTANTS } from '../../assets/CopyConstants';
import { STYLE_CLASSES } from '../../assets/StyleConstants';
import { ClientReflection } from '../../data/models/ClientReflection';
import { LIGHT_CLOSE_ICON } from '../../images/images';
import { CustomButton } from './Button';
import { Fadeable } from './Fadeable';
import { ListPanel } from './ListPanel';

interface Props {
    isVisible: boolean;
    reflection?: ClientReflection;
    previousReflection?: ClientReflection;
    onClose(): void;
}

export class ClientReflectionModal extends React.Component<Props> {
    private getRowItems(reflection: ClientReflection): JSX.Element[][] {
        const { questions, answers } = CLIENT_REFLECTION_CONSTANTS;
        const { yesNo, generalRating, foodRating, exerciseRating } = answers;

        const questionAnswerPairs = [
            [CLIENT_REFLECTION_CONSTANTS.questions.overall, generalRating(reflection.overallFeelingRating)],
            [questions.whatEmotions, Array.from(reflection.emotions).reduce((all, [emotion, subemotions]) => [...all, emotion, ...subemotions], []).join(', ')],
            [questions.drugUse, yesNo(reflection.usedNonMedicalDrugs)], ,
            this.getAnswerOrNA(questions.drugUseProblems, reflection.nonMedicalDrugsCausedProblems),
            this.getAnswerOrNA(questions.drugUseConcern, reflection.nonMedicalDrugsAreConcerned),
            this.getAnswerOrNA(questions.medication, reflection.prescribedMedication),
            [questions.sleep, generalRating(reflection.sleepRating)],
            [questions.dream, yesNo(reflection.hadTraumaDream)],
            this.getAnswerOrNA(questions.dreamDescription, reflection.dreamDescription),
            [questions.exercise, exerciseRating(reflection.exerciseRating)],
            [questions.food, foodRating(reflection.foodRating)],
            [questions.sponsor, yesNo(reflection.connectedWithTrustedPerson)],
            [questions.socialInteractions, yesNo(reflection.hadPositiveSocialInteractions)],
            [questions.supportGroups, reflection.attendedGroupsCount],
            [questions.stressors, (reflection.anxietySources || []).join(', ')],
            [questions.goal, reflection.todaysGoal],
            undefined != this.props.previousReflection
                ? [questions.yesterdaysGoal(this.props.previousReflection.todaysGoal), yesNo(reflection.completedYesterdaysGoal)]
                : [questions.yesterdaysGoal(null), 'N/A'],
            [questions.thankfulFor, reflection.thankfulFor]
        ];
        return questionAnswerPairs
            .filter(pair => pair)
            .map(pair => pair!.map(item => <p key={item} style={cellTextStyle}>{item}</p>));
    }

    private getAnswerOrNA(question: string, answer: string | boolean | undefined): [string, string] {
        if (undefined == answer) {
            return [question, 'N/A'];
        }
        if ('string' === typeof answer) {
            return [question, answer];
        }
        return [question, CLIENT_REFLECTION_CONSTANTS.answers.yesNo(answer)];
    }

    public render(): JSX.Element {
        const { reflection, isVisible } = this.props;
        return (
            <Fadeable
                style={clientReflectionModalStyle}
                shouldDisplay={isVisible}
            >

                <CustomButton
                    style={closeButtonStyle}
                    styleClassName={STYLE_CLASSES.BUTTON_CLOSE}
                    onClick={this.props.onClose}
                />

                <div style={contentStyle}>
                    {null != reflection &&
                        <ListPanel
                            title={(
                                <span style={listTitlePrimaryStyle}>
                                    {CLIENT_REFLECTION_CONSTANTS.title}
                                    <span style={listTItleSecondaryStyle}>
                                        {` ${reflection.dateDisplay}`}
                                    </span>
                                </span>
                            )}
                            columnWidths={['50%', '50%']}
                            columnNames={[CLIENT_REFLECTION_CONSTANTS.questionHeading, CLIENT_REFLECTION_CONSTANTS.answerHeading]}
                            rowItems={this.getRowItems(reflection)}
                            style={{ marginTop: 0 }}
                            maxHeight={'80%'}
                        />}
                </div>
            </Fadeable>
        );
    }
}

const clientReflectionModalStyle: React.CSSProperties = {
    ...modalStyle,
    display: 'flex',
    justifyContent: 'center',
    bottom: 100
};

const contentStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
};

const closeButtonStyle: React.CSSProperties = {
    position: 'absolute',
    right: '20px',
    top: '5px',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundImage: `url(${LIGHT_CLOSE_ICON})`,
    width: '40px',
    height: '40px',
    borderRadius: '30px'
};

const listTitlePrimaryStyle: React.CSSProperties = { fontWeight: 'bold' };

const listTItleSecondaryStyle: React.CSSProperties = { fontWeight: 100 };