import * as React from 'react';
import { gridCellWithExtraLeftMarginStyle } from '../../assets/CommonStyles';
import { COPY_CONSTANTS } from '../../assets/CopyConstants';
import { Client } from '../../data/models/Client';
import { Date } from '../../data/models/Date';

interface Props {
    client: Client;
}

export const LastCheckInText: React.SFC<Props> = ({ client }) => (
    <p key={client.firebaseUid} style={gridCellWithExtraLeftMarginStyle}>{getLastCheckInDisplay(client)}</p>
);

function getLastCheckInDisplay(client: Client): JSX.Element {
    const today = Date.createForToday();
    if (null == client.lastCheckInDate) {
        return <span>&ndash;</span>;
    }
    const daysFromToday = today.diff(client.lastCheckInDate);
    if (daysFromToday === 0) {
        return <span>{COPY_CONSTANTS.today}</span>;
    }
    if (daysFromToday <= 14) {
        return <span>{client.lastCheckInDate.moment.from(today.moment)}</span>;
    }
    return <span>{client.lastCheckInDate.displayString}</span>;
}
