import * as firebase from 'firebase';
import { assertNotNull, assertTypeof } from '../../util/assert';
import { IFirebaseFacility, IFirebaseFacilities } from '../models/firebase/FirebaseFacility';
import { FIREBASE_CONSTANTS } from '../../assets/FirebaseConstants';

const FACILITIES_PATH = `${FIREBASE_CONSTANTS.environment}/facilities`;
const ORG_ID_FIELD = `orgID`;

const CLASS_NAME = 'FacilityService';

export class FacilityService {

    private static readonly assertNotNull = assertNotNull(CLASS_NAME);
    private static readonly assertTypeof = assertTypeof(CLASS_NAME);

    private static get facilitiesRef(): firebase.database.Reference {
        return firebase.database().ref(FACILITIES_PATH);
    }

    private static getFacilityRef(firebaseID: string): firebase.database.Reference {
        return firebase.database().ref(`${FACILITIES_PATH}/${firebaseID}`);
    }

    public static async getFacility(facilityFirebaseID: string): Promise<IFirebaseFacility> {

        this.assertNotNull('getFacility')(facilityFirebaseID, 'facilityFirebaseID');
        this.assertTypeof('getFacility')('string')(facilityFirebaseID, 'facilityFirebaseID');

        const snapshot =
            await this.getFacilityRef(facilityFirebaseID)
                .once(FIREBASE_CONSTANTS.value);
        
        if (null == snapshot) {
            throw new Error('getFacility: null snapshot');
        }

        return snapshot.val();
    }

    public static async getAll(): Promise<IFirebaseFacilities> {

        const snapshot =
            await this.facilitiesRef
                .once(FIREBASE_CONSTANTS.value);

        if (null == snapshot) {
            throw new Error('FacilityService.getAll: null snapshot');
        }

        return snapshot.val();
    }

    public static listen(
        onFacilityAddedOrChanged: (firebaseID: string, firebaseFacility: IFirebaseFacility) => void,
        onFacilityRemoved: (firebaseID: string) => void
    ): void {

        this.assertNotNull('listen')(onFacilityAddedOrChanged, 'onFacilityAddedOrChanged');
        this.assertNotNull('listen')(onFacilityRemoved, 'onFacilityRemoved');

        this.facilitiesRef.off();

        this.facilitiesRef
            .on(
                FIREBASE_CONSTANTS.eventChildAdded,
                async snapshot =>
                    this.handleFacilityAddedOrChanged(snapshot, onFacilityAddedOrChanged)
            );

        this.facilitiesRef
            .on(
                FIREBASE_CONSTANTS.eventChildChanged,
                async snapshot =>
                    this.handleFacilityAddedOrChanged(snapshot, onFacilityAddedOrChanged)
            );

        this.facilitiesRef
            .on(
                FIREBASE_CONSTANTS.eventChildRemoved,
                async snapshot =>
                    this.handleFacilityRemoved(snapshot, onFacilityRemoved)
            );
    }

    public static unlisten(): void {

        this.facilitiesRef.off();
    }

    private static async handleFacilityAddedOrChanged(
        snapshot: firebase.database.DataSnapshot | null,
        onFacilityAddedOrChanged: (firebaseID: string, firebaseFacility: IFirebaseFacility) => void
    ): Promise<void> {

        this.assertNotNull('handleFacilityAddedOrChanged')(onFacilityAddedOrChanged, 'onFacilityAddedOrChanged');

        if (null == snapshot) {
            throw new Error('handleFacilityAddedOrChanged: null snapshot');
        }

        if (null == snapshot.key) {
            throw new Error('handleFacilityAddedOrChanged: null snapshot key');
        }

        const facility: IFirebaseFacility = snapshot.val();

        if (null == facility) {
            throw new Error('handleFacilityAddedOrChanged: null facility');
        }

        onFacilityAddedOrChanged(snapshot.key, facility);
    }

    private static async handleFacilityRemoved(
        snapshot: firebase.database.DataSnapshot | null,
        onFacilityRemoved: (firebaseID: string) => void
    ): Promise<void> {

        this.assertNotNull('handleFacilityRemoved')(onFacilityRemoved, 'onFacilityRemoved');

        if (null == snapshot) {
            throw new Error('handleFacilityRemoved: null snapshot');
        }

        if (null == snapshot.key) {
            throw new Error('handleFacilityRemoved: null firebaseID');
        }

        onFacilityRemoved(snapshot.key);
    }

    public static async addFacility(firebaseFacility: IFirebaseFacility): Promise<void> {

        this.assertNotNull('addFacility')(firebaseFacility, 'firebaseFacility');

        return this.facilitiesRef.push({
            ...firebaseFacility,
            createdTimestamp: firebase.database.ServerValue.TIMESTAMP
        });
    }

    public static async updateFacility(firebaseFacilityID: string, firebaseFacility: IFirebaseFacility): Promise<void> {

        assertTypeof('FacilityService')('updateFacility')('string')(firebaseFacilityID, 'firebaseFacilityID');
        this.assertNotNull('updateFacility')(firebaseFacility, 'firebaseFacility');

        return this.getFacilityRef(firebaseFacilityID).set({
            ...firebaseFacility
        });
    }

    public static async getFacilitiesForOrg(orgID: string): Promise<IFirebaseFacilities> {

        this.assertNotNull('getFacilitiesForOrg')(orgID, 'orgID');
        this.assertTypeof('getFacilitiesForOrg')('string')(orgID, 'orgID');

        const snapshot =
            await this.facilitiesRef
            .orderByChild(ORG_ID_FIELD)
                .equalTo(orgID)
                .once(FIREBASE_CONSTANTS.value);

        if (null == snapshot) {
            throw new Error('getFacilitiesForOrg: null snapshot');
        }
        return snapshot.val();
    }

}
