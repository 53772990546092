import * as assert from 'assert';
import { IFirebaseFacility } from './firebase/FirebaseFacility';

export class Facility {

    private _firebaseFacilityID: string;
    private _name: string;
    private _address: string;
    private _contactPhone: string;
    private _contactEmailAddress: string;
    private _createdTimestamp: number | null;
    private _orgID: string;
    private _isActive: boolean;

    private constructor() {}

    public get firebaseFacilityID(): string {
        return this._firebaseFacilityID;
    }

    public get name(): string {
        return this._name;
    }

    public get address(): string {
        return this._address;
    }

    public get contactPhone(): string {
        return this._contactPhone;
    }

    public get contactEmailAddress(): string {
        return this._contactEmailAddress;
    }

    public get orgID(): string {
        return this._orgID;
    }

    public static createFromFirebase(firebaseFacilityID: string, firebaseFacility: IFirebaseFacility): Facility {

        const facility = new Facility();

        assert(typeof firebaseFacilityID === 'string', 'Facility constructor passed args with invalid firebaseFacilityID');
        assert(typeof firebaseFacility.name === 'string', 'Facility constructor passed args with invalid name');
        assert(typeof firebaseFacility.address === 'string', 'Facility constructor passed args with invalid address');
        assert(typeof firebaseFacility.createdTimestamp === 'number', 'Facility constructor passed args with invalid createdTimestamp');
        //assert(typeof firebaseFacility.orgID === 'string', 'Facility constructor passed args with invalid org')
        facility._firebaseFacilityID = firebaseFacilityID;
        facility._name = firebaseFacility.name;
        facility._address = firebaseFacility.address;
        facility._createdTimestamp = firebaseFacility.createdTimestamp;
        facility._orgID = firebaseFacility.orgID || '';
        facility._isActive = firebaseFacility.isActive || true;

        assert(firebaseFacility.contact !== undefined, 'Facility constructor passed args with undefined contact object');
        assert(firebaseFacility.contact.phone == null || typeof firebaseFacility.contact.phone === 'string', 'Facility constructor passed args with invalid contact phone');
        assert(firebaseFacility.contact.emailAddress == null || typeof firebaseFacility.contact.emailAddress === 'string', 'Facility constructor passed args with invalid contact email address');
        facility._contactPhone = firebaseFacility.contact.phone;
        facility._contactEmailAddress = firebaseFacility.contact.emailAddress;

        return facility;
    }

    public static create(name: string, address: string, contactPhone: string, contactEmailAddress: string, existingFacility: Facility | undefined, orgID: string): Facility {
        
        const facility = existingFacility  || new Facility();
        facility._name = name;
        facility._address = address;
        facility._contactPhone = contactPhone;
        facility._contactEmailAddress = contactEmailAddress;
        facility._orgID = orgID;

        if (undefined === existingFacility) {
            facility._createdTimestamp = new Date().getTime();
        }

        return facility;
    }

    public toFirebaseFacility(): IFirebaseFacility {

        let createdTimestamp: number | null = null;
        if (this._firebaseFacilityID) {
            assert(null != this._createdTimestamp, 'Facility.toFirebaseFacility: null createdTimestamp for existing facility');
            createdTimestamp = this._createdTimestamp;
        }

        return {
            name: this._name,
            address: this._address,
            contact: {
                phone: this._contactPhone,
                emailAddress: this._contactEmailAddress
            },
            createdTimestamp: createdTimestamp,
            orgID: this.orgID
        };
    }

    public compareToForSort(facility: Facility): number {

        return this._name.localeCompare(facility._name);
    }
    
}
