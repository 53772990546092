import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { rightColumnFieldStyle, rowStyle } from '../../assets/CommonStyles';
import { COPY_CONSTANTS } from '../../assets/CopyConstants';
import { Peer } from '../../data/models/Peer';
import { ApplicationStore } from '../../data/stores/ApplicationStore';
import { ItemKeyAndDisplayValue } from '../../util/common';
import { isEmailValid } from '../../util/validate';
import { FormDropdownField } from './FormDropdownField';
import { FormTextField } from './FormTextField';
import { Modal } from './Modal';

interface Props {
    store?: ApplicationStore;
    isVisible: boolean;
    peerToEdit?: Peer;
    onComplete: () => void;
}

interface State {
    firstName: string;
    lastName: string;
    email: string;
    facilityFirebaseID: string | null;
    facilityIDsAndDisplayValues?: ItemKeyAndDisplayValue[];
    doesAnotherUserExistWithEmail: boolean;
    isValid: boolean;
    userCreationFailed: boolean;
    forceDisplayValidationErrors: boolean;
}

@inject('store')
@observer
export class PeerAddEditModal extends React.Component<Props, State> {

    private static readonly CLASS_NAME = 'PeerAddEditModal';

    public state: State = this.getInitialState();

    public static getDerivedStateFromProps(nextProps: Props): Partial<State> | null {
        if (null == nextProps.peerToEdit) {
            return null;
        }
        return {
            firstName: nextProps.peerToEdit.firstName,
            lastName: nextProps.peerToEdit.lastName,
            email: nextProps.peerToEdit.emailAddress,
            facilityFirebaseID: nextProps.peerToEdit.facilityFirebaseID,
        };
    }

    private getInitialState(): State {

        return (
            this.props.peerToEdit
                ? {
                    firstName: this.props.peerToEdit.firstName,
                    lastName: this.props.peerToEdit.lastName,
                    email: this.props.peerToEdit.emailAddress,
                    facilityFirebaseID: this.props.peerToEdit.facilityFirebaseID,
                    doesAnotherUserExistWithEmail: false,
                    isValid: true,
                    userCreationFailed: false,
                    forceDisplayValidationErrors: false
                }
                : {
                    firstName: '',
                    lastName: '',
                    email: '',
                    facilityFirebaseID: null,
                    doesAnotherUserExistWithEmail: false,
                    isValid: false,
                    userCreationFailed: false,
                    forceDisplayValidationErrors: false
                }
        );
    }

    private async save(): Promise<void> {

        if (false === this.validate() || null == this.state.facilityFirebaseID) {
            this.setState({ forceDisplayValidationErrors: true });
            return;
        }

        if (null == this.props.store) {
            throw new Error(`${PeerAddEditModal.CLASS_NAME}: no store`);
        }

        let newPeerUserFirebaseID: string | undefined = undefined;

        if (null == this.props.peerToEdit) {
            try {

                // check if auth user already exists, perhaps due to usage in another environment
                newPeerUserFirebaseID = await this.props.store.userStore.getAuthUserFirebaseUid(this.state.email);

                if (undefined == newPeerUserFirebaseID) {
                    // the normal case: need to create auth user
                    newPeerUserFirebaseID = await this.props.store.authorizationStore.createUserWithEmailAndPassword(this.state.email);
                    if (undefined != newPeerUserFirebaseID) {
                        await this.props.store.userStore.addAuthUser(this.state.email, newPeerUserFirebaseID);
                    }
                }
            } catch (error) {
                this.setState({
                    userCreationFailed: true,
                    forceDisplayValidationErrors: true
                });
                return;
            }
        }

        const peer = Peer.create(
            this.state.firstName,
            this.state.lastName,
            this.state.email,
            this.state.facilityFirebaseID,
            this.props.peerToEdit,
            newPeerUserFirebaseID
        );

        await this.props.store.userStore.upsertUser(peer);

        this.close(peer);
    }

    private close(savedPeer: Peer | undefined): void {

        this.props.onComplete();

        if (undefined === savedPeer || undefined === this.props.peerToEdit) {
            setTimeout(() => this.setState(this.getInitialState()), 500);
        }
    }

    public componentDidUpdate(prevProps: Props, prevState: State): void {

        this.refreshWhetherUserExistsWithEmail(prevState.email);

        if (prevProps !== this.props) {
            this.refreshFacilityIDsAndDisplayNames();
        }
    }

    private async refreshFacilityIDsAndDisplayNames(): Promise<void> {
        if (null == this.props.store) {
            throw new Error(`${PeerAddEditModal.CLASS_NAME}: no store`);
        }

        const facilityIDsAndDisplayValues = await this.props.store.facilityStore.getFacilityIDsAndDisplayValues();

        this.setState({ facilityIDsAndDisplayValues });
    }

    private async refreshWhetherUserExistsWithEmail(previousStateEmail: string): Promise<void> {
        if (this.state.email === previousStateEmail) {
            return;
        }

        let doesUserExistWithEmail = false;

        if (isEmailValid(this.state.email)) {

            if (null == this.props.store) {
                throw new Error(`${PeerAddEditModal.CLASS_NAME}.refreshWhetherUserExistsWithEmail: no store`);
            }

            doesUserExistWithEmail = await this.props.store.userStore.doesUserExistWithEmail(this.state.email);
        }

        if (doesUserExistWithEmail !== this.state.doesAnotherUserExistWithEmail) {
            this.setState({ doesAnotherUserExistWithEmail: doesUserExistWithEmail });
        }
    }

    private validate(): boolean {

        const isValid =
            this.getRequiredFieldValidationError(this.state.facilityFirebaseID || '').length === 0
            && this.getRequiredFieldValidationError(this.state.firstName).length === 0
            && this.getRequiredFieldValidationError(this.state.lastName).length === 0
            && this.getEmailValidationError().length === 0;

        return isValid;
    }

    private getEmailValidationError(): string {

        if (null == this.props.store) {
            throw new Error(`${PeerAddEditModal.CLASS_NAME}: no store`);
        }

        if (false === isEmailValid(this.state.email)) {
            return COPY_CONSTANTS.emailInvalid;
        }

        if (undefined === this.props.peerToEdit && this.state.doesAnotherUserExistWithEmail) {
            return COPY_CONSTANTS.userEmailAlreadyExists;
        }

        if (this.state.userCreationFailed) {
            return COPY_CONSTANTS.errorCreatingUser;
        }

        return '';
    }

    private getRequiredFieldValidationError(value: string): string {
        if (value.length === 0) {
            return COPY_CONSTANTS.requiredField;
        }
        return '';
    }

    public render(): JSX.Element | null {

        if (null == this.props.store) {
            throw new Error(`${PeerAddEditModal.CLASS_NAME}: no store`);
        }

        return (
            <Modal
                isVisible={this.props.isVisible && undefined !== this.state.facilityIDsAndDisplayValues}
                title={this.props.peerToEdit
                    ? COPY_CONSTANTS.editPeer
                    : COPY_CONSTANTS.addNewPeer}
                primaryButtonText={this.props.peerToEdit
                    ? COPY_CONSTANTS.save
                    : COPY_CONSTANTS.addPeer}
                onPrimaryButtonClick={() => this.save()}
                onCloseButtonClick={() => this.close(undefined)}
            >

                <div style={rowStyle}>

                    <FormDropdownField
                        label={COPY_CONSTANTS.facility}
                        items={this.state.facilityIDsAndDisplayValues || []}
                        selectedItemKey={this.state.facilityFirebaseID || ''}
                        initialSelectedItemKey={this.props.peerToEdit ? this.props.peerToEdit.facilityFirebaseID : ''}
                        onValueChanged={(newValue: string) =>
                            this.setState({ facilityFirebaseID: newValue === '' ? null : newValue })
                        }
                        validationError={this.getRequiredFieldValidationError(this.state.facilityFirebaseID || '')}
                        forceDisplayValidationError={this.state.forceDisplayValidationErrors}
                    />

                    <FormTextField
                        style={rightColumnFieldStyle}
                        isReadOnly={undefined !== this.props.peerToEdit}
                        label={COPY_CONSTANTS.email}
                        value={this.state.email}
                        initialValue={this.props.peerToEdit ? this.props.peerToEdit.emailAddress : ''}
                        onValueChanged={(newValue: string) =>
                            this.setState({
                                email: newValue,
                                userCreationFailed: false
                            })
                        }
                        validationError={this.getEmailValidationError()}
                        forceDisplayValidationError={this.state.forceDisplayValidationErrors}
                    />

                </div>

                <div style={rowStyle}>

                    <FormTextField
                        label={COPY_CONSTANTS.firstName}
                        value={this.state.firstName}
                        initialValue={this.props.peerToEdit ? this.props.peerToEdit.firstName : ''}
                        onValueChanged={(newValue: string) =>
                            this.setState({ firstName: newValue })
                        }
                        validationError={this.getRequiredFieldValidationError(this.state.firstName)}
                        forceDisplayValidationError={this.state.forceDisplayValidationErrors}
                    />

                    <FormTextField
                        style={rightColumnFieldStyle}
                        label={COPY_CONSTANTS.lastName}
                        value={this.state.lastName}
                        initialValue={this.props.peerToEdit ? this.props.peerToEdit.lastName : ''}
                        onValueChanged={(newValue: string) =>
                            this.setState({ lastName: newValue })
                        }
                        validationError={this.getRequiredFieldValidationError(this.state.lastName)}
                        forceDisplayValidationError={this.state.forceDisplayValidationErrors}
                    />

                </div>

            </Modal>
        );
    }

}
