import * as React from 'react';
import { Client } from '../../data/models/Client';
import { RIGHT_ARROW_GREY_ICON } from '../../images/images';
import { ClientLink } from './ClientLink';

interface Props {
    client: Client;
}

export const ClientLinkWithArrow: React.SFC<Props> = ({ client }) => (
    <ClientLink client={client}>
        <span style={arrowContainerStyle} key={client.firebaseUid}>
            <img src={RIGHT_ARROW_GREY_ICON} />
        </span>
    </ClientLink>
);


const arrowContainerStyle: React.CSSProperties = {
    display: 'flex',
    flex: 1,
    alignSelf: 'stretch',
    alignItems: 'center',
    justifyContent: 'center'
};