import * as assert from 'assert';

const chainAssert = (root: any, className?: string) => (caller: string) => (chain: string[]) => {
    let node = root;
    chain.forEach(leafName => {
        node = node[leafName];
        assert(node != null, `${className ? className + '#' : ''}${caller}: ${leafName} is null or undefined`);
    });
};

const assertNotNull = (className?: string) => (caller: string) => <T>(target: T, targetName: string) => {
    assert(target != null, `${className ? className + '#' : ''}${caller}: ${targetName} is null or undefined`);
};

const assertTypeof = (className?: string) => (caller: string) => (type: string) => <T>(
    target: T,
    targetName: string
) => {
    assert(
        typeof target === type,
        `${className ? className + '#' : ''}${caller}: ${targetName} is not of type ${type}`
    );
};

const assertIsArray = (className?: string) => (caller: string) => (array: any[], arrayName: string) => {
    assert(Array.isArray(array), `${className ? className + '#' : ''}${caller}: ${arrayName} is not an array`);
};

const assertCondition = (className?: string) => (caller: string) => (condition: boolean, message: string) => {
    assert(condition, `${className ? className + '#' : ''}${caller}: ${message}`);
};

export { chainAssert, assertNotNull, assertTypeof, assertIsArray, assertCondition };
