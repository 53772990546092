import * as React from 'react';
import { ChangeEvent, CSSProperties } from 'react';
import { STYLE_CONSTANTS } from '../../assets/StyleConstants';
import { FormField } from './FormField';
import { ItemKeyAndDisplayValue } from '../../util/common';

interface Props {
    style?: CSSProperties;
    label: string;
    items: ItemKeyAndDisplayValue[];
    selectedItemKey: string;
    initialSelectedItemKey: string;
    validationError: string;
    forceDisplayValidationError: boolean;
    onValueChanged: (newSelectedItemKey: string, oldSelectedItemKey: string) => void;
}

interface State {
    hasFocus: boolean;
    hasValueChanged: boolean;
}

export class FormDropdownField extends React.Component<Props, State> {

    public state: State = {
        hasFocus: false,
        hasValueChanged: false
    };

    private onValueChanged(newSelectedItemKey: string, oldSelectedItemKey: string): void {

        if ('' === this.props.selectedItemKey && '' === newSelectedItemKey) {
            return;
        }

        if (false === this.state.hasValueChanged
            && (
                '' === this.props.selectedItemKey
                || this.props.selectedItemKey !== newSelectedItemKey
            )
        ) {
            this.setState({ hasValueChanged: true });
        }

        this.props.onValueChanged(newSelectedItemKey, oldSelectedItemKey);
    }

    public render(): JSX.Element {

        return (
            <FormField
                style={this.props.style}
                label={this.props.label}
                validationError={(this.state.hasValueChanged || this.props.forceDisplayValidationError) ? this.props.validationError : ''}
                isModified={this.props.selectedItemKey !== this.props.initialSelectedItemKey}
                isPlaceholderShowing={'' === this.props.selectedItemKey}
            >

                <select
                    style={'' !== this.props.selectedItemKey
                        ? hasValueStyle
                        : placeholderStyle}
                    value={this.props.selectedItemKey}
                    onFocus={() => this.setState({ hasFocus: true})}
                    onBlur={() => this.setState({ hasFocus: false})}
                    onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                        this.onValueChanged(e.target.value, this.props.selectedItemKey)
                    }
                >

                    <option
                        key={''}
                        value={''}
                    >
                        {this.props.label}
                    </option>

                    {this.props.items.map(
                        (item: ItemKeyAndDisplayValue) => {

                            return (
                                <option
                                    key={item.key}
                                    value={item.key}
                                >
                                    {item.display}
                                </option>
                            );
                        }
                    )}
                </select>

            </FormField>
        );
    }
}

const style: CSSProperties = {
    backgroundColor: 'transparent',
    border: 0,
    outline: 0,
    fontSize: STYLE_CONSTANTS.fontSizeFormField,
    resize: 'vertical',
    width: '100%'
};

const hasValueStyle: CSSProperties = {
    ...style,
    color: STYLE_CONSTANTS.darkestGray
};

const placeholderStyle: CSSProperties = {
    ...style,
    color: STYLE_CONSTANTS.mediumGray
};
