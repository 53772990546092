import * as React from 'react';
import { VictoryPie } from 'victory';
import { COPY_CONSTANTS } from '../../assets/CopyConstants';
import { STYLE_CONSTANTS } from '../../assets/StyleConstants';
import { Client } from '../../data/models/Client';
import { GraphTimeWindow } from '../../data/models/GraphTimeWindow';

interface Props {
    client: Client;
    numberOfDaysViewing: GraphTimeWindow;
    style?: React.CSSProperties;
    headingStyle?: React.CSSProperties;
}

interface State { }

const PieKeyColors = {
    Joyful: '#30B0F6',
    Mad: '#F77081',
    Peaceful: '#FFA779',
    Powerful: '#C151D8',
    Sad: '#9C71FF',
    Scared: '#49DBA7',
    [COPY_CONSTANTS.noEmotionsData]: STYLE_CONSTANTS.mediumGray

};

export class EmotionsGraph extends React.Component<Props, State> {
    private getDataFromClient(): [string, number][] {
        if (null == this.props.client) {
            return [];
        }
        const reflections = this.props.client.getRecentReflectionsFor(this.props.numberOfDaysViewing);
        const sumsOfEmotions = reflections.reduce(
            (sums, reflection) => {
                Array.from(reflection.emotions.keys()).forEach(emotion => sums.set(emotion, (sums.get(emotion) || 0) + 1));
                return sums;
            },
            new Map<string, number>()
        );
        if (sumsOfEmotions.size < 1) {
            return [[COPY_CONSTANTS.noEmotionsData, 1]];
        }
        return Array.from(sumsOfEmotions.entries())
            .sort(([_, valueA], [__, valueB]) => valueB - valueA)
            .slice(0, 5)
            .sort(([emotionA], [emotionB]) => emotionA.localeCompare(emotionB));
    }

    public render(): JSX.Element {
        const data = this.getDataFromClient();
        console.log('data', data);
        const colors = data.map(dataPoint => PieKeyColors[dataPoint[0]]);

        return (
            <div style={this.props.style}>
                <p style={this.props.headingStyle}>{COPY_CONSTANTS.emotions}</p>
                <div style={{ display: 'flex', flex: 1, }}>
                    <VictoryPie
                        x={point => point[0]}
                        y={point => point[1]}
                        animate={{ duration: 500 }}
                        data={data}
                        colorScale={colors}
                        innerRadius={30}
                        padAngle={1}
                        padding={{ top: 30, bottom: 50, left: 100, right: 100 }}
                        height={220}
                        width={400}
                        style={{ labels: graphLabelStyle }}
                    />
                </div>
            </div>
        );
    }
}

const graphLabelStyle: React.CSSProperties = {
    fontSize: STYLE_CONSTANTS.fontSizeSmall,
    color: '#5C5C5C',
    fontFamily: 'Avenir, Nunito, sans-serif',
    fontWeight: 'lighter'
};