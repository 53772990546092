import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { rightColumnFieldStyle, rowStyle, clientNotesCreatorStyle } from '../../assets/CommonStyles';
import { COPY_CONSTANTS } from '../../assets/CopyConstants';
import { STYLE_CLASSES } from '../../assets/StyleConstants';
import { Date } from '../../data/models/Date';
import { PeerVisit } from '../../data/models/PeerVisit';
import { getAllCategories, PeerVisitCategory } from '../../data/models/PeerVisitCategory';
import { ApplicationStore } from '../../data/stores/ApplicationStore';
import { durationMinutesToDisplay, ItemKeyAndDisplayValue } from '../../util/common';
import { ConfirmationModal } from '../components/ConfirmationModal';
import { FormDateField } from './FormDateField';
import { FormDropdownField } from './FormDropdownField';
import { FormTextField } from './FormTextField';
import { Modal } from './Modal';
import Reactotron from 'reactotron-react-js';
import CKEditor from '@ckeditor/ckeditor5-react';
import InlineEditor from '@ckeditor/ckeditor5-build-inline';
//import InlineEditor from '@ckeditor/ckeditor5-editor-inline/src/inlineeditor';
//import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials';
//import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold';
//import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic';
//import GFMDataProcessor from '@ckeditor/ckeditor5-markdown-gfm/src/gfmdataprocessor';

// Simple plugin which loads the data processor.
function Markdown( editor ) {
    editor.data.processor = new GFMDataProcessor();
}


interface Props {
    store?: ApplicationStore;
    isVisible: boolean;
    peerUserFirebaseID: string;
    peerVisitToEdit?: PeerVisit;
    clientUserFirebaseIDForAdd?: string;
    onComplete: () => void;
}

interface State {
    clientUserFirebaseID?: string;
    category?: PeerVisitCategory;
    date?: Date;
    durationMinutes?: number;
    notes: string;
    clientIDsAndDisplayNames?: ItemKeyAndDisplayValue[];
    isValid: boolean;
    forceDisplayValidationErrors: boolean;
    isConfirmingDiscardChanges: boolean;
    useStateData: boolean;
    prefillKey: string;
    prefillKeysAndLabels?: ItemKeyAndDisplayValue[];
    prefillTemplates?: {};
    editor?: any;
}



function getPeerVisitDurations(): ItemKeyAndDisplayValue[] {

    const durations: ItemKeyAndDisplayValue[] = [];
    const MINUTES_MIN = 15;
    const MINUTES_MAX = 60 * 24;

    for (let minutes: number = MINUTES_MIN; minutes <= MINUTES_MAX; minutes += 15) {

        durations.push({
            key: minutes.toString(),
            display: durationMinutesToDisplay(minutes)
        });
    }

    return durations;
}




@inject('store')
@observer
export class PeerVisitAddEditModal extends React.Component<Props, State> {

    private readonly CLASS_NAME = 'PeerVisitAddEditModal';

    public state: State = this.getInitialState();

    private getInitialState(): State {

        
        return (
            this.props.peerVisitToEdit
                ? {
                    clientUserFirebaseID: this.props.peerVisitToEdit.clientUserFirebaseID || undefined,
                    category: this.props.peerVisitToEdit.category,
                    date: this.props.peerVisitToEdit.date,
                    durationMinutes: this.props.peerVisitToEdit.durationMinutes,
                    notes: this.props.peerVisitToEdit.notes,
                    isValid: true,
                    forceDisplayValidationErrors: false,
                    isConfirmingDiscardChanges: false,
                    useStateData: false,
                    prefillKey: 'empty',
                    editor: undefined,
                }
                : {
                    clientUserFirebaseID: this.props.clientUserFirebaseIDForAdd,
                    category: PeerVisitCategory.Unassigned,
                    date: Date.createForToday(),
                    durationMinutes: 30, // woo, magic number, eventually should be user configurable?
                    notes: '',
                    isValid: false,
                    forceDisplayValidationErrors: false,
                    isConfirmingDiscardChanges: false,
                    useStateData: false,
                    prefillKey: 'empty',
                    editor: undefined,
                }
        );
    }

    public setupPrefills(): ItemKeyAndDisplayValue[] {
        this.state.prefillKeysAndLabels = [];
        this.state.prefillTemplates = {};
        this.state.prefillKeysAndLabels.push({key: "empty", display: 'freeform session, no notes template'});
        this.state.prefillTemplates["empty"] = 'no preformed content<debug>';

        for (let index = 0; index < this.props.store.policyStore.notesTemplateTitles.length; index++) {
            this.state.prefillKeysAndLabels.push({
                key: index.toString(),
                display: this.props.store.policyStore.notesTemplateTitles[index]});
            this.state.prefillTemplates[index.toString()] =  this.props.store.policyStore.notesTemplates[index];
            
        }

        return this.state.prefillKeysAndLabels
    }

    public notesForKey(key: string): string {

        if (key == '') { return ''};
            // this is broken, as the key should be numberic?  or something -- anyway, the key is broken here.
            return this.state.prefillTemplates[key]
        }
    
    public static getDerivedStateFromProps(nextProps: Props, state: State): Partial<State> | null {
        const { peerVisitToEdit } = nextProps;
        if (false == nextProps.isVisible ) {
        //     console.debug(`getDerivedStateFromProps: next cut is not visible. abandoning`);
            return{};
        };
        if (null == peerVisitToEdit) {

            if (state.clientUserFirebaseID){ 
                // console.debug(`getDerivedStateFromProps: had no peer visit to edit. in this case, we are in the modal and have changed the client dropdown. this is where i might add the check to see if changing the user is permitted in this context.`, state);

            return {
                clientUserFirebaseID: state.clientUserFirebaseID,
                }

            };
            // console.debug(`getDerivedStateFromProps: had no peer visit to edit. either adding an identified client or leave the dropdown blank. can i distinguish via nextProps somehow?`,nextProps);
            // console.debug(`or state?`, state);             
            return { clientUserFirebaseID: nextProps.clientUserFirebaseIDForAdd || undefined };
        };
        // console.debug(`getDerivedStateFromProps: we did have a peer visit to edit. this should be the case where the existing visit is modified at time log. So set my derived state from there. this is the place where i need to decide if we can change the user log entry`, state);
        if (state.useStateData) {
            return{};
        }
        return {
             clientUserFirebaseID: peerVisitToEdit.clientUserFirebaseID,
             category: peerVisitToEdit.category,
             date: peerVisitToEdit.date,
             durationMinutes: peerVisitToEdit.durationMinutes,
             notes: peerVisitToEdit.notes,
             useStateData: true,
        };
        }

    public componentDidUpdate(prevProps: Props): void {
        this.setupPrefills()
        if (prevProps !== this.props) {
            this.refreshClientIDsAndDisplayNames();
        }
    }

    private async refreshClientIDsAndDisplayNames(): Promise<void> {
        if (null == this.props.store) {
            throw new Error(`${this.CLASS_NAME}: no store`);
        }
        //        this.setupPrefills();
        const clientIDsAndDisplayNames = await this.props.store.userStore.getClientIDsAndDisplayNamesForPeer(this.props.peerUserFirebaseID);
        const peerVisitToEdit = this.props.peerVisitToEdit;
        if (peerVisitToEdit
            && peerVisitToEdit.clientUserFirebaseID
            && false === clientIDsAndDisplayNames.some((clientIDAndName: ItemKeyAndDisplayValue) => clientIDAndName.key === peerVisitToEdit.clientUserFirebaseID)
        ) {
            const formerClient = await this.props.store.userStore.getClient(peerVisitToEdit.clientUserFirebaseID);
            clientIDsAndDisplayNames.push({
                key: peerVisitToEdit.clientUserFirebaseID,
                display: `${formerClient.firstName} (${formerClient.emailAddress})`
            });
        }

        this.setState({
            clientIDsAndDisplayNames: clientIDsAndDisplayNames
        });
    }

    private async save(): Promise<void> {

        if (false === this.validate()) {
            this.setState({ forceDisplayValidationErrors: true });
            return;
        }

        if (null == this.props.store) {
            throw new Error(`${this.CLASS_NAME}: no store`);
        }

        const previousDate = this.props.peerVisitToEdit ? this.props.peerVisitToEdit.date : this.state.date!;

        const peerVisit = PeerVisit.create(
            this.props.peerUserFirebaseID,
            this.state.clientUserFirebaseID,
            this.state.category!,
            this.state.date!,
            this.state.durationMinutes!,
            this.state.notes,
            this.props.peerVisitToEdit
        );

        if (this.props.peerVisitToEdit) {
            await this.props.store.peerVisitStore.updatePeerVisit(peerVisit, previousDate.dateString);
        } else {
            await this.props.store.peerVisitStore.addPeerVisit(peerVisit);
        }

        this.close(peerVisit);
    }

    private tryClose(): void {

        if (this.props.peerVisitToEdit) {
            if (
                this.state.clientUserFirebaseID !== this.props.peerVisitToEdit.clientUserFirebaseID
                || this.state.category !== this.props.peerVisitToEdit.category
                || (undefined === this.state.date || false === this.state.date.isSame(this.props.peerVisitToEdit.date))
                || this.state.durationMinutes !== this.props.peerVisitToEdit.durationMinutes
                || this.state.notes !== this.props.peerVisitToEdit.notes
            ) {
                this.setState({ isConfirmingDiscardChanges: true });
                return;
            }
        }

        this.close(undefined);
    }

    private close(savedPeerVisit: PeerVisit | undefined): void {

        this.props.onComplete();

        if (undefined === savedPeerVisit || undefined === this.props.peerVisitToEdit) {
            setTimeout(() => this.setState(this.getInitialState()), 500);
        }
    }

    private validate(): boolean {

        const isValid =
            this.getRequiredFieldValidationError(this.state.prefillKey).length === 0
            && this.getRequiredFieldValidationError(this.state.date).length === 0
            && this.getRequiredFieldValidationError(this.state.durationMinutes).length === 0;

        return isValid;
    }

    private getRequiredFieldValidationError(selectedValue: any): string {

        if (this.props.isVisible && (undefined == selectedValue || '' === selectedValue)) {
            return COPY_CONSTANTS.requiredField;
        }
        return '';
    }

    public render(): JSX.Element | null {

        if (null == this.props.store) {
            throw new Error(`${this.CLASS_NAME}: no store`);
        }
    //    console.debug(`rendering.`, this)
        return (
            <div>
                <div className={this.state.isConfirmingDiscardChanges ? STYLE_CLASSES.DIV_BLUR : undefined}>
                    <Modal
                        isVisible={this.props.isVisible && undefined !== this.state.clientIDsAndDisplayNames}
                        title={this.props.peerVisitToEdit
                            ? COPY_CONSTANTS.editTime
                            : COPY_CONSTANTS.addTime}
                        primaryButtonText={this.props.peerVisitToEdit
                            ? COPY_CONSTANTS.save
                            : COPY_CONSTANTS.addTime}
                        onPrimaryButtonClick={() => this.save()}
                        onCloseButtonClick={() => this.tryClose()}
                    >

                        <div style={rowStyle}>

                            <FormDropdownField
                                label={COPY_CONSTANTS.selectClientIfAny}
                                items={this.state.clientIDsAndDisplayNames || []}
                                selectedItemKey={this.state.clientUserFirebaseID || this.state.derivedClientUserFirebaseId || ''}
                                initialSelectedItemKey={this.props.peerVisitToEdit && this.props.peerVisitToEdit.clientUserFirebaseID ? this.props.peerVisitToEdit.clientUserFirebaseID : ''}
                                onValueChanged={(newValue: string) =>
                                    this.setState({ clientUserFirebaseID: newValue === '' ? undefined : newValue })
                                }
                                validationError={''}
                                forceDisplayValidationError={false}
                            />

                            { <FormDropdownField
                                style={rightColumnFieldStyle}
                                label={COPY_CONSTANTS.selectPrefill}
                                items={this.setupPrefills()}
                                selectedItemKey={this.state.prefillKey || 'empty'}
                                initialSelectedItemKey={'empty'}
                                onValueChanged={(newValue: string) => {
                                    const key: string = newValue == null ? "empty" : newValue;
                                    const notes = this.notesForKey(key);
                                    this.setState({ prefillKey: key, notes: notes});
                                    this.state.editor && this.state.editor.setData(notes) ;
                                }}
                                validationError={''}
                                forceDisplayValidationError={false}
                            /> }

                        </div>

                        <div style={rowStyle}>

                            <FormDateField
                                label={COPY_CONSTANTS.date}
                                value={this.state.date}
                                initialValue={this.props.peerVisitToEdit ? this.props.peerVisitToEdit.date : undefined}
                                validationError={this.getRequiredFieldValidationError(this.state.date)}
                                onValueChanged={(date: Date) => this.setState({ date: date })}
                                forceDisplayValidationError={this.state.forceDisplayValidationErrors}
                            />

                            <FormDropdownField
                                style={rightColumnFieldStyle}
                                label={COPY_CONSTANTS.selectTime}
                                items={getPeerVisitDurations()}
                                selectedItemKey={this.state.durationMinutes ? this.state.durationMinutes.toString() : ''}
                                initialSelectedItemKey={this.props.peerVisitToEdit ? this.props.peerVisitToEdit.durationMinutes.toString() : ''}
                                onValueChanged={(newValue: string) =>
                                    this.setState({ durationMinutes: newValue === '' ? undefined : Number(newValue) })
                                }
                                validationError={this.getRequiredFieldValidationError(this.state.durationMinutes)}
                                forceDisplayValidationError={this.state.forceDisplayValidationErrors}
                            />

                        </div>

                        <div style={rowStyle}>

                            <div style = {clientNotesCreatorStyle} >
                            <CKEditor
                                label = {COPY_CONSTANTS.notes}
                                onInit={ editor => {this.state.editor = editor;
                                    editor.setData(this.props.peerVisitToEdit ? 
                                        this.props.peerVisitToEdit.notes : '')}
                                }
                                onChange={ ( event, editor ) => {
                                    var upd: string = editor.getData();
                                    this.setState({ notes: upd})} }
                                editor={ InlineEditor }
                                config={{
                                    placeholder: 'Notes go here',
                                    toolbar: ['bold', 'italic', 'essentials', 'bulletedList', 'blockQuote'],
                                    }}
                            />
                            </div>
                        </div>

                    </Modal>
                </div>

                <ConfirmationModal
                    isVisible={this.state.isConfirmingDiscardChanges}
                    title={COPY_CONSTANTS.discardChanges}
                    message={COPY_CONSTANTS.discardChangesConfirmationMessage}
                    confirmButtonText={COPY_CONSTANTS.discard}
                    cancelButtonText={COPY_CONSTANTS.keepEditing}
                    confirmButtonStyleClassName={STYLE_CLASSES.BUTTON_GREEN}
                    cancelButtonStyleClassName={STYLE_CLASSES.BUTTON_MEDIUM_LIGHT_GRAY}
                    onConfirm={() => {
                        this.setState({ isConfirmingDiscardChanges: false });
                        this.close(undefined);
                    }}
                    onCancel={() => {
                        this.setState({ isConfirmingDiscardChanges: false });
                    }}
                    isInsideAnotherModal={true}
                />

            </div>
        );
    }

}
