import Reactotron from 'reactotron-react-js';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { CSSProperties } from 'react';
import { COPY_CONSTANTS } from '../../assets/CopyConstants';
import { Client } from '../../data/models/Client';
import { ApplicationStore } from '../../data/stores/ApplicationStore';
import styled from '../../styled';
import { isEmailValid } from '../../util/validate';
import { FormTextField } from '../components/FormTextField';
import { Modal } from '../components/Modal';

interface Props {
    isVisible: boolean;
    clientToEdit?: Client;
    onComplete: () => void;
    onClose: () => void;
    store?: ApplicationStore;
}

interface State {
    name: string;
    email: string;
    userCreationFailed: boolean;
    forceDisplayValidationError: boolean;
    isEmailAlreadyClient: boolean;
    isEmailForPeerOrFounder: boolean;
}

@inject('store')
@observer
export class ClientAddEditModal extends React.Component<Props, State> {

    public state = this.getInitialState();

    public componentDidUpdate(prevProps: Props, prevState: State): void {
        this.refreshWhetherEmailIsValidForClient(prevState.email);
    }

    private getInitialState(): State {
        const { clientToEdit } = this.props;
        const defaultState = {
            userCreationFailed: false,
            forceDisplayValidationError: false,
            isEmailAlreadyClient: false,
            isEmailForPeerOrFounder: false,
        };
        return null != clientToEdit
            ? {
                name: clientToEdit.firstName,
                email: clientToEdit.emailAddress,
                ...defaultState
            }
            : {
                name: '',
                email: '',
                ...defaultState
            };
    }

    private async refreshWhetherEmailIsValidForClient(previousStateEmail: string): Promise<void> {
        if (this.state.email === previousStateEmail) {
            return;
        }

        let isEmailAlreadyClient = false;
        let isEmailForPeerOrFounder = false;

        if (isEmailValid(this.state.email)) {

            const { store } = this.props;

            if (null == store) {
                throw new Error(`${this.constructor.name}.refreshWhetherUserExistsWithEmail: no store`);
            }

            isEmailForPeerOrFounder = await store.userStore.isEmailForPeerOrFounder(this.state.email);
            isEmailAlreadyClient = await store.userStore.isEmailAlreadyClient(this.state.email);

        }
        this.setState({ isEmailAlreadyClient, isEmailForPeerOrFounder });
    }

    private getEmailValidationError(): string {

        if (null == this.props.store) {
            throw new Error(`${this.constructor.name}: no store`);
        }

        if (false === isEmailValid(this.state.email)) {
            return COPY_CONSTANTS.emailInvalid;
        }

        if (this.state.isEmailForPeerOrFounder) {
            return COPY_CONSTANTS.userEmailIsNotValidClient;
        }

        if (this.state.isEmailAlreadyClient) {
            return COPY_CONSTANTS.userEmailAlreadyClient;
        }

        if (this.state.userCreationFailed) {
            return COPY_CONSTANTS.errorCreatingUser;
        }

        return '';
    }

    private getRequiredFieldValidationError(value: string): string {
        if (value.length === 0) {
            return COPY_CONSTANTS.requiredField;
        }
        return '';
    }

    private validate(): boolean {

        const isValid =
            this.getRequiredFieldValidationError(this.state.name).length === 0
            && this.getEmailValidationError().length === 0;

        return isValid;
    }

    private async save(): Promise<void> {
        if (false === this.validate()) {
            this.setState({ forceDisplayValidationError: true });
            return;
        }

        const { store } = this.props;

        if (null == store) {
            throw new Error(`${this.constructor.name}: no store`);
        }

        if (undefined == this.props.clientToEdit) {

            let clientFirebaseID = await store.userStore.getUserIDForEmail(this.state.email);

            if (null == clientFirebaseID) {
                Reactotron.debug('checked if this email exists. it does not, so creating a user at auth');
                try {
                    clientFirebaseID = await store.authorizationStore.createUserWithEmailAndPassword(this.state.email);
                } catch (error) {
                    this.setState({
                        forceDisplayValidationError: true,
                        userCreationFailed: true
                    });
                    return;
                }
            }
            Reactotron.debug(`created a new clientFirebaseId: ${clientFirebaseID}`);

            await store.userStore.addClientToPeer(this.state.name, this.state.email, clientFirebaseID);

        } else {

            const peer = await store.userStore.getCurrentUser();

            if (undefined == peer || undefined == peer.firebaseUid) {
                throw new Error(`${this.constructor.name}: no current user`);
            }

            const client = Client.create(
                this.state.name.trim(),
                this.state.email,
                peer.firebaseUid,
                this.props.clientToEdit,
                undefined
            );

            await store.userStore.upsertUser(client);
        }

        this.close();
    }

    private close(): void {
        this.props.onComplete();

        if (undefined === this.props.clientToEdit) {
            this.setState(this.getInitialState());
        }
    }

    public render(): JSX.Element {
        return (
            <Modal
                isVisible={this.props.isVisible}
                title={undefined != this.props.clientToEdit ? COPY_CONSTANTS.editClient : COPY_CONSTANTS.addNewClient}
                primaryButtonText={undefined != this.props.clientToEdit ? COPY_CONSTANTS.save : COPY_CONSTANTS.addClient}
                onPrimaryButtonClick={() => this.save()}
                onCloseButtonClick={this.props.onClose}
            >
                <StyledForm>
                    <FormTextField
                        label={COPY_CONSTANTS.firstName}
                        value={this.state.name}
                        initialValue={this.props.clientToEdit ? this.props.clientToEdit.firstName : ''}
                        onValueChanged={(name: string) => this.setState({ name })}
                        validationError={this.getRequiredFieldValidationError(this.state.name)}
                        forceDisplayValidationError={this.state.forceDisplayValidationError}
                    />
                    <FormTextField
                        style={emailAuthFieldStyle}
                        label={COPY_CONSTANTS.email}
                        value={this.state.email}
                        isReadOnly={undefined != this.props.clientToEdit}
                        initialValue={this.props.clientToEdit ? this.props.clientToEdit.emailAddress : ''}
                        onValueChanged={(email: string) => this.setState({ email })}
                        validationError={this.getEmailValidationError()}
                        forceDisplayValidationError={this.state.forceDisplayValidationError}
                    />
                </StyledForm>
            </Modal>
        );
    }
}

const StyledForm = styled.div`
    display: flex;
    justify-content: space-between;
`;

const emailAuthFieldStyle: CSSProperties = {
    marginLeft: '40px',
};
