export interface IThemeInterface {
    white: string;
    snowWhite: string;
    spaceBlack: string;
    purpleBlack: string;
    purpleGray: string;
    lightGray: string;
    brightGreen: string;
}

export const theme: IThemeInterface = {
    white: '#FFF',
    snowWhite: '#FEFEFE',
    spaceBlack: '#4A4A4A',
    purpleBlack: '#4D416C',
    purpleGray: '#9F99B1',
    lightGray: '#9F99B1',
    brightGreen: '#4AD6AE',
};
