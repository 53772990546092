import * as assert from 'assert';
import { computed, ObservableMap } from 'mobx';
import moment from 'moment'; 
import { momentToISODate } from '../../util/common';
import { Date } from '../models/Date';
import { ClientReflection } from './ClientReflection';
import { IFirebaseUser } from './firebase/FirebaseUser';
import { Gender } from './Gender';
import { PaymentMethod } from './PaymentMethod';
import { Substance } from './Substance';
import { User } from './User';
import { UserType } from './UserType';

export class Client extends User {

    private _reflectionsByDate: ObservableMap<string, ClientReflection[]> = new ObservableMap();

    public get reflectionsByDate(): ObservableMap<string, ClientReflection[]> {
        return this._reflectionsByDate;
    }

    @computed public get lastCheckInDate(): Date | undefined {
        return this.latestStreakLastReflectionDate;
    }

    @computed public get isFlagged(): boolean {
        if (false === this.isOnboarded) {
            return false;
        }
        // original code:
        // let compareDate = Date.fromMoment(moment(this.onboardingTimestamp));
        // changed because ts was getting confused as to which moment was being referenced
        // i.e. the function alias? or the module alias? and it would consistenly resolve to the 
        // wrong one.  MJM, during update to ts 3.7.3
        let compareDate = Date.fromMoment(moment(this.onboardingTimestamp));
        if (null != this.latestStreakLastReflectionDate) {
            compareDate = this.latestStreakLastReflectionDate;
        }
        const today = Date.createForToday();
        return today.diff(compareDate) >= 3;
    }

    public get latestStreakLength(): number {
        if (null == this.latestStreakFirstReflectionDate || null == this.latestStreakLastReflectionDate) {
            return 0;
        }
        const today = Date.createForToday();
        const yesterday = new Date(momentToISODate(Date.createForToday().moment.subtract(1, 'day')));
        if (
            this.latestStreakLastReflectionDate.isSame(today)
            || this.latestStreakLastReflectionDate.isSame(yesterday)
        ) {
            return this.latestStreakLastReflectionDate.diff(this.latestStreakFirstReflectionDate) + 1;
        }
        return 0;
    }

    private isOnboarded: boolean; 
    private peerUserFirebaseID?: string;

    private gender: Gender;
    private birthYear: number;
    private stateCode: string;
    private county: string;
    private primarySubstance: Substance;
    private _recoveryDate: Date;
    private treatmentPaymentMethod: PaymentMethod;
    private treatmentFacilityOrg: string;
    private isTakingPrescription: boolean;
    private _program: string;
    private howHeardAbout: string;
    private onboardingTimestamp: number;

    private latestStreakLastReflectionDate: Date;
    private latestStreakFirstReflectionDate: Date;

    private badgeEarnedDates: Set<Date>;

    private constructor() {
        super();
        // MJM adding definite initializers

    }

    public get recoveryDate(): Date {
        return this._recoveryDate;
    }

    public get program(): string {
        return this._program;
    }

    public get nameAndEmail(): string {
        return `${this.firstName} (${this.emailAddress})`;
    }

    public static createFromFirebase(firebaseUid: string, firebaseUser: IFirebaseUser): Client {

        const client = new Client();

        super.createFromFirebase(firebaseUid, firebaseUser, client);

        assert(typeof firebaseUser.isOnboarded === 'boolean', 'Client constructor passed args with invalid isOnboarded');
        client.isOnboarded = firebaseUser.isOnboarded;

        if (undefined === firebaseUser.clientUser) {
            return client;
        }
        assert(undefined !== firebaseUser.clientUser, 'Client constructor passed args with undefined clientUser');
        const clientUser = firebaseUser.clientUser;

        assert(typeof clientUser.peerUserFirebaseID === 'string' || typeof clientUser.peerUserFirebaseID === 'undefined', 'Client constructor passed args with invalid peerUserFirebaseID');
        client.peerUserFirebaseID = clientUser.peerUserFirebaseID;

        if (undefined !== clientUser.profile) {
            const profile = clientUser.profile;

            assert(typeof profile.gender === 'string', 'Client constructor passed args with invalid gender');
            assert(typeof profile.birthYear === 'number', 'Client constructor passed args with invalid birthYear');
            assert(typeof profile.stateCode === 'string', 'Client constructor passed args with invalid stateCode');
            assert(typeof profile.county === 'string', 'Client constructor passed args with invalid county');
            assert(typeof profile.primarySubstance === 'string', 'Client constructor passed args with invalid primarySubstance');
            assert(typeof profile.recoveryDate === 'string', 'Client constructor passed args with invalid recoveryDate');
            assert(typeof profile.treatmentPaymentMethod === 'string', 'Client constructor passed args with invalid treatmentPaymentMethod');
            assert(typeof profile.treatmentFacilityOrg === 'string', 'Client constructor passed args with invalid treatmentFacilityOrg');
            assert(typeof profile.isTakingPrescription === 'boolean', 'Client constructor passed args with invalid isTakingPrescription');
            assert(typeof profile.program === 'string', 'Client constructor passed args with invalid program');
            assert(typeof profile.howHeardAbout === 'string', 'Client constructor passed args with invalid howHeardAbout');
            assert(typeof profile.onboardingTimestamp === 'number', 'Client constructor passed args with invalid onboardingTimestamp');

            client.gender = profile.gender as Gender;
            client.birthYear = profile.birthYear;
            client.stateCode = profile.stateCode;
            client.county = profile.county;
            client.primarySubstance = profile.primarySubstance as Substance;
            client._recoveryDate = new Date(profile.recoveryDate);
            client.treatmentPaymentMethod = profile.treatmentPaymentMethod as PaymentMethod;
            client.treatmentFacilityOrg = profile.treatmentFacilityOrg;
            client.isTakingPrescription = profile.isTakingPrescription;
            client._program = profile.program;
            client.howHeardAbout = profile.howHeardAbout;
            client.onboardingTimestamp = profile.onboardingTimestamp;
        }

        if (undefined !== clientUser.latestStreak) {

            const latestStreak = clientUser.latestStreak;

            assert(typeof latestStreak.lastReflectionDate === 'string', 'Client constructor passed args with invalid lastReflectionDate');
            assert(typeof latestStreak.lastReflectionDate === 'string', 'Client constructor passed args with invalid firstReflectionDate');
            client.latestStreakLastReflectionDate = new Date(latestStreak.lastReflectionDate);
            client.latestStreakFirstReflectionDate = new Date(latestStreak.firstReflectionDate);
        }

        client.badgeEarnedDates = new Set(
            clientUser.badges
                ? Object.keys(clientUser.badges)
                    .map((badgeDateString) => new Date(badgeDateString))
                : []
        );

        return client;
    }

    public static create(firstName: string, emailAddress: string, peerUserFirebaseID: string, existingClient: Client | undefined, newClientFirebaseID: string | undefined): Client {
        assert(undefined != existingClient || undefined != newClientFirebaseID, 'Client create passed neither existingClient nor newClientFirebaseID');
        assert(undefined == existingClient || undefined == newClientFirebaseID, 'Client create passed both existingClient and newClientFirebaseID');

        const client = existingClient || new Client();

        super.createUser(emailAddress, firstName, client, newClientFirebaseID);

        client.peerUserFirebaseID = peerUserFirebaseID;

        if (undefined == existingClient) {
            client.isOnboarded = false;
        }

        return client;
    }

    public toFirebaseUser(): IFirebaseUser {

        let firebaseUser = super.getFirebaseUser() as IFirebaseUser;

        firebaseUser.userType = UserType.Client;
        firebaseUser.isOnboarded = this.isOnboarded;
        firebaseUser.clientUser = {
            peerUserFirebaseID: this.peerUserFirebaseID,
        };

        if (this.recoveryDate) {
            firebaseUser.clientUser.profile = {
                gender: this.gender,
                birthYear: this.birthYear,
                stateCode: this.stateCode,
                county: this.county,
                primarySubstance: this.primarySubstance,
                recoveryDate: this.recoveryDate.dateString,
                treatmentFacilityOrg: this.treatmentFacilityOrg,
                treatmentPaymentMethod: this.treatmentPaymentMethod,
                isTakingPrescription: this.isTakingPrescription,
                program: this._program,
                howHeardAbout: this.howHeardAbout,
                onboardingTimestamp: this.onboardingTimestamp
            };
        }

        if (this.latestStreakFirstReflectionDate && this.latestStreakLastReflectionDate) {
            firebaseUser.clientUser.latestStreak = {
                lastReflectionDate: this.latestStreakLastReflectionDate.dateString,
                firstReflectionDate: this.latestStreakFirstReflectionDate.dateString
            };
        }

        return firebaseUser;
    }

    public getRecentReflectionsFor(numberOfDays: number): ClientReflection[] {
        const today = Date.createForToday();
        return Array.from(this._reflectionsByDate.values())
            .reduce((a, b) => a.concat(Array.from(b)), [])
            .filter(reflection => today.diff(Date.fromDisplayString(reflection.dateDisplay)) < numberOfDays);
    }

    public doesMatchSearch(searchTerm: string): boolean {
        return this.firstName.toLocaleLowerCase().includes(searchTerm) || this.emailAddress.toLocaleLowerCase().includes(searchTerm);
    }
}
