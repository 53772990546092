import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { CSSProperties } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { panelButtonStyle, rowStyle } from '../../assets/CommonStyles';
import { COPY_CONSTANTS } from '../../assets/CopyConstants';
import { STYLE_CLASSES, STYLE_CONSTANTS } from '../../assets/StyleConstants';
import { Facility } from '../../data/models/Facility';
import { Peer } from '../../data/models/Peer';
import { ApplicationStore } from '../../data/stores/ApplicationStore';
import { ROUTES } from '../../Routes';
import { CustomButton } from '../components/Button';
import { FacilityAddEditModal } from '../components/FacilityAddEditModal';
import { Header } from '../components/Header';
import { ListPanel } from '../components/ListPanel';
import { Darkener } from '../components/Darkener';
import { Org } from '../../data/models/Org';

interface IRouteParams {
    id: string;
}

export interface Props extends RouteComponentProps<IRouteParams> {
    store: ApplicationStore;
}

interface State {
    facility?: Facility;
    activePeers?: Peer[];
    orgName: string;
    isEditing: boolean;
}

@inject('store')
@observer
export class FacilityDetail extends React.Component<Props, State> {

    public constructor(props: Props) {

        super(props);

        this.state = {
            isEditing: false
        };
    }

    public async componentWillMount(): Promise<void> {

        const facilityFirebaseID = this.props.match.params.id;
        const facility = await this.props.store.facilityStore.getFacility(facilityFirebaseID);
        const activePeers = await this.props.store.userStore.getActivePeersForFacility(facilityFirebaseID);

        this.setState({
            facility: facility,
            activePeers: activePeers,
        });

        var org: Org;
        if (facility.orgID ) {
            org = await this.props.store.orgStore.getOrg(facility.orgID);
            this.setState({orgName: org? org.name : '' })
        } 
    }

    private getFacilityInfoItems(facility: Facility): JSX.Element[][] {

        const rowItems: JSX.Element[][] = [
            this.buildFacilityInfoRowItem(COPY_CONSTANTS.name, facility.name),
            this.buildFacilityInfoRowItem(COPY_CONSTANTS.address, facility.address, multiLineValueStyle),
            this.buildFacilityInfoRowItem(COPY_CONSTANTS.telephone, facility.contactPhone),
            this.buildFacilityInfoRowItem(COPY_CONSTANTS.email, facility.contactEmailAddress, wordBreakAllStyle)];
        if (facility.orgID) { 
            rowItems.push(this.buildFacilityInfoRowItem(COPY_CONSTANTS.orgName, this.state.orgName, )) //TODO: make this into a link, not just a static.
            rowItems.push(this.buildFacilityInfoRowItemWithLink(COPY_CONSTANTS.org, this.state.orgName, `${  ROUTES.org}/${facility.orgID}`))
        };
        return rowItems;
    }


    private buildFacilityInfoRowItem(field: string, value: string, rowStyle?: CSSProperties): JSX.Element[] {

        return [
            <p style={cellTextStyle} key={'field'}>{field}</p>,
            <p style={{ ...cellTextStyle, ...rowStyle }} key={'value'}>{value}</p>
        ];
    }

    private buildFacilityInfoRowItemWithLink(field: string, value: string, to: string): JSX.Element[] {
    
        return [
            <p style={cellTextStyle} key={'field'}>{field}</p>,
            <Link
                to={`${to}`}
                style={{...cellLinkTextStyle, ...rowStyle}}
                key={'value'}
            > {`${value}`} </Link>
        ]
    }

    private getPeerRowItems(activePeers: Peer[]): JSX.Element[][] {

        const rowItems = activePeers
            .sort((a: Peer, b: Peer) => a.compareToForSort(b))
            .map((peer: Peer) => this.buildPeerRowItem(peer));

        if (0 === rowItems.length) {
            rowItems.push(this.buildNoPeersRowItem());
        }

        return rowItems;
    }

    private buildPeerRowItem(peer: Peer): JSX.Element[] {

        return [
            this.getPeerLink(peer),
            <p key={'email'} style={{ ...cellTextStyle, ...wordBreakAllStyle }}>{peer.emailAddress}</p>
        ];
    }

    private buildNoPeersRowItem(): JSX.Element[] {

        return [
            <p key={'message'} style={{ ...cellTextStyle, ...cellMessageTextStyle }}>{COPY_CONSTANTS.noPeers}</p>
        ];
    }

    private getPeerLink(peer: Peer): JSX.Element {

        return (
            <Link
                to={`${ROUTES.peer}/${peer.firebaseUid}`}
                style={cellLinkTextStyle}
            >
                {peer.fullName}
            </Link>
        );
    }

    public render(): JSX.Element | null {

        if (undefined === this.state.facility || undefined === this.state.activePeers) {
            return null;
        }

        return (
            <div>

                <div className={this.props.store!.isModalShowing ? STYLE_CLASSES.DIV_BLUR : undefined}>

                    <div style={containerStyle}>

                        <Header title={this.state.facility.name} addButtons={[]} />

                        <ListPanel
                            title={COPY_CONSTANTS.facilityInfo}
                            columnWidths={['50%', '50%']}
                            rowItems={this.getFacilityInfoItems(this.state.facility)}
                            style={facilityInfoStyle}
                            titleRowButtons={[
                                <CustomButton
                                    key={COPY_CONSTANTS.edit}
                                    style={panelButtonStyle}
                                    styleClassName={STYLE_CLASSES.BUTTON_PANEL}
                                    onClick={() => this.setState({ isEditing: true })}
                                >

                                    {COPY_CONSTANTS.edit}

                                </CustomButton>
                            ]}
                        />

                        <ListPanel
                            title={COPY_CONSTANTS.peers}
                            columnNames={[COPY_CONSTANTS.name, COPY_CONSTANTS.email]}
                            columnWidths={['50%', '50%']}
                            rowItems={this.getPeerRowItems(this.state.activePeers)}
                            style={peerListStyle}
                        />

                    </div>

                </div>

                <FacilityAddEditModal
                    isVisible={this.state.isEditing}
                    facilityToEdit={this.state.facility}
                    onComplete={() => this.setState({ isEditing: false })}
                />

                <Darkener isActive={this.props.store!.isModalShowing}/>

            </div>
        );

    }

}

const containerStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    padding: '50px',
    backgroundColor: STYLE_CONSTANTS.lightGray
};

const facilityInfoStyle: CSSProperties = {
    width: '100%',
    minWidth: '400px'
};

const cellTextStyle: CSSProperties = {
    margin: '0',
    textDecoration: 'none',
    color: STYLE_CONSTANTS.darkGray
};

const cellLinkTextStyle: CSSProperties = {
    ...cellTextStyle,
    color: STYLE_CONSTANTS.darkerPurple
};

const multiLineValueStyle: CSSProperties = {
    whiteSpace: 'pre-wrap'
};

const peerListStyle: CSSProperties = {
    marginTop: '50px'
};

const cellMessageTextStyle: CSSProperties = {
    fontStyle: 'italic'
};

const wordBreakAllStyle: CSSProperties = {
    wordBreak: 'break-all'
};
