export const COPY_CONSTANTS = {
    appName: 'Recovree',
    dashboard: 'Dashboard',
    stats: 'Stats',
    our_community: 'Our Community',
    all: 'All',
    minnedemo: 'Minnedemo',
    addFacility: 'Add Facility',
    addNewFacility: 'Add New Facility',
    editFacility: 'Edit Facility',
    addPeer: 'Add Peer',
    addNewPeer: 'Add New Peer',
    editPeer: 'Edit Peer',
    addClient: 'Add Client',
    editClient: 'Edit Client',
    addNewClient: 'Add New Client',
    addTime: 'Add Time',
    search: 'Search',
    peers: 'Peers',
    facilities: 'Facilities',
    addOrg: 'Add Organization',
    addNewOrg: 'Add New Organization',
    orgAlreadyExists: 'Organization already exists',
    editOrg: 'Edit Organization',
    orgName: 'Organization Name',
    org: "Organization",
    orgInfo: "Organization Info",
    facilityHasNoPeers: 'Facility has no active peers',
    noOrg: 'No organization',
    orgHasNoFacilities: 'Organizaiton has no active facilities',
    noPeers: 'No active peers',
    name: 'Name',
    facility: 'Facility',
    facilityInfo: 'Facility Info',
    firstName: 'First name',
    lastName: 'Last name',
    peerInfo: 'Peer Info',
    joinDate: 'Join Date',
    clients: 'Clients',
    timeLog: 'Time Log',
    peerVisits: 'Peer Visits',
    noPeerVisits: 'Peer has no visits',
    category: 'Category',
    client: 'Client',
    type: 'Type',
    date: 'Date',
    time: 'Time',
    edit: 'Edit',
    deactivate: 'Deactivate',
    deactivatePeer: 'Deactivate Peer',
    cancel: 'Cancel',
    deactivatePeerConfirmationMessage: 'Are you sure you want to deactivate this peer? This can only be undone manually by an administrator.',
    save: 'Save',
    facilityAlreadyExists: 'Facility already exists',
    userEmailAlreadyExists: 'User with this email already exists',
    userEmailIsNotValidClient: 'User with this email is not a client',
    userEmailAlreadyClient: 'User with this email is already your client',
    errorCreatingUser: 'Invalid email address, or user already exists',
    phoneOrEmailRequired: 'Phone or email is required',
    phoneInvalid: 'Invalid telephone number',
    emailInvalid: 'Invalid email address',
    endDateEarlierThanStartDate: 'End date earlier than start date',
    requiredField: 'Required',
    invalidLogin: 'Invalid email or password',
    signupFailed: `You're not currently in our system, and can't log in. Please contact hello@recovree.com with questions on access.`,
    signupFailedIncomplete: 'Signup failed',
    signupFailedClient: `It looks like you're trying to sign up as a client; this is the portal for Peer Recovery Specialists. Download the app from iTunes or Google Play to get the correct platform!`,
    signupFailedAlreadyOnboarded: `It looks like you've already signed up. Please click Login below.`,
    passwordResetFailedSignUpFirst: 'Please complete the signup process to set your password',
    passwordResetFailedClient: `It looks like you have a client account; this is the portal for Peer Recovery Specialists. Download the app from iTunes or Google Play!`,
    email: 'Email',
    password: 'Password',
    reEnterPassword: 'Re-enter Password',
    passwordsDoNotMatch: 'Passwords do not match',
    login: 'Login',
    signUp: 'Sign Up',
    logOut: 'Log Out',
    forgot: 'Forgot?',
    passwordReset: 'Password Reset',
    passwordResetInstructions: 'We\'ll send you a link to reset your password.',
    send: 'Send',
    forgotPasswordEmailSent: `If you have an account with us, you'll receive an email with a password reset link`,
    faciltyName: 'Facility name',
    address: 'Address',
    telephone: 'Telephone',
    sort: 'Sort',
    export: 'Export',
    inactivityWarningTitle: 'Are you still there?',
    inactivityWarningMessage: 'You haven\'t been active for a few minutes. Would you like to stay logged in? If not, you\'ll be logged out in a minute.',
    yes: 'Stay logged in',
    pleaseCreatePassword: 'Welcome! Please choose a password to log in.',
    activity: 'Activity',
    notes: 'Notes',
    editTime: 'Edit Time',
    noActivities: 'No activities',
    noOrgsOrFacilities: 'No organizations or facilities',
    view: 'View',
    viewAll: 'View all',
    peerVisit: 'Peer Visit',
    editNotes: 'Edit Notes',
    discardChanges: 'Discard changes?',
    discardChangesConfirmationMessage: 'You have unsaved changes.',
    discard: 'Discard',
    keepEditing: 'Keep Editing',
    selectClientIfAny: 'Select Client (if any)',
    selectClientOrAll: 'Select Client (or all)',
    selectCategory: 'Select Category',
    selectDate: 'Select Date',
    selectTime: 'Select Duration',
    startDate: 'Start Date',
    endDate: 'End Date',
    none: '(none)',
    selectAll: '[All]',
    nonClient: '[Non-Client]',
    peerVisitExportFileName: 'recovree_time_export.csv',
    checkedIn: 'Checked In',
    streak: 'Streak',
    timeThisWeek: 'Time This Week',
    lastCheckIn: 'Last Check In',
    clientInfo: 'Client Info',
    remove: 'Remove',
    insights: 'Insights',
    recoveryDate: 'Recovery Date',
    program: 'Program',
    reflection: 'Reflection',
    emotions: 'Emotions',
    overview: 'Overview',
    answers: 'Answers',
    removeClient: 'Remove Client',
    removeClientConfirmationMessage: 'Are you sure you want to remove this client?',
    add: 'Add',
    flagged: 'Flagged',
    searchPlaceholder: 'Search',
    noEmotionsData: 'No emotions tracked for this time period',
    week: 'Week',
    month: 'Month',
    termsAndConditionsTitle: 'Terms And Conditions',
    termsAndConditionsLink: 'Terms And Conditions',
    termsAndConditionsStart: 'I have read and agree to the ',
    termAndConditionsConjunction: ' and the ',
    privacyPolicyLink: 'Privacy Policy',
    today: 'today',
    noClientsMessage: 'No clients yet',
    noFlaggedClientsMessage: 'No flagged clients',
    noClientsSearchMessage: 'No clients match your search',
    termsNotAccepted: 'Please accept the Recovree terms and conditions',
    contactSupportLink: 'Contact Support',
    contactSupportURL: 'mailto:hello@recovree.com',
    yesFrequencyTitle: 'Number of questions answered with \'yes\'',
    questionFrequencyTitle: 'Number of \'yes\' answers by question',
    totalResponses: 'Total responses:',
    questionsAnswered: 'Questions answered',
    responses: 'Responses',
    question: 'Question',
    numberOfYesAnswers: 'Number of Yes Answers',
    selectPrefill: 'What kind of session notes are you capturing?'
};

const generalRatingToText = ['', 'Terrible', 'Not So Great', 'Okay', 'Good', 'Great'];
const foodRatingToText = ['', 'Not Healthy', 'A Little Healthy', 'Somewhat Healthy', 'Healthy', 'Very Healthy'];
const exerciseRatingToText = ['', 'Not Active', 'Slightly Active', 'Moderately Active', 'Active', 'Very Active'];

export const CLIENT_REFLECTION_CONSTANTS = {
    title: 'Reflection Answers',
    questionHeading: 'Question',
    answerHeading: 'Answer',
    questions: {
        overall: 'Overall, how are you feeling?',
        whatEmotions: 'What emotions have you experienced today?',
        drugUse: 'Have you used any non-medicinal drugs or alcohol today?',
        drugUseProblems: 'Did your use cause any problems?',
        drugUseConcern: 'Are you concerned?',
        medication: 'Have you taken your medication as prescribed today?',
        sleep: 'How did you sleep last night?',
        dream: 'Did you experience any dreams last night that made you feel uncomfortable?',
        dreamDescription: 'Describe your dream',
        exercise: 'Did you exercise today?',
        food: 'Rate your food consumption',
        sponsor: 'Did you connect with a sponsor, mentor, or person you trust today?',
        socialInteractions: 'Did you engage in any positive social interaction today?',
        supportGroups: 'How many support groups did you attend today?',
        stressors: 'Did any of the following create anxiety or stress today?',
        goal: 'Set a goal',
        yesterdaysGoal: (goal: string | null) => `Did you complete yesterday's goal` + (null != goal ? `: ${goal}?` : '?'),
        thankfulFor: 'What are you thankful for today?'
    },
    answers: {
        generalRating: (rating: number) => `${rating} ${generalRatingToText[rating]}`,
        foodRating: (rating: number) => `${rating} ${foodRatingToText[rating]}`,
        exerciseRating: (rating: number) => `${rating} ${exerciseRatingToText[rating]}`,
        yesNo: (value: boolean) => value ? 'Yes' : 'No'
    }
};
