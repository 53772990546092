export const FIREBASE_CONSTANTS = {

    environment: process.env.REACT_APP_FIREBASE_ENV,
    defaultPassword: process.env.REACT_APP_FIREBASE_AUTH_DEFAULT_PASSWORD || '',

    secondaryAppReferenceName: 'secondaryApp',

    eventChildAdded: 'child_added',
//    eventChildAdded: firebase.database.EventType: 'child_added',
    eventChildChanged: 'child_changed',
    eventChildRemoved: 'child_removed',

    value: 'value',
};