import * as assert from 'assert';
import * as moment from 'moment';
import { DATE_DISPLAY_FORMAT, momentToDateString } from '../../util/common';

const DATE_FORMAT = 'YYYY-MM-DD';

export class Date {

    private _date: moment.Moment;
    private _dateString: string;

    public constructor(date: string) {

        assert(typeof date === 'string');

        this._dateString = date;
        this._date = moment(date, DATE_FORMAT, true).startOf('day');
    }

    public static fromMoment(momentDate: moment.Moment): Date {

        return new Date(momentDate.format(DATE_FORMAT));
    }

    public static createForToday(): Date {
        return this.fromMoment(moment());
    }

    public static fromDisplayString(displayString: string): Date {
        return this.fromMoment(moment(displayString, DATE_DISPLAY_FORMAT));
    }

    public get isPast(): boolean {
        const today = moment().startOf('day');
        return this._date < today;
    }

    public get dateString(): string {
        return this._date.format(DATE_FORMAT);
    }

    public get displayString(): string {

        return momentToDateString(this._date);
    }

    public get moment(): moment.Moment {
        return this._date;
    }

    public isSame(otherDate: Date | undefined): boolean {

        if (undefined === otherDate) {
            return false;
        }

        return this._date.isSame(otherDate._date);
    }

    public diff(otherDate: Date): number {
        return this._date.diff(otherDate._date, 'days');
    }
}
