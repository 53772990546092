import * as React from 'react';
import { CSSProperties } from 'react';
import { STYLE_CONSTANTS } from '../../assets/StyleConstants';


interface Props {
    style?: CSSProperties;
    title: string | JSX.Element;
    titleRowButtons?: JSX.Element[];
    titleRowButtonContainerStyle?: CSSProperties;
}

export class Panel extends React.Component<Props, {}> {

    public render(): JSX.Element {

        return (
            <div style={{ ...containerStyle, ...this.props.style }}>

                <div style={titleRowStyle}>
                    <p style={titleStyle}>{this.props.title}</p>
                    <div style={this.props.titleRowButtonContainerStyle}>
                        {this.props.titleRowButtons ? this.props.titleRowButtons.map((button: JSX.Element) => button) : null}
                    </div>
                </div>

                <div style={bodyStyle}>
                    {this.props.children}
                </div>

            </div>
        );
    }
}

const containerStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    color: STYLE_CONSTANTS.white,
    backgroundColor: STYLE_CONSTANTS.darkPurple,
    borderRadius: '5px',
    borderWidth: '2px',
    marginTop: '30px'
};

const titleRowStyle: CSSProperties = {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '12px',
    paddingLeft: '20px',
    fontSize: STYLE_CONSTANTS.fontSizeMedium
};

const titleStyle: CSSProperties = {
    padding: 0,
    margin: 0,
    lineHeight: '35px'
};

const bodyStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: STYLE_CONSTANTS.lightestGray,
    height: '100%'
};
