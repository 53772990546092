import * as assert from 'assert';
import { IFirebaseUser } from './firebase/FirebaseUser';

export abstract class User {
    private _firebaseUid: string | undefined;
    private _emailAddress: string;
    private _firstName: string;
    private _createdTimestamp: number;

    protected constructor() {}

    public get emailAddress(): string {
        return this._emailAddress;
    }

    public get firstName(): string {
        return this._firstName;
    }

    public get firebaseUid(): string | undefined {
        return this._firebaseUid;
    }

    public get createdTimestamp(): number {
        return this._createdTimestamp;
    }

    public abstract toFirebaseUser(): IFirebaseUser;

    protected static createFromFirebase(firebaseUid: string, firebaseUser: IFirebaseUser, user: User): void {
        
        assert(typeof firebaseUid === 'string', 'User createFromFirebase passed args with invalid firebaseUid');
        assert(undefined !== firebaseUser, 'User createFromFirebase passed null or undefined args object');
        assert(typeof firebaseUser.emailAddress === 'string', 'User createFromFirebase passed args with invalid emailAddress');
        assert(typeof firebaseUser.firstName === 'string', 'User createFromFirebase passed args with invalid firstName');
        assert(typeof firebaseUser.createdTimestamp === 'number', 'User createFromFirebase passed args with invalid created');
        assert(user instanceof User, 'User createFromFirebase passed args with invalid user');

        user._firebaseUid = firebaseUid;
        user._emailAddress = firebaseUser.emailAddress;
        user._firstName = firebaseUser.firstName;
        user._createdTimestamp = firebaseUser.createdTimestamp;
    }

    protected static createUser(emailAddress: string, firstName: string, user: User, newPeerUserFirebaseID: string | undefined): void {
        
        assert(typeof emailAddress === 'string', 'User create passed args with invalid emailAddress');
        assert(typeof firstName === 'string', 'User create passed args with invalid firstName');
        assert(user instanceof User, 'User create passed args with invalid user');
        assert(typeof newPeerUserFirebaseID === 'string' || typeof newPeerUserFirebaseID === 'undefined', 'User create passed args with invalid newPeerUserFirebaseID');

        user._emailAddress = emailAddress.toLowerCase();
        user._firstName = firstName;

        if (newPeerUserFirebaseID) {
            user._firebaseUid = newPeerUserFirebaseID;
            user._createdTimestamp = 0;
        }
    }

    protected getFirebaseUser(): Pick<IFirebaseUser, 'emailAddress' | 'firstName' | 'createdTimestamp'> {
        
        return {
            emailAddress: this._emailAddress,
            firstName: this._firstName,
            createdTimestamp: this._createdTimestamp
        };
    }
    
}
