import * as React from 'react';
import { ChangeEvent, CSSProperties } from 'react';
import { STYLE_CLASSES, STYLE_CONSTANTS } from '../../assets/StyleConstants';
import { CustomButton } from './Button';
import { FormField } from './FormField';

interface Props {
    isMultiLine?: boolean;
    isReadOnly?: boolean;
    label: string;
    value: string;
    initialValue?: string;
    isPassword?: boolean;
    validationError?: string;
    isStatic?: boolean;
    forceDisplayValidationError?: boolean;
    onValueChanged: (newValue: string) => void;
    style?: CSSProperties;
    textStyle?: CSSProperties;
    labelTextStyle?: CSSProperties;
    indicatorBarUnpopulatedStyle?: CSSProperties;
    icon?: any;
    iconDimensionPixels?: number;
    embeddedButtonProps?: EmbeddedButtonProps;
    maxLength?: number;
}

export interface EmbeddedButtonProps {
    label: string;
    style: CSSProperties;
    styleClassName: string;
    onClick: () => void;
}

interface State {
    hasFocus: boolean;
    hasValueChanged: boolean;
}

export class FormTextField extends React.Component<Props, State> {

    public constructor(props: Props) {

        super(props);

        this.state = {
            hasFocus: false,
            hasValueChanged: false
        };
    }

    private onValueChanged(newValue: string): void {

        if ('' === this.props.value && newValue === this.props.label) {
            return;
        }

        let finalValue = newValue;

        if (0 === newValue.trim().length) {
            finalValue = '';
        }

        if (false === this.state.hasValueChanged && finalValue !== this.props.value) {
            this.setState({ hasValueChanged: true });
        }

        this.props.onValueChanged(finalValue);
    }

    private get shouldShowPlaceholder(): boolean {

        return (
            0 === this.props.value.length
            && false === this.state.hasFocus
        );
    }

    public render(): JSX.Element {

        const textBaseStyle =
            this.props.isMultiLine
                ? textAreaStyle
                : this.props.embeddedButtonProps
                    ? inputStyleWhenButtonPresent
                    : inputStyle;

        const textPlaceholderOrValueStyle =
            this.props.value.length > 0
                ? hasValueStyle
                : placeholderStyle;

        const textStyle = {
            ...textBaseStyle,
            ...textPlaceholderOrValueStyle,
            ...this.props.textStyle
        };

        const textValue =
            this.shouldShowPlaceholder
                ? this.props.label
                : this.props.value;

        const commonTextFieldProps = {
            readOnly: this.props.isReadOnly,
            value: textValue,
            style: textStyle,
            onFocus: () => this.setState({ hasFocus: true }),
            onBlur: () => this.setState({ hasFocus: false }),
            onChange: (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
                this.onValueChanged(e.target.value)
            ,
            maxLength: this.props.maxLength
        };

        return (
            <FormField
                style={this.props.style}
                labelTextStyle={this.props.labelTextStyle}
                label={this.props.label}
                validationError={(this.state.hasValueChanged || this.props.forceDisplayValidationError) ? this.props.validationError : ''}
                isStatic={this.props.isStatic}
                isModified={this.props.value !== this.props.initialValue}
                isPlaceholderShowing={this.shouldShowPlaceholder}
                indicatorBarUnpopulatedStyle={this.props.indicatorBarUnpopulatedStyle}
                icon={this.props.icon}
                iconDimensionPixels={this.props.iconDimensionPixels}
            >

                {this.props.isMultiLine

                    ?
                    <textarea
                        rows={3}
                        {...commonTextFieldProps}
                    />

                    :
                    <input
                        autoCapitalize={this.props.isPassword ? 'off' : 'on'}
                        type={
                            this.props.isPassword && false === this.shouldShowPlaceholder
                                ? 'password'
                                : 'text'
                        }
                        className={STYLE_CLASSES.FONT_WEIGHT_LIGHTER}
                        {...commonTextFieldProps}
                    />
                }

                {this.props.embeddedButtonProps
                    ?
                    <CustomButton
                        type={'button'}
                        style={{ ...embeddedButtonStyle, ...this.props.embeddedButtonProps.style }}
                        styleClassName={this.props.embeddedButtonProps.styleClassName}
                        onClick={() => this.props.embeddedButtonProps ? this.props.embeddedButtonProps.onClick() : () => { return; }}
                    >

                        <p style={embeddedButtonTextStyle}>
                            {this.props.embeddedButtonProps.label}
                        </p>

                    </CustomButton>

                    : null
                }

            </FormField>
        );
    }
}

const inputStyle: CSSProperties = {
    backgroundColor: 'transparent',
    border: 0,
    outline: 0,
    fontSize: STYLE_CONSTANTS.fontSizeFormField,
    width: '100%'
};

const inputStyleWhenButtonPresent: CSSProperties = {
    ...inputStyle,
    width: '65%'
};

const textAreaStyle: CSSProperties = {
    ...inputStyle,
    resize: 'vertical',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
};

const hasValueStyle: CSSProperties = {
    color: STYLE_CONSTANTS.darkestGray
};

const placeholderStyle: CSSProperties = {
    color: STYLE_CONSTANTS.mediumGray
};

const embeddedButtonStyle: CSSProperties = {
    width: '20%',
    marginLeft: '5%',
    position: 'relative'
};

const embeddedButtonTextStyle: CSSProperties = {
    fontSize: STYLE_CONSTANTS.fontSizeSmaller,
    margin: 'auto',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    width: '100%',
    height: '60%'
};
