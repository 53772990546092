import * as React from 'react';
import { CSSProperties } from 'react';
import { bigButtonStyle, modalStyle, pageTitleStyle } from '../../assets/CommonStyles';
import { STYLE_CLASSES } from '../../assets/StyleConstants';
import { CLOSE_ICON } from '../../images/images';
import { CustomButton } from '../components/Button';
import { Fadeable } from './Fadeable';

interface Props {
    isVisible: boolean;
    title: string;
    secondaryTitle?: string;
    primaryButtonText?: string;
    onPrimaryButtonClick?: () => void;
    onCloseButtonClick: () => void;
}

export class Modal extends React.Component<Props, {}> {

    public render(): JSX.Element | null {

        return (
            <Fadeable
                style={modalStyle}
                shouldDisplay={this.props.isVisible}
            >

                <CustomButton
                    style={closeButtonStyle}
                    styleClassName={STYLE_CLASSES.BUTTON_CLOSE}
                    onClick={() => this.props.onCloseButtonClick()}
                />

                <div style={contentStyle}>

                    <div style={titleStyle} className={STYLE_CLASSES.DIV_LIGHT_BOLD}>
                        <span>{this.props.title}</span>
                        {undefined != this.props.secondaryTitle &&
                            <span style={secondaryTitleStyle}>&nbsp;&ndash;&nbsp;{this.props.secondaryTitle}</span>
                        }

                    </div>

                    {this.props.children}

                    {this.props.primaryButtonText && <CustomButton
                        isDisabled={false}
                        style={bigButtonStyle}
                        styleClassName={STYLE_CLASSES.BUTTON_GREEN}
                        onClick={this.props.onPrimaryButtonClick}
                    >
                        {this.props.primaryButtonText}
                    </CustomButton>}

                </div>

            </Fadeable>
        );
    }
}

const contentStyle: CSSProperties = {
    margin: 'auto',
    marginTop: '40px',
    marginBottom: '40px',
    width: '95%',
   // maxWidth: '1000px',
    display: 'flex',
    flexDirection: 'column'
};

const titleStyle: CSSProperties = {
    ...pageTitleStyle,
    marginBottom: '30px'
};

const secondaryTitleStyle: CSSProperties = {
    fontWeight: 'normal'
};

const closeButtonStyle: CSSProperties = {
    position: 'absolute',
    right: '40px',
    top: '40px',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundImage: `url(${CLOSE_ICON})`,
    width: '60px',
    height: '60px',
    borderRadius: '30px'
};
