import * as React from 'react';
import { CSSProperties } from 'react';
import * as moment from 'moment';
import { STYLE_CONSTANTS } from '../../assets/StyleConstants';
import { FormField } from './FormField';
import { Date } from '../../data/models/Date';
import { NLDatePicker } from './NLDatePicker';

interface Props {
    style?: CSSProperties;
    label: string;
    value?: Date;
    initialValue: Date | undefined;
    validationError: string;
    forceDisplayValidationError?: boolean;
    onValueChanged: (newValue: Date) => void;
}

interface State {
    isDatePickerOpen: boolean;
}

export class FormDateField extends React.Component<Props, State> {

    public state: State = {
        isDatePickerOpen: false
    };

    private onValueChanged(newValue: moment.Moment): void {

        this.props.onValueChanged(Date.fromMoment(newValue));
        this.setState({
            isDatePickerOpen: false
        });
    }

    public render(): JSX.Element {

        return (
            <FormField
                style={{...formFieldStyle, ...this.props.style}}
                label={this.props.label}
                validationError={(this.props.value !== this.props.initialValue || this.props.forceDisplayValidationError) ? this.props.validationError : ''}
                isModified={this.props.value ? false === this.props.value.isSame(this.props.initialValue) : false}
                isPlaceholderShowing={undefined === this.props.value}
            >

                <p
                    style={this.props.value ? hasValueStyle : placeholderStyle}
                    onClick={() => this.setState({ isDatePickerOpen: true })}
                >
                    {this.props.value
                        ? this.props.value.displayString
                        : this.props.label
                    }
                </p>

                {this.state.isDatePickerOpen &&
                    <NLDatePicker
                        style={datePickerStyle}
                        selectedDate={this.props.value ? this.props.value.moment : moment()}
                        onDateSelected={(date: moment.Moment) => this.onValueChanged(date)}
                        onClickOutside={() => this.setState({ isDatePickerOpen: false })}
                    />
                }

            </FormField>
        );
    }
}

const formFieldStyle: CSSProperties = {
    position: 'relative'
};

const datePickerStyle: CSSProperties = {
    position: 'absolute',
    top: '80px'
};

const style: CSSProperties = {
    backgroundColor: 'transparent',
    fontSize: STYLE_CONSTANTS.fontSizeFormField,
    margin: 0,
    width: '100%'
};

const hasValueStyle: CSSProperties = {
    ...style,
    color: STYLE_CONSTANTS.darkestGray
};

const placeholderStyle: CSSProperties = {
    ...style,
    color: STYLE_CONSTANTS.mediumGray
};
