import { observable, ObservableMap } from 'mobx';
import { assertNotNull } from '../../util/assert';
import { IFirebasePeerVisitsForDay } from '../models/firebase/FirebasePeerVisitDays';
import { PeerVisit } from '../models/PeerVisit';
import { PeerVisitService } from '../services/PeerVisitService';

const CLASS_NAME = 'UserStore';

export class PeerVisitStore {

    private readonly assertNotNull = assertNotNull(CLASS_NAME);
    @observable private peerVisitsByClientID = new ObservableMap<string, PeerVisit[]>();

    public async getPeerVisitsForPeer(peerUserFirebaseID: string): Promise<PeerVisit[]> {

        const firebasePeerVisitDays = await PeerVisitService.getPeerVisitsForPeer(peerUserFirebaseID);
        if (null == firebasePeerVisitDays) {
            return [];
        }

        const peerVisits: PeerVisit[] = [];

        for (let dateString in firebasePeerVisitDays) {

            const firebasePeerVisitsForDay: IFirebasePeerVisitsForDay = firebasePeerVisitDays[dateString];

            for (let firebasePeerVisitID in firebasePeerVisitsForDay) {

                const peerVisit = PeerVisit.createFromFirebase(peerUserFirebaseID, dateString, firebasePeerVisitID, firebasePeerVisitsForDay[firebasePeerVisitID]);
                peerVisits.push(peerVisit);
            }
        }

        return peerVisits;
    }

    public async getPeerVisitsForClient(peerUserFirebaseID: string, clientUserFirebaseID: string): Promise<PeerVisit[]> {

        const allPeerVisits = await this.getPeerVisitsForPeer(peerUserFirebaseID);
        return allPeerVisits.filter((peerVisit: PeerVisit) => peerVisit.clientUserFirebaseID === clientUserFirebaseID);
    }

    public getPeerVisitsForPeerAndClient(peerUserFirebaseID: string, clientUserFirebaseID: string): PeerVisit[] {
        return this.peerVisitsByClientID.get(clientUserFirebaseID) || [];
    }

    public async addPeerVisit(peerVisit: PeerVisit): Promise<void> {

        this.assertNotNull('insertPeerVisit')(peerVisit, 'peerVisit');

        await PeerVisitService.addPeerVisit(
            peerVisit.peerUserFirebaseID, peerVisit.date.dateString, peerVisit.toFirebasePeerVisit());
        const existingVisitsForClient = this.peerVisitsByClientID.get(peerVisit.clientUserFirebaseID!) || [];
        this.peerVisitsByClientID.set(peerVisit.clientUserFirebaseID!, existingVisitsForClient.concat([peerVisit]));
    }

    public async updatePeerVisit(peerVisit: PeerVisit, previousDateString: string): Promise<void> {

        this.assertNotNull('updatePeerVisit')(peerVisit, 'peerVisit');
        this.assertNotNull('updatePeerVisit')(peerVisit.peerVisitFirebaseID, 'peerVisit.peerVisitFirebaseID');
        this.assertNotNull('updatePeerVisit')(previousDateString, 'previousDateString');

        return PeerVisitService.updatePeerVisit(
            peerVisit.peerUserFirebaseID, peerVisit.date.dateString, peerVisit.peerVisitFirebaseID, peerVisit.toFirebasePeerVisit(), previousDateString);
    }

    public async refreshPeerVisitsForPeer(peerUserFirebaseID: string): Promise<void> {
        const peerVisits = await this.getPeerVisitsForPeer(peerUserFirebaseID);
        const peerVisitsByClientID = peerVisits.reduce(
            (byClient, visit) => {
                if (null == visit.clientUserFirebaseID) {
                    return byClient;
                }
                const visitsForClient = byClient.get(visit.clientUserFirebaseID) || [];
                return byClient.set(visit.clientUserFirebaseID, visitsForClient.concat(visit));
            },
            new Map<string, PeerVisit[]>()
        );
        Array.from(peerVisitsByClientID).forEach(([clientID, visits]) => this.peerVisitsByClientID.set(clientID, visits));
    }

}
