import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { cellTextStyle, emailCellStyle, gridCellIconStyle, panelButtonStyle } from '../../assets/CommonStyles';
import { COPY_CONSTANTS } from '../../assets/CopyConstants';
import { STYLE_CLASSES } from '../../assets/StyleConstants';
import { Client } from '../../data/models/Client';
import { Date } from '../../data/models/Date';
import { ApplicationStore } from '../../data/stores/ApplicationStore';
import { CHECKMARK_ICON, VIEW_ALL_ICON, X_ICON } from '../../images/images';
import { ROUTES } from '../../Routes';
import { ClientLink } from '../components/ClientLink';
import { ClientLinkWithArrow } from '../components/ClientLinkWithArrow';
import { ClientLinkWithFlag } from '../components/ClientLinkWithFlag';
import { LastCheckInText } from '../components/LastCheckInText';
import { ListPanel } from '../components/ListPanel';
import { PeerPage } from '../components/PeerPage';
import { StreakLengthText } from '../components/StreakLengthText';


interface Props extends RouteComponentProps<{}> {
    store?: ApplicationStore;
}

interface State {
    searchText: string | undefined;
}

@inject('store')
@observer
export class PeerDashboard extends React.Component<Props, State> {

    public state: State = {
        searchText: undefined
    };

    private handleSearchTextChanged = (searchText: string) => this.setState({ searchText });

    public render(): JSX.Element | null {

        return (
            <PeerPage
                title={COPY_CONSTANTS.dashboard}
                onSearchTextChanged={this.handleSearchTextChanged}
                searchText={this.state.searchText}
            >

                <ListPanel
                    title={COPY_CONSTANTS.flagged}
                    columnWidths={['15%', '70%', '12%', '3%']}
                    columnNames={[
                        COPY_CONSTANTS.name,
                        COPY_CONSTANTS.email,
                        COPY_CONSTANTS.lastCheckIn,
                        ''
                    ]}
                    rowItems={this.getFlaggedRowItems()}
                    minimumRowCount={3}
                    maxHeight={120}
                />

                <ListPanel
                    title={COPY_CONSTANTS.clients}
                    titleRowButtons={[
                        <Link
                            key={'view_all'}
                            style={panelButtonStyle}
                            className={STYLE_CLASSES.BUTTON_PANEL}
                            to={ROUTES.clients}
                        >
                            {COPY_CONSTANTS.viewAll}
                            <img src={VIEW_ALL_ICON} style={{ marginLeft: '5px' }} />
                        </Link>
                    ]}
                    columnWidths={['15%', '58%', '12%', '12%', '3%']}
                    columnNames={[
                        COPY_CONSTANTS.name,
                        COPY_CONSTANTS.email,
                        COPY_CONSTANTS.checkedIn,
                        COPY_CONSTANTS.streak,
                        ''
                    ]}
                    rowItems={this.getTopClientsRowItems()}
                    minimumRowCount={10}
                />
            </PeerPage>
        );

    }

    private get clientsMatchingSearch(): Client[] {
        const { activeClients } = this.props.store!.userStore;
        if (null != this.state.searchText) {
            const searchText = this.state.searchText.toLocaleLowerCase();
            return activeClients.filter(client => client.doesMatchSearch(searchText));
        }
        return activeClients;
    }

    private getFlaggedRowItems(): JSX.Element[][] {
        const { activeClients } = this.props.store!.userStore;
        if (activeClients.length <= 0) {
            return this.getEmptyFlaggedRow(COPY_CONSTANTS.noFlaggedClientsMessage);
        }
        const flaggedMatchingClients = this.clientsMatchingSearch.filter(client => client.isFlagged);
        if (flaggedMatchingClients.length <= 0) {
            return this.getEmptyFlaggedRow(COPY_CONSTANTS.noFlaggedClientsMessage);
        }
        return flaggedMatchingClients.map(client => [
            <ClientLinkWithFlag key={client.firebaseUid} client={client} />,
            <ClientLink style={emailCellStyle} key="email" client={client}>{client.emailAddress}</ClientLink>,
            <LastCheckInText key={client.firebaseUid} client={client} />,
            <ClientLinkWithArrow key={client.firebaseUid} client={client} />
        ]);
    }

    private getTopClientsRowItems(): JSX.Element[][] {
        const { activeClients } = this.props.store!.userStore;
        if (activeClients.length <= 0) {
            return this.getEmptyClientsRow(COPY_CONSTANTS.noClientsMessage);
        }
        if (this.clientsMatchingSearch.length <= 0) {
            return this.getEmptyClientsRow(COPY_CONSTANTS.noClientsSearchMessage);
        }
        return this.clientsMatchingSearch.slice(0, 10).map(client => [
            <ClientLinkWithFlag key={client.firebaseUid} client={client}>{client.firstName}</ClientLinkWithFlag>,
            <ClientLink style={emailCellStyle} key="email" client={client}>{client.emailAddress}</ClientLink>,
            <img key={client.firebaseUid} style={gridCellIconStyle} src={client.reflectionsByDate.has(Date.createForToday().dateString) ? CHECKMARK_ICON : X_ICON} />,
            <StreakLengthText key={client.firebaseUid} client={client} />,
            <ClientLinkWithArrow key={client.firebaseUid} client={client} />
        ]);
    }

    private getEmptyClientsRow(text: string): JSX.Element[][] {
        return [[<span key="empty" />, <p key="none" style={cellTextStyle}>{text}</p>, <span key="empty" />, <span key="empty" />]];
    }

    private getEmptyFlaggedRow(text: string): JSX.Element[][] {
        return [[<span key="empty" />, <p key="none" style={cellTextStyle}>{text}</p>, <span key="empty" />]];
    }
}
