import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { CSSProperties } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { COPY_CONSTANTS } from '../../assets/CopyConstants';
import { STYLE_CLASSES, STYLE_CONSTANTS } from '../../assets/StyleConstants';
import { Peer } from '../../data/models/Peer';
import { PortalUser } from '../../data/models/PortalUser';
import { ApplicationStore } from '../../data/stores/ApplicationStore';
import { CLIENTS_OFF_ICON, CLIENTS_ON_ICON, DASHBOARD_OFF_ICON, DASHBOARD_ON_ICON, LEFT_NAV_BACKGROUND, LOGO, TIME_LOG_OFF_ICON, TIME_LOG_ON_ICON, EMAIL_ICON, STATS_ON_ICON, STATS_OFF_ICON } from '../../images/images';
import { NavSection, ROUTES } from '../../Routes';
import styled from '../../styled';
import { Founder } from '../../data/models/Founder';

interface Props extends RouteComponentProps<{}> {
    store?: ApplicationStore;
    navSection: NavSection;
}

interface State {
    currentUser?: PortalUser;
}

@inject('store')
@observer
export class LeftNavBar extends React.Component<Props, State> {

    public state: State = {
        currentUser: undefined
    };

    public async componentWillMount(): Promise<void> {

        if (null == this.props.store) {
            throw new Error('LeftNavBar.componentWillMount: no store');
        }

        const currentUser = await this.props.store.userStore.getCurrentUser();

        this.setState({
            currentUser: currentUser
        });
    }

    private renderNavLink(
        route: string, label: string, iconOnBackgroundStyle: CSSProperties, iconOffBackgroundStyle: CSSProperties, navSection: NavSection, isExternal?: boolean): JSX.Element {

        const isCurrentNavSection = (navSection === this.props.navSection);

        const iconBackgroundStyle =
            isCurrentNavSection
                ? iconOnBackgroundStyle
                : iconOffBackgroundStyle;

        return (
            <div style={linkRowStyle} key={label}>
                <div style={{ ...linkIconStyle, ...iconBackgroundStyle }}>
                    &nbsp;
                </div>
                {isExternal ?
                    <a style={navOffTextStyle} href={route}>{label}</a>
                    :
                    <Link
                        to={route}
                        style={
                        isCurrentNavSection
                        ? navOnTextStyle
                        : navOffTextStyle
                    }
                    >
                        <div>
                            {label}
                        </div>
                    </Link>
                }
            </div>
        );
    }

    public render(): JSX.Element {

        const navLinks: JSX.Element[] = [];
        navLinks.push(this.renderNavLink(ROUTES.dashboard, COPY_CONSTANTS.dashboard, dashboardOnIconStyle, dashboardOffIconStyle, NavSection.Dashboard));

        if (undefined !== this.state.currentUser && this.state.currentUser instanceof Peer) {
            navLinks.push(this.renderNavLink(ROUTES.clients, COPY_CONSTANTS.clients, clientsOnIconStyle, clientsOffIconStyle, NavSection.Clients));
            navLinks.push(this.renderNavLink(ROUTES.timeLog, COPY_CONSTANTS.timeLog, timeLogOnIconStyle, timeLogOffIconStyle, NavSection.TimeLog));
            navLinks.push(this.renderNavLink(COPY_CONSTANTS.contactSupportURL, COPY_CONSTANTS.contactSupportLink, contactSupportIconStyle, contactSupportIconStyle, NavSection.TimeLog, true));
        } else if (undefined !== this.state.currentUser && this.state.currentUser instanceof Founder) {
            navLinks.push(this.renderNavLink(ROUTES.stats, COPY_CONSTANTS.stats, statsOnIconStyle, statsOffIconStyle, NavSection.Stats));
            navLinks.push(this.renderNavLink(ROUTES.facilities, COPY_CONSTANTS.facilities, facilitiesOnIconStyle, facilitiesOffIconStyle, NavSection.Facilities));  
            navLinks.push(this.renderNavLink(ROUTES.peers, COPY_CONSTANTS.peers, peersOnIconStyle, peersOffIconStyle, NavSection.Peers));
        }

        return (
            <Container className={this.props.store!.isModalShowing ? STYLE_CLASSES.DIV_BLUR_NAV_BAR : undefined}>
                <div style={appNameStyle}>
                    &nbsp;
                    </div>
                {navLinks}

                <div style={policiesStyle}>
                    <StyledLink to={ROUTES.privacyPolicy} target="_blank">
                        {COPY_CONSTANTS.privacyPolicyLink}
                    </StyledLink>
                    <div style={buildLabelStyle}>

                        Build: Feb 27, 2020 #3<br />

                        Pointing at {process.env.REACT_APP_FIREBASE_ENV}
                    </div>
                </div>

            </Container>
        );
    }
}

const StyledLink = styled(Link)`
&:visited {
    color: ${STYLE_CONSTANTS.mediumLightGray}
}`;

export const Container = styled.div`
    flex: 1;
    padding: 50px 50px 0 50px;
    padding-top: 0;
    flex-direction: column;
    background-image: url(${LEFT_NAV_BACKGROUND}), linear-gradient(to top right, #4a74dd , #b364d5);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center bottom;
    position: relative;
`;

const appNameStyle: CSSProperties = {
    backgroundImage: `url(${LOGO})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center left',
    width: '170px',
    height: '150px',
};

const buildLabelStyle: CSSProperties = {
    fontSize: STYLE_CONSTANTS.fontSizeSmall,
    color: STYLE_CONSTANTS.white,
    textDecoration: 'none'
};

const navOffTextStyle: CSSProperties = {
    fontSize: STYLE_CONSTANTS.fontSizeMedium,
    color: STYLE_CONSTANTS.white,
    textDecoration: 'none'
};

const navOnTextStyle: CSSProperties = {
    ...navOffTextStyle,
    fontWeight: 'bold'
};

const linkRowStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '40px'
};

const linkIconStyle: CSSProperties = {
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    width: '22px',
    marginRight: '15px'
};

const dashboardOnIconStyle: CSSProperties = {
    backgroundImage: `url(${DASHBOARD_ON_ICON})`,
    height: '20px'
};

const dashboardOffIconStyle: CSSProperties = {
    backgroundImage: `url(${DASHBOARD_OFF_ICON})`,
    height: '20px'
};

const statsOnIconStyle: CSSProperties = {
    backgroundImage: `url(${STATS_ON_ICON})`,
    height: '24px'
};

const statsOffIconStyle: CSSProperties = {
    backgroundImage: `url(${STATS_OFF_ICON})`,
    height: '24px'
};

const clientsOnIconStyle: CSSProperties = {
    backgroundImage: `url(${CLIENTS_ON_ICON})`,
    height: '24px'
};

const clientsOffIconStyle: CSSProperties = {
    backgroundImage: `url(${CLIENTS_OFF_ICON})`,
    height: '24px'
};

const timeLogOnIconStyle: CSSProperties = {
    backgroundImage: `url(${TIME_LOG_ON_ICON})`,
    height: '22px'
};

const timeLogOffIconStyle: CSSProperties = {
    backgroundImage: `url(${TIME_LOG_OFF_ICON})`,
    height: '22px'
};

const facilitiesOnIconStyle: CSSProperties = {
    backgroundImage: `url(${TIME_LOG_ON_ICON})`,
    height: '22px'
};

const facilitiesOffIconStyle: CSSProperties = {
    backgroundImage: `url(${TIME_LOG_OFF_ICON})`,
    height: '22px'
};
const peersOnIconStyle: CSSProperties = {
    backgroundImage: `url(${TIME_LOG_ON_ICON})`,
    height: '22px'
};

const peersOffIconStyle: CSSProperties = {
    backgroundImage: `url(${TIME_LOG_OFF_ICON})`,
    height: '22px'
};


const contactSupportIconStyle: CSSProperties = {
    backgroundImage: `url(${EMAIL_ICON})`,
    height: '18px'
};

const policiesStyle: CSSProperties = {
    position: 'fixed',
    bottom: '50px'
};
