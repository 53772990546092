import { observe, when } from 'mobx';
import { inject, observer } from 'mobx-react';
import * as moment from 'moment';
import * as React from 'react';
import { CSSProperties } from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router';
import { COPY_CONSTANTS } from '../assets/CopyConstants';
import { STYLE_CLASSES, STYLE_CONSTANTS } from '../assets/StyleConstants';
import { UserType } from '../data/models/UserType';
import { ApplicationStore } from '../data/stores/ApplicationStore';
import { AuthorizationStore } from '../data/stores/AuthorizationStore';
import { NavSection, ROUTES } from '../Routes';
import { ConfirmationModal } from './components/ConfirmationModal';
import { LeftNavBar } from './components/LeftNavBar';
import { ClientDetail } from './pages/ClientDetail';
import { Clients } from './pages/Clients';
import { Dashboard } from './pages/Dashboard';
import { Stats } from './pages/Stats';
import { FacilityDetail } from './pages/FacilityDetail';
import { PeerDetail } from './pages/PeerDetail';
import { TimeLog } from './pages/TimeLog';
import { Facilities } from './pages/Facilities';
import { Peers } from './pages/Peers';
import { OrgDetail } from './pages/OrgDetail';

interface Props extends RouteComponentProps<{}> {
    store: ApplicationStore;
}

interface State {
    inactivityWarningDisplayedTime?: moment.Moment;
    isAlreadyShowingModal: boolean;
}

@inject('store')
@observer
export class NavigationController extends React.Component<Props, State> {

    private static INACTIVITY_CHECK_INTERVAL_MILLISECONDS: number = 10 * 1000;
    private static INACTIVITY_WARN_AFTER_MILLISECONDS: number = 30 * 60 * 1000;
    private static INACTIVITY_WARNING_DURATION_MILLISECONDS: number = 60 * 1000;

    private lastUserInteractionTime = moment();
    private inactivityCheckTimer: any;

    public constructor(props: Props) {
        super(props);

        this.state = {
            inactivityWarningDisplayedTime: undefined,
            isAlreadyShowingModal: false
        };

        when(
            () => {
                const autoAuthComplete = this.props.store.authorizationStore.autoLogOnComplete;
                const notLoggedIn = (false === this.props.store.authorizationStore.isAuthenticated);
                return autoAuthComplete && notLoggedIn;
            },
            () => {
                this.routeToLogIn();
            }
        );

        this.updatePeerOrClientListeners(this.props.store.authorizationStore);

        observe(this.props.store.authorizationStore, async change => {
            this.updatePeerOrClientListeners(change.object);
        });
    }

    public componentDidMount(): void {

        document.addEventListener('keydown', this.handleUserActivity);
        document.addEventListener('mousedown', this.handleUserActivity);
        this.inactivityCheckTimer = setInterval(() => this.checkForInactivity(), NavigationController.INACTIVITY_CHECK_INTERVAL_MILLISECONDS);
    }

    public componentWillUnmount(): void {

        document.removeEventListener('keydown', this.handleUserActivity);
        document.removeEventListener('mousedown', this.handleUserActivity);
        clearInterval(this.inactivityCheckTimer);

        this.props.store.userStore.unlistenClients();
    }

    public handleUserActivity = () => {
        this.lastUserInteractionTime = moment();
    }

    private async updatePeerOrClientListeners(authStore: AuthorizationStore): Promise<void> {
        try {
            const user = await this.props.store.userStore.getCurrentUser();
            if (authStore.isAuthenticated && user.userType === UserType.Peer) {
                this.props.store.userStore.unlistenPeers();
                this.props.store.userStore.listenClients();
            } else if (authStore.isAuthenticated) {
                this.props.store.userStore.unlistenClients();
                this.props.store.userStore.listenPeers();
            } else {
                this.props.store.userStore.unlistenClients();
                this.props.store.userStore.unlistenPeers();
            }
        } catch { }
    }

    private checkForInactivity(): void {

        const now = moment();

        if (undefined === this.state.inactivityWarningDisplayedTime) {
            const millisecondsSinceLastActivity = now.diff(this.lastUserInteractionTime);
            if (millisecondsSinceLastActivity >= NavigationController.INACTIVITY_WARN_AFTER_MILLISECONDS) {
                this.setState({ inactivityWarningDisplayedTime: now, isAlreadyShowingModal: this.props.store.isModalShowing });
            }
        } else {
            const millisecondsInactivityWarningDisplayed = now.diff(this.state.inactivityWarningDisplayedTime);
            if (millisecondsInactivityWarningDisplayed >= NavigationController.INACTIVITY_WARNING_DURATION_MILLISECONDS) {
                this.props.store.authorizationStore.logOut();
            }
        }
    }

    private stayLoggedIn(): void {
        this.setState({ inactivityWarningDisplayedTime: undefined });
    }

    private routeToLogIn(): void {
        this.props.history.push(ROUTES.login);
    }

    private get currentNavSection(): NavSection {

        const pathName = this.props.location.pathname;
        let navSection: NavSection = NavSection.Dashboard;

        if (pathName.includes(ROUTES.clients) || pathName.includes(ROUTES.client)) {
            navSection = NavSection.Clients;
        } else if (pathName.includes(ROUTES.timeLog)) {
            navSection = NavSection.TimeLog;
        } else if (pathName.includes(ROUTES.stats)) {
            navSection = NavSection.Stats;
        } else if (pathName.includes(ROUTES.facilities) ||
            pathName.includes(ROUTES.facility) ||
            pathName.includes(ROUTES.org)) {
            navSection = NavSection.Facilities;
        } else if (pathName.includes(ROUTES.peers) || 
            pathName.includes(ROUTES.peer)) {
            navSection = NavSection.Peers;
        }

        return navSection;
    }

    public render(): JSX.Element | null {

        if (null == this.props.store || false === this.props.store.storeIsReady) {
            return null;
        }

        return (
            <div style={containerStyle}>


                <LeftNavBar
                    navSection={this.currentNavSection}
                    {...this.props}
                />

                <div style={mainContentStyle}>
                    <Switch>

                        <Route exact={true} path={ROUTES.dashboard} component={Dashboard} />
                        <Route exact={true} path={ROUTES.stats} component={Stats} />

                        <Route exact={true} path={ROUTES.facilities} component={Facilities} />
                        <Route exact={true} path={ROUTES.peers} component={Peers} />
                        

                        <Route exact={true} path={ROUTES.facility + '/:id'} component={FacilityDetail} />
                        <Route exact={true} path={ROUTES.peer + '/:id'} component={PeerDetail} />
                        <Route exact={true} path={ROUTES.org + '/:id'} component={OrgDetail} />

                        <Route exact={true} path={ROUTES.clients} component={Clients} />
                        <Route exact={true} path={ROUTES.client + '/:id'} component={ClientDetail} />
                        <Route exact={true} path={ROUTES.timeLog} component={TimeLog} />

                        <Redirect exact={true} path="/" to={ROUTES.dashboard} />

                    </Switch>
                </div>

                <ConfirmationModal
                    isVisible={undefined !== this.state.inactivityWarningDisplayedTime}
                    title={COPY_CONSTANTS.inactivityWarningTitle}
                    message={COPY_CONSTANTS.inactivityWarningMessage}
                    confirmButtonText={COPY_CONSTANTS.yes}
                    confirmButtonStyleClassName={STYLE_CLASSES.BUTTON_GREEN}
                    cancelButtonStyleClassName={STYLE_CLASSES.BUTTON_MEDIUM_LIGHT_GRAY}
                    onConfirm={() => this.stayLoggedIn()}
                    isInsideAnotherModal={this.state.isAlreadyShowingModal}
                    isFullScreen={true}
                />
            </div>
        );
    }
}

const containerStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'row'
};

const mainContentStyle: CSSProperties = {
    flex: 5,
    backgroundColor: STYLE_CONSTANTS.lightGray,
    minHeight: '100vh'
};
