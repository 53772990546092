import { inject, observer } from 'mobx-react';
import * as moment from 'moment';
import * as React from 'react';
import { CSSProperties } from 'react';
import { closeRightColumnFieldStyle, lightGreenBigButtonStyle, rightColumnFieldStyle, rowStyle } from '../../assets/CommonStyles';
import { COPY_CONSTANTS } from '../../assets/CopyConstants';
import { STYLE_CLASSES } from '../../assets/StyleConstants';
import { Client } from '../../data/models/Client';
import { Date } from '../../data/models/Date';
import { PeerVisit } from '../../data/models/PeerVisit';
import { PeerVisitCategory } from '../../data/models/PeerVisitCategory';
import { ApplicationStore } from '../../data/stores/ApplicationStore';
import { buildCsvFile, buildCsvRow, ItemKeyAndDisplayValue } from '../../util/common';
import { CustomButton } from './Button';
import { FormDateField } from './FormDateField';
import { FormDropdownField } from './FormDropdownField';
import { Modal } from './Modal';

interface Props {
    store?: ApplicationStore;
    isVisible: boolean;
    peerUserFirebaseID: string;
    onComplete: () => void;
}

interface State {
    clientsByIDFromPeerVisits: Map<string, Client> | undefined;
    peerVisits: PeerVisit[] | undefined;
    dropdownClientUserFirebaseIDs: string[];
    areAllClientsSelected: boolean;
    startDate: Date | undefined;
    endDate: Date;
    isInvalid: boolean;
}

const ALL_CLIENTS_SELECTION_KEY = 'ALL_CLIENTS_SELECTION_KEY';
const NON_CLIENT_SELECTION_KEY = 'NON_CLIENT_SELECTION_KEY';
const EXPORT_ANCHOR_ID = 'EXPORT_ANCHOR';

@inject('store')
@observer
export class PeerVisitExportModal extends React.Component<Props, State> {

    private readonly CLASS_NAME = 'PeerVisitExportModal';

    public state: State = this.initialState;

    private get initialState(): State {

        return {
            clientsByIDFromPeerVisits: undefined,
            peerVisits: undefined,
            dropdownClientUserFirebaseIDs: [''],
            areAllClientsSelected: false,
            startDate: undefined,
            endDate: Date.fromMoment(moment()),
            isInvalid: false
        };
    }

    private get selectedClientUserFirebaseIDs(): string[] {

        return this.state.dropdownClientUserFirebaseIDs
            .filter((clientUserFirebaseID: string) => clientUserFirebaseID.length > 0);
    }

    public componentDidUpdate(prevProps: Props): void {
        if (prevProps !== this.props) {
            this.refreshClientsAndVisits();
        }
    }

    private async refreshClientsAndVisits(): Promise<void> {
        const peerVisits = await this.props.store!.peerVisitStore.getPeerVisitsForPeer(this.props.peerUserFirebaseID);

        const clientsByIDFromPeerVisits = await this.props.store!.userStore.getClientsByIDFromPeerVisits(peerVisits);

        this.setState({
            clientsByIDFromPeerVisits: clientsByIDFromPeerVisits,
            peerVisits: peerVisits
        });
    }

    private get allClientIDsAndDisplayNames(): ItemKeyAndDisplayValue[] {

        if (undefined === this.state.clientsByIDFromPeerVisits) {
            return [];
        }

        return Array.from(this.getAllClientIDs())
            .map((clientUserFirebaseID: string) => {
                const client = this.getClient(clientUserFirebaseID)!;
                return {
                    key: clientUserFirebaseID,
                    display: client.nameAndEmail
                };
            });
    }

    private export(): void {

        if (false === this.validate()) {
            return;
        }

        document.getElementById(EXPORT_ANCHOR_ID)!.click();
    }

    private validate(): boolean {

        if (
            '' !== this.getClientValidationError()
            || '' !== this.getDateValidationError(this.state.startDate)
            || '' !== this.getDateValidationError(this.state.endDate)
        ) {

            this.setState({
                isInvalid: true
            });

            return false;
        }

        return true;
    }

    private getClientValidationError(): string {

        if (false === this.state.areAllClientsSelected && 0 === this.selectedClientUserFirebaseIDs.length) {
            return COPY_CONSTANTS.requiredField;
        }

        return '';
    }

    private getDateValidationError(date: Date | undefined): string {

        if (undefined === date) {
            return COPY_CONSTANTS.requiredField;
        }

        return this.getDateRangeValidationError();
    }

    private getDateRangeValidationError(): string {

        if (this.state.startDate && this.state.endDate && this.state.endDate.moment.isBefore(this.state.startDate.moment)) {
            return COPY_CONSTANTS.endDateEarlierThanStartDate;
        }

        return '';
    }

    private close(): void {

        this.props.onComplete();
        this.setState(this.initialState);
    }

    private get allClientsSelectionItem(): ItemKeyAndDisplayValue {

        return {
            key: ALL_CLIENTS_SELECTION_KEY,
            display: COPY_CONSTANTS.selectAll
        };
    }

    private get nonClientSelectionItem(): ItemKeyAndDisplayValue {

        return {
            key: NON_CLIENT_SELECTION_KEY,
            display: COPY_CONSTANTS.nonClient
        };
    }

    private filterSelectedClients(clientSelectItems: ItemKeyAndDisplayValue[], clientUserFirebaseID: string | undefined): ItemKeyAndDisplayValue[] {

        return clientSelectItems.filter((idAndName: ItemKeyAndDisplayValue) =>
            clientUserFirebaseID === idAndName.key
            || this.selectedClientUserFirebaseIDs.indexOf(idAndName.key) < 0
        );
    }

    private getClient(clientUserFirebaseID: string): Client | undefined {
        const client = this.props.store!.userStore.activeClientsForPeerByID.get(clientUserFirebaseID);
        if (undefined != client) {
            return client;
        }
        if (undefined == this.state.clientsByIDFromPeerVisits) {
            return undefined;
        }
        return this.state.clientsByIDFromPeerVisits.get(clientUserFirebaseID);
    }

    private getAllClientIDs(): string[] {

        if (undefined == this.state.clientsByIDFromPeerVisits) {
            return Array.from(this.props.store!.userStore.activeClientsForPeerByID.keys());
        }

        const clientIDs: Set<string> = new Set<string>(this.props.store!.userStore.activeClientsForPeerByID.keys());
        if (this.state.clientsByIDFromPeerVisits) {
            Array.from(this.state.clientsByIDFromPeerVisits.keys()).forEach((peerVisitClientID: string) => {
                if (false === clientIDs.has(peerVisitClientID)) {
                    clientIDs.add(peerVisitClientID);
                }
            });
        }
        return Array.from(clientIDs);
    }

    private generateCsv(): string {

        if (this.state.isInvalid
            || undefined === this.state.startDate
            || (false === this.state.areAllClientsSelected && 0 === this.selectedClientUserFirebaseIDs.length)
            || undefined === this.state.clientsByIDFromPeerVisits
            || undefined === this.state.peerVisits
        ) {
            return '';
        }

        let rows: string[] = [];

        rows.push(
            buildCsvRow(['Client Name', 'Client Email', 'Category', 'Date', 'Time', 'Notes'])
        );

        const selectedClientUserFirebaseIDs = new Set<string>(this.selectedClientUserFirebaseIDs);

        const peerVisitsToExport = this.state.peerVisits.filter((peerVisit: PeerVisit) => {
            const dateMoment = peerVisit.date.moment;
            if (
                dateMoment.isBefore(this.state.startDate!.moment)
                || dateMoment.isAfter(this.state.endDate.moment)
            ) {
                return false;
            }

            return (
                this.state.areAllClientsSelected
                || selectedClientUserFirebaseIDs.has(peerVisit.clientUserFirebaseID || NON_CLIENT_SELECTION_KEY)
            );
        });

        peerVisitsToExport
            .sort((a: PeerVisit, b: PeerVisit) =>
                a.compareToForDescendingDateSort(b))
            .forEach((peerVisit: PeerVisit) => {
                const client = this.getClient(peerVisit.clientUserFirebaseID!)!;
                if (undefined !== peerVisit.clientUserFirebaseID && undefined === client) {
                    return; // todo: handle removed clients
                }
                rows.push(
                    buildCsvRow([
                        undefined !== peerVisit.clientUserFirebaseID
                            ? client.firstName || ''
                            : COPY_CONSTANTS.none,
                        undefined !== peerVisit.clientUserFirebaseID
                            ? client.emailAddress || ''
                            : COPY_CONSTANTS.none,
                        PeerVisitCategory[peerVisit.category],
                        peerVisit.date.displayString,
                        peerVisit.durationDisplay,
                        peerVisit.notes
                    ])
                );
            });

        return buildCsvFile(rows);
    }

    private renderClientDropdowns(): JSX.Element[] {

        let dropdowns: JSX.Element[];

        const clientSelectItems =
            this.state.clientsByIDFromPeerVisits
                ? [this.allClientsSelectionItem, this.nonClientSelectionItem, ...this.allClientIDsAndDisplayNames]
                : [];

        if (this.state.areAllClientsSelected) {

            dropdowns = [this.renderAllClientsSelectedDropdown(clientSelectItems)];

        } else {

            let clientValidationError = this.getClientValidationError();
            let validationErrorShown = false;

            dropdowns = this.state.dropdownClientUserFirebaseIDs.map((clientUserFirebaseID: string, index: number) => {

                let dropdown;

                if (0 === clientUserFirebaseID.length) {
                    dropdown = this.renderAddAnotherClientDropdown(clientSelectItems, index, clientValidationError, validationErrorShown);
                    validationErrorShown = true;
                } else {
                    dropdown = this.renderClientSelectedDropdown(clientSelectItems, clientUserFirebaseID);
                }

                return dropdown;
            });
        }

        return dropdowns;
    }

    private renderAllClientsSelectedDropdown(clientSelectItems: ItemKeyAndDisplayValue[]): JSX.Element {

        return (
            <FormDropdownField
                key={'all'}
                label={COPY_CONSTANTS.selectClientOrAll}
                items={clientSelectItems}
                selectedItemKey={ALL_CLIENTS_SELECTION_KEY}
                initialSelectedItemKey={''}
                onValueChanged={(newValue: string) => {
                    let selectedClientUserFirebaseIDs: string[] = [];
                    if ('' !== newValue) {
                        selectedClientUserFirebaseIDs.push(newValue);
                    }
                    this.setState({
                        areAllClientsSelected: false,
                        dropdownClientUserFirebaseIDs: selectedClientUserFirebaseIDs
                    });
                }}
                validationError={''}
                forceDisplayValidationError={this.state.isInvalid}
            />
        );
    }

    private renderClientSelectedDropdown(clientSelectItems: ItemKeyAndDisplayValue[], selectedClientUserFirebaseID: string): JSX.Element {
        return (
            <FormDropdownField
                key={selectedClientUserFirebaseID}
                label={COPY_CONSTANTS.selectClientOrAll}
                items={this.filterSelectedClients(clientSelectItems, selectedClientUserFirebaseID)}
                selectedItemKey={selectedClientUserFirebaseID}
                initialSelectedItemKey={''}
                onValueChanged={(newValue: string, oldValue: string) => {
                    let areAllClientsSelected = this.state.areAllClientsSelected;
                    let dropdownClientUserFirebaseIDs = [...this.state.dropdownClientUserFirebaseIDs];
                    if (ALL_CLIENTS_SELECTION_KEY === newValue) {
                        areAllClientsSelected = true;
                        dropdownClientUserFirebaseIDs = [];
                    } else {
                        const index = dropdownClientUserFirebaseIDs.indexOf(oldValue);
                        dropdownClientUserFirebaseIDs[index] = newValue;
                    }
                    this.setState({
                        areAllClientsSelected: areAllClientsSelected,
                        dropdownClientUserFirebaseIDs: dropdownClientUserFirebaseIDs
                    });
                }}
                validationError={''}
                forceDisplayValidationError={this.state.isInvalid}
            />
        );
    }

    private renderAddAnotherClientDropdown(clientSelectItems: ItemKeyAndDisplayValue[], index: number, validationError: string, validationErrorShownAlready: boolean): JSX.Element {
        return (
            <FormDropdownField
                key={`add_another_${index}`}
                label={COPY_CONSTANTS.selectClientOrAll}
                items={this.filterSelectedClients(clientSelectItems, undefined)}
                selectedItemKey={''}
                initialSelectedItemKey={''}
                onValueChanged={(newValue: string) => {
                    let areAllClientsSelected = false;
                    let dropdownClientUserFirebaseIDs = [...this.state.dropdownClientUserFirebaseIDs];
                    if (ALL_CLIENTS_SELECTION_KEY === newValue) {
                        areAllClientsSelected = true;
                        dropdownClientUserFirebaseIDs = [];
                    } else {
                        dropdownClientUserFirebaseIDs[index] = newValue;
                    }
                    this.setState({
                        areAllClientsSelected: areAllClientsSelected,
                        dropdownClientUserFirebaseIDs: dropdownClientUserFirebaseIDs
                    });
                }}
                validationError={validationErrorShownAlready ? '' : validationError}
                forceDisplayValidationError={this.state.isInvalid}
            />
        );
    }

    public render(): JSX.Element | null {

        if (null == this.props.store) {
            throw new Error(`${this.CLASS_NAME}: no store`);
        }

        return (
            <Modal
                isVisible={this.props.isVisible && undefined !== this.state.clientsByIDFromPeerVisits && undefined !== this.state.peerVisits}
                title={COPY_CONSTANTS.export}
                primaryButtonText={COPY_CONSTANTS.export}
                onPrimaryButtonClick={() => this.export()}
                onCloseButtonClick={() => this.close()}
            >

                <div style={rowStyle}>

                    <div style={clientDropdownsStyle}>

                        {this.renderClientDropdowns()}

                        {false === this.state.areAllClientsSelected
                            && this.state.clientsByIDFromPeerVisits
                            && this.selectedClientUserFirebaseIDs.length < this.getAllClientIDs().length + 1 // plus one is due to Non-Client
                            &&
                            <CustomButton
                                style={lightGreenBigButtonStyle}
                                styleClassName={STYLE_CLASSES.BUTTON_LIGHT_GREEN}
                                onClick={() => this.setState({
                                    dropdownClientUserFirebaseIDs: [...this.state.dropdownClientUserFirebaseIDs, '']
                                })}
                            >
                                <div style={plusSignStyle}>+</div>

                                <p>{COPY_CONSTANTS.addClient}</p>

                            </CustomButton>
                        }

                    </div>

                    <FormDateField
                        style={rightColumnFieldStyle}
                        label={COPY_CONSTANTS.startDate}
                        value={this.state.startDate}
                        initialValue={undefined}
                        onValueChanged={(newValue: Date) =>
                            this.setState({
                                startDate: newValue
                            })
                        }
                        validationError={this.getDateValidationError(this.state.startDate)}
                        forceDisplayValidationError={this.state.isInvalid}
                    />

                    <FormDateField
                        style={closeRightColumnFieldStyle}
                        label={COPY_CONSTANTS.endDate}
                        value={this.state.endDate}
                        initialValue={Date.fromMoment(moment())}
                        onValueChanged={(newValue: Date) =>
                            this.setState({
                                endDate: newValue
                            })
                        }
                        validationError={this.getDateValidationError(this.state.endDate)}
                        forceDisplayValidationError={this.state.isInvalid}
                    />

                </div>

                <a
                    id={EXPORT_ANCHOR_ID}
                    download={COPY_CONSTANTS.peerVisitExportFileName}
                    href={`data:application/octet-stream,${this.generateCsv()}`}
                />

            </Modal>
        );
    }

}

const clientDropdownsStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
};

const plusSignStyle: CSSProperties = {
    fontSize: '36px',
    lineHeight: '30px',
    height: '36px',
    width: '36px',
    margin: 0,
    marginRight: '5px',
    alignSelf: 'center',
    display: 'block'
};
