import * as firebase from 'firebase';
import { assertNotNull, assertTypeof } from '../../util/assert';
import { IFirebaseOrg, IFirebaseOrgs } from '../models/firebase/FirebaseOrg';
import { FIREBASE_CONSTANTS } from '../../assets/FirebaseConstants';

const ORGS_PATH = `${FIREBASE_CONSTANTS.environment}/orgs`;

const CLASS_NAME = 'OrgService';

export class OrgService {

    private static readonly assertNotNull = assertNotNull(CLASS_NAME);
    private static readonly assertTypeof = assertTypeof(CLASS_NAME);

    private static get orgsRef(): firebase.database.Reference {
        return firebase.database().ref(ORGS_PATH);
    }

    private static getOrgRef(firebaseID: string): firebase.database.Reference {
        return firebase.database().ref(`${ORGS_PATH}/${firebaseID}`);
    }

    public static async getOrg(orgFirebaseID: string): Promise<IFirebaseOrg> {

        this.assertNotNull('getOrg')(orgFirebaseID, 'orgFirebaseID');
        this.assertTypeof('getOrg')('string')(orgFirebaseID, 'orgFirebaseID');

        const snapshot =
            await this.getOrgRef(orgFirebaseID)
                .once(FIREBASE_CONSTANTS.value);
        
        if (null == snapshot) {
            throw new Error('getOrg: null snapshot');
        }

        return snapshot.val();
    }

    public static async getAll(): Promise<IFirebaseOrgs> {

        const snapshot =
            await this.orgsRef
                .once(FIREBASE_CONSTANTS.value);

        if (null == snapshot) {
            throw new Error('OrgService.getAll: null snapshot');
        }

        return snapshot.val();
    }

    public static listen(
        onOrgAddedOrChanged: (firebaseID: string, firebaseOrg: IFirebaseOrg) => void,
        onOrgRemoved: (firebaseID: string) => void
    ): void {

        this.assertNotNull('listen')(onOrgAddedOrChanged, 'onOrgAddedOrChanged');
        this.assertNotNull('listen')(onOrgRemoved, 'onOrgRemoved');

        this.orgsRef.off();

        this.orgsRef
            .on(
                FIREBASE_CONSTANTS.eventChildAdded,
                async snapshot =>
                    this.handleOrgAddedOrChanged(snapshot, onOrgAddedOrChanged)
            );

        this.orgsRef
            .on(
                FIREBASE_CONSTANTS.eventChildChanged,
                async snapshot =>
                    this.handleOrgAddedOrChanged(snapshot, onOrgAddedOrChanged)
            );

        this.orgsRef
            .on(
                FIREBASE_CONSTANTS.eventChildRemoved,
                async snapshot =>
                    this.handleOrgRemoved(snapshot, onOrgRemoved)
            );
    }

    public static unlisten(): void {

        this.orgsRef.off();
    }

    private static async handleOrgAddedOrChanged(
        snapshot: firebase.database.DataSnapshot | null,
        onOrgAddedOrChanged: (firebaseID: string, firebaseOrg: IFirebaseOrg) => void
    ): Promise<void> {

        this.assertNotNull('handleOrgAddedOrChanged')(onOrgAddedOrChanged, 'onOrgAddedOrChanged');

        if (null == snapshot) {
            throw new Error('handleOrgAddedOrChanged: null snapshot');
        }

        if (null == snapshot.key) {
            throw new Error('handleOrgAddedOrChanged: null snapshot key');
        }

        const org: IFirebaseOrg = snapshot.val();

        if (null == org) {
            throw new Error('handleOrgAddedOrChanged: null org');
        }

        onOrgAddedOrChanged(snapshot.key, org);
    }

    private static async handleOrgRemoved(
        snapshot: firebase.database.DataSnapshot | null,
        onOrgRemoved: (firebaseID: string) => void
    ): Promise<void> {

        this.assertNotNull('handleOrgRemoved')(onOrgRemoved, 'onOrgRemoved');

        if (null == snapshot) {
            throw new Error('handleOrgRemoved: null snapshot');
        }

        if (null == snapshot.key) {
            throw new Error('handleOrgRemoved: null firebaseID');
        }

        onOrgRemoved(snapshot.key);
    }

    public static async addOrg(firebaseOrg: IFirebaseOrg): Promise<void> {

        this.assertNotNull('addOrg')(firebaseOrg, 'firebaseOrg');

        return this.orgsRef.push({
            ...firebaseOrg,
            createdTimestamp: firebase.database.ServerValue.TIMESTAMP
        });
    }

    public static async updateOrg(firebaseOrgID: string, firebaseOrg: IFirebaseOrg): Promise<void> {

        assertTypeof('OrgService')('updateOrg')('string')(firebaseOrgID, 'firebaseOrgID');
        this.assertNotNull('updateOrg')(firebaseOrg, 'firebaseOrg');

        return this.getOrgRef(firebaseOrgID).set({
            ...firebaseOrg
        });
    }

}
