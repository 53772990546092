import * as firebase from 'firebase';
import * as moment from 'moment';
import { FIREBASE_CONSTANTS } from '../../assets/FirebaseConstants';
import { assertTypeof } from '../../util/assert';
import { IFirebaseClientReflection, IFirebaseClientReflectionWithDate } from '../models/firebase/FirebaseClientReflection';

const REFLECTIONS_PATH = `${FIREBASE_CONSTANTS.environment}/clientReflections`;

export class ClientReflectionService {
    private static readonly assertTypeof = assertTypeof(ClientReflectionService.name);

    private static readonly reflectionsRef: firebase.database.Reference = firebase.database().ref(REFLECTIONS_PATH);

    private static getClientReflectionQueries(clientFirebaseID: string): firebase.database.Query[] {
        const format = 'YYYY-MM-DDThh:mm:ss';
        const cutoff = moment().subtract(31, 'days').format(format);
        const orderedRef = this.reflectionsRef.child(clientFirebaseID).orderByKey();
        const lastMonthQuery = orderedRef.startAt(cutoff);
        const lastAfterLastMonthQuery = orderedRef.endAt(cutoff).limitToLast(1);
        return [lastAfterLastMonthQuery, lastMonthQuery];
    }

    public static listenClientReflections(
        clientFirebaseID: string,
        onReflectionAddedHandler: (
            clientFirebaseID: string,
            firebaseReflectionWithDate: IFirebaseClientReflectionWithDate
        ) => void
    ): void {
        this.assertTypeof('listenClientReflections')('function')(onReflectionAddedHandler, 'onReflectionAddedHandler');
        this.assertTypeof('listenClientReflections')('string')(clientFirebaseID, 'clientFirebaseID');

        const clientReflectionsQueries = this.getClientReflectionQueries(clientFirebaseID);

        clientReflectionsQueries.forEach(query => {
            query.off();

            query.on(
                FIREBASE_CONSTANTS.eventChildAdded,
                //firebase.functions
                snapshot => this.handleReflectionAdded(snapshot, clientFirebaseID, onReflectionAddedHandler)
            );
        });
    }

    private static handleReflectionAdded(
        snapshot: firebase.database.DataSnapshot | null,
        clientFirebaseID: string,
        onReflectionAddedHandler: (
            clientFirebaseID: string,
            firebaseReflectionWithDate: IFirebaseClientReflectionWithDate
        ) => void
    ): void {
        this.assertTypeof('handleReflectionAdded')('function')(onReflectionAddedHandler, 'onReflectionAddedHandler');

        if (null == snapshot) {
            throw new Error('handleReflectionAdded: null snapshot');
        }

        if (null == snapshot.key) {
            throw new Error('handleReflectionAdded: null firebaseID');
        }

        const reflection: IFirebaseClientReflection = snapshot.val();

        if (null == reflection) {
            throw new Error('handleReflectionAdded: null reflection');
        }

        onReflectionAddedHandler(clientFirebaseID, {
            date: snapshot.key,
            reflection
        });
    }
}