import * as React from 'react';
import { STYLE_CONSTANTS } from '../../assets/StyleConstants';
import { CustomButton } from '../components/Button';

interface Props {
    isOpen: boolean;
    options: string[];
    highlightedOption?: string;
    onOptionClicked: (option: string) => void;
    onExternalClick?: () => void;
    style?: React.CSSProperties;
    backgroundColor: string;
    optionStyle: React.CSSProperties;
    optionStyleClassName?: string;
    highlightedOptionStyleClassName?: string;
}


export class FloatingMenu extends React.Component<Props> {

    private menuDiv: HTMLDivElement;

    public componentDidUpdate(prevProps: Props): void {

        if (false === prevProps.isOpen && this.props.isOpen && undefined != this.props.onExternalClick) {
            document.addEventListener('click', this.handleClickOutside);
        } else if (prevProps.isOpen && false === this.props.isOpen) {
            this.removeClickListener();
        }
    }
    
    public componentWillUnmount(): void {
        this.removeClickListener();
    }

    private removeClickListener(): void {
        document.removeEventListener('click', this.handleClickOutside);
    }
    
    private handleClickOutside = (event: any): void => {
        if (
            this.menuDiv && false === this.menuDiv.contains(event.target)
            && this.props.isOpen
            && undefined != this.props.onExternalClick
        ) {
          this.props.onExternalClick();
        }
    }

    public render(): JSX.Element | null {

        if (false === this.props.isOpen) {
            return null;
        }
        
        return (
            <div style={outerContainerStyle}>

                <div
                    ref={(div: HTMLDivElement): void => { this.menuDiv = div; }}
                    style={{...innerContainerStyle, ...this.props.style}}
                >

                    <div style={getPopoverArrowStyle(this.props.backgroundColor)}>
                        &nbsp;
                    </div>

                    <div style={{...optionsBoxStyle, backgroundColor: this.props.backgroundColor }}>
                        {this.props.options.map((option: string) =>

                            <CustomButton
                                style={{...optionStyle, ...this.props.optionStyle}}
                                styleClassName={option === this.props.highlightedOption ? this.props.highlightedOptionStyleClassName : this.props.optionStyleClassName}
                                key={option}
                                onClick={() => this.props.onOptionClicked(option)}
                            >
                                {option}
                            </CustomButton>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

function getPopoverArrowStyle(color: string): React.CSSProperties {
    return {
        width: 0,
        height: 0,
        borderLeft: '10px solid transparent',
        borderRight: '10px solid transparent',
        borderBottom: `10px solid ${color}`,
        margin: 'auto'
    };
}

const outerContainerStyle: React.CSSProperties = {
    position: 'relative'
};

const innerContainerStyle: React.CSSProperties = {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: STYLE_CONSTANTS.transparent,
    padding: '5px',
    justifyContent: 'center'
};

const optionsBoxStyle: React.CSSProperties = {
    borderRadius: '5px'
};

const optionStyle: React.CSSProperties = {
    width: '100%'
};
