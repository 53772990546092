import * as assert from 'assert';
import { when } from 'mobx';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { ChangeEvent, CSSProperties } from 'react';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { UnauthenticatedPageContainer } from '../../assets/CommonStyles';
import { COPY_CONSTANTS } from '../../assets/CopyConstants';
import { FIREBASE_CONSTANTS } from '../../assets/FirebaseConstants';
import { STYLE_CLASSES, STYLE_CONSTANTS } from '../../assets/StyleConstants';
import { Peer } from '../../data/models/Peer';
import { UserType } from '../../data/models/UserType';
import { ApplicationStore } from '../../data/stores/ApplicationStore';
import { EMAIL_ICON, LOGO, PASSWORD_ICON } from '../../images/images';
import { ROUTES } from '../../Routes';
import styled from '../../styled';
import { CustomButton } from '../components/Button';
import { Darkener } from '../components/Darkener';
import { EmbeddedButtonProps, FormTextField } from '../components/FormTextField';
import { PasswordResetModal } from '../components/PasswordResetModal';

interface Props extends RouteComponentProps<{}> {
    store: ApplicationStore;
}

interface State {
    emailAddress: string;
    password: string;
    passwordReEntered: string;
    message: string;
    isResettingPassword: boolean;
    isSigningUp: boolean;
    isTermsModalVisible: boolean;
    hasAcceptedTerms: boolean;
}

@inject('store')
@observer
export class Login extends React.Component<Props, State> {

    public constructor(props: Props) {
        super(props);
        this.state = {
            emailAddress: '',
            password: '',
            passwordReEntered: '',
            message: '',
            isResettingPassword: false,
            isSigningUp: false,
            isTermsModalVisible: false,
            hasAcceptedTerms: false
        };

        assert(null != this.props.store.authorizationStore, 'LogIn#constructor, Authorization store returned undefined');
        when(
            () => {
                return this.props.store.authorizationStore.isAuthenticated;
            },
            () => {
                this.routeToSite();
            });
    }

    public componentWillMount(): void {
        if (this.props.store.authorizationStore.isAuthenticated) {
            this.routeToSite();
        }
    }

    private routeToSite(): void {
        this.props.history.push('');
    }

    private async submit(event: ChangeEvent<HTMLFormElement>): Promise<void> {

        event.preventDefault();

        return (
            this.state.isSigningUp
                ? this.signUp()
                : this.logIn()
        );
    }

    private async signUp(): Promise<void> {

        const emailAddress = this.state.emailAddress;
        const password = this.state.password;
        const passwordReEntered = this.state.passwordReEntered;

        if (password !== passwordReEntered) {
            this.setState({ message: COPY_CONSTANTS.passwordsDoNotMatch });
            return;
        }

        let message: string | null = null;
        if ('' === emailAddress || '' === password || '' === passwordReEntered) {
            message = COPY_CONSTANTS.signupFailedIncomplete;
        } else if (false === this.state.hasAcceptedTerms) {
            message = COPY_CONSTANTS.termsNotAccepted;
        } else {
            try {
                const onboardedStatus: { isOnboarded?: boolean; isClient?: boolean } = await this.props.store.userStore.getUserOnboardedStatus(emailAddress);
                if (undefined === onboardedStatus) {
                    message = COPY_CONSTANTS.signupFailed;
                } else if (onboardedStatus.isClient) {
                    message = COPY_CONSTANTS.signupFailedClient;
                } else if (onboardedStatus.isOnboarded) {
                    message = COPY_CONSTANTS.signupFailedAlreadyOnboarded;
                } else {
                    await this.props.store.authorizationStore.logIn(emailAddress, FIREBASE_CONSTANTS.defaultPassword);
                    await this.props.store.authorizationStore.setInitialPassword(password);
                    const peer = await this.props.store.userStore.getCurrentUser() as Peer;
                    if (peer) {
                        peer.isOnboarded = true;
                        await this.props.store.userStore.upsertUser(peer);
                    }
                }
            } catch (error) {
                message = COPY_CONSTANTS.signupFailed;
            }
        }

        if (null != message) {
            this.setState({ message });
        }
    }

    private async logIn(): Promise<void> {

        const emailAddress = this.state.emailAddress;
        const password = this.state.password;

        let message: string | null = null;

        if ('' === emailAddress || '' === password) {
            message = COPY_CONSTANTS.invalidLogin;
        } else {
            try {
                await this.props.store.authorizationStore.logIn(emailAddress, password);

                const currentUser = await this.props.store.userStore.getCurrentUser();
                if (false === currentUser.isOnboarded && UserType.Peer === currentUser.userType) {
                    const peer = currentUser as Peer;
                    peer.isOnboarded = true;
                    await this.props.store.userStore.upsertUser(peer);
                }
            } catch (error) {
                if ((error as Error).message === 'Access is not permitted') {
                    message = COPY_CONSTANTS.signupFailedClient;
                } else {
                    message = COPY_CONSTANTS.invalidLogin;
                }
            }
        }


        if (null != message) {
            this.setState({ message });
        }
    }

    private get forgotPasswordButtonProps(): EmbeddedButtonProps {
        return {
            label: COPY_CONSTANTS.forgot,
            style: forgotPasswordButtonStyle,
            styleClassName: 'forgotPassword',
            onClick: () => this.setState({ isResettingPassword: true })
        };
    }

    private handleClickTermsCheckbox = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        this.setState({ hasAcceptedTerms: false === this.state.hasAcceptedTerms });
    }

    public render(): JSX.Element {

        return (
            <div>

                <UnauthenticatedPageContainer
                    className={this.props.store!.isModalShowing ? STYLE_CLASSES.DIV_BLUR : undefined}
                >

                    <div style={pageHeader}>
                        <div style={pageTitle} />
                    </div>

                    <div style={formStyle}>
                        <form
                            onSubmit={(e: ChangeEvent<HTMLFormElement>) => {
                                this.submit(e);
                            }}
                        >
                            <FormTextField
                                style={emailAuthFieldStyle}
                                textStyle={emailAuthTextStyle}
                                labelTextStyle={emailAuthLabelTextStyle}
                                indicatorBarUnpopulatedStyle={indicatorBarUnpopulatedStyle}
                                label={COPY_CONSTANTS.email}
                                value={this.state.emailAddress}
                                onValueChanged={(newValue: string) => {
                                    this.setState({ emailAddress: newValue, message: '' });
                                }}
                                isStatic={true}
                                icon={EMAIL_ICON}
                            />

                            <FormTextField
                                style={emailAuthFieldStyle}
                                textStyle={emailAuthTextStyle}
                                labelTextStyle={emailAuthLabelTextStyle}
                                indicatorBarUnpopulatedStyle={indicatorBarUnpopulatedStyle}
                                isPassword={true}
                                label={COPY_CONSTANTS.password}
                                value={this.state.password}
                                onValueChanged={(newValue: string) => {
                                    this.setState({ password: newValue, message: '' });
                                }}
                                isStatic={true}
                                icon={PASSWORD_ICON}
                                embeddedButtonProps={this.state.isSigningUp ? undefined : this.forgotPasswordButtonProps}
                            />

                            {this.state.isSigningUp &&
                                <FormTextField
                                    style={emailAuthFieldStyle}
                                    textStyle={emailAuthTextStyle}
                                    labelTextStyle={emailAuthLabelTextStyle}
                                    indicatorBarUnpopulatedStyle={indicatorBarUnpopulatedStyle}
                                    isPassword={true}
                                    label={COPY_CONSTANTS.reEnterPassword}
                                    value={this.state.passwordReEntered}
                                    onValueChanged={(newValue: string) => {
                                        this.setState({ passwordReEntered: newValue, message: '' });
                                    }}
                                    isStatic={true}
                                    icon={PASSWORD_ICON}
                                />
                            }

                            <div style={this.state.message.length > 0 ? messageStyle : noMessageStyle}>
                                {this.state.message || 'x'}
                            </div>

                            {this.state.isSigningUp &&
                                <div style={buttonWrapperStyle}>
                                    <button
                                        style={termsCheckboxStyle}
                                        onClick={this.handleClickTermsCheckbox}
                                    >
                                        {this.state.hasAcceptedTerms &&
                                            <span style={termsCheckStyle}>
                                                ✓
                                            </span>
                                        }
                                    </button>
                                    <span style={termsLinkText}>
                                        {COPY_CONSTANTS.termsAndConditionsStart}
                                        <StyledLink to={ROUTES.termsAndConditions} target="_blank">
                                            {COPY_CONSTANTS.termsAndConditionsLink}
                                        </StyledLink>
                                        {COPY_CONSTANTS.termAndConditionsConjunction}
                                        <StyledLink to={ROUTES.privacyPolicy} target="_blank">
                                            {COPY_CONSTANTS.privacyPolicyLink}
                                        </StyledLink>
                                        {'.'}
                                    </span>
                                </div>}

                            <div style={buttonWrapperStyle}>
                                <CustomButton
                                    type={'submit'}
                                    style={submitButtonStyle}
                                    styleClassName={STYLE_CLASSES.BUTTON_GREEN}
                                    isDisabled={false}
                                >
                                    {this.state.isSigningUp ? COPY_CONSTANTS.signUp : COPY_CONSTANTS.login}
                                </CustomButton>
                            </div>

                            <div style={buttonWrapperStyle}>
                                <CustomButton
                                    type={'button'}
                                    style={switchModeButtonStyle}
                                    isDisabled={false}
                                    onClick={() => this.setState({
                                        isSigningUp: (false === this.state.isSigningUp),
                                        message: ''
                                    })}
                                >
                                    {`${this.state.isSigningUp ? COPY_CONSTANTS.login : COPY_CONSTANTS.signUp} >`}
                                </CustomButton>
                            </div>



                        </form>
                    </div>

                </UnauthenticatedPageContainer>

                <Darkener isActive={this.props.store!.isModalShowing} />

                <PasswordResetModal
                    isVisible={this.state.isResettingPassword}
                    onResetSent={() => this.setState({
                        isResettingPassword: false,
                        message: COPY_CONSTANTS.forgotPasswordEmailSent
                    })
                    }
                    onCancel={() => this.setState({
                        isResettingPassword: false
                    })}
                />
            </div>
        );
    }
}

const StyledLink = styled(Link)`
&:visited {
    color: ${STYLE_CONSTANTS.mediumLightGray}    
}`;

const pageHeader: CSSProperties = {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    maxWidth: '540px',
    marginTop: '20vh',
    marginBottom: '30px'
};

const pageTitle: CSSProperties = {
    backgroundImage: `url(${LOGO})`,
    backgroundPosition: 'center bottom',
    backgroundRepeat: 'no-repeat',
    height: '150px',
    width: '350px'
};

const formStyle: CSSProperties = {
    width: '350px'
};

const emailAuthFieldStyle: CSSProperties = {
    color: STYLE_CONSTANTS.white,
    backgroundColor: 'transparent',
    border: 'none',
    outline: 'none',
    marginTop: '40px',
    marginBottom: '15px'
};

const emailAuthTextStyle: CSSProperties = {
    color: STYLE_CONSTANTS.white,
    fontWeight: 'lighter',
    letterSpacing: '1px',
    height: '25px',
    fontSize: STYLE_CONSTANTS.fontSizeMedium
};

const emailAuthLabelTextStyle: CSSProperties = {
    color: STYLE_CONSTANTS.white,
    fontWeight: 'lighter',
    letterSpacing: '1px',
};

const indicatorBarUnpopulatedStyle: CSSProperties = {
    backgroundColor: STYLE_CONSTANTS.semiOpaqueWhite30
};

const buttonWrapperStyle: CSSProperties = {
    margin: 'auto',
    marginTop: '20px',
    width: '300px',
    display: 'flex'
};

const submitButtonStyle: CSSProperties = {
    border: 'none',
    color: STYLE_CONSTANTS.white,
    width: '100%',
    height: '75px',
    fontSize: STYLE_CONSTANTS.fontSizeMedium
};

const switchModeButtonStyle: CSSProperties = {
    border: 'none',
    backgroundColor: STYLE_CONSTANTS.transparent,
    width: '100%',
    fontSize: STYLE_CONSTANTS.fontSizeSmall
};

const messageStyle: CSSProperties = {
    color: STYLE_CONSTANTS.white,
    fontSize: STYLE_CONSTANTS.fontSizeSmall,
    textAlign: 'center'
};

const noMessageStyle: CSSProperties = {
    ...messageStyle,
    visibility: 'hidden'
};

const forgotPasswordButtonStyle: CSSProperties = {
    color: STYLE_CONSTANTS.white,
    height: '25px'
};

const termsCheckboxStyle: React.CSSProperties = {
    height: 20,
    width: 20,
    borderColor: 'white',
    borderWidth: 2,
    borderStyle: 'solid',
    borderRadius: 5,
    textAlign: 'center',
    backgroundColor: 'transparent',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 'auto',
    outline: 'none'
};

const termsCheckStyle: React.CSSProperties = {
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
};

const termsLinkText: React.CSSProperties = { marginLeft: '10px' };