import * as assert from 'assert';
import { IFirebaseOrg } from './firebase/FirebaseOrg';

export class Org {

    private _firebaseOrgID: string;
    private _name: string;
    private _address: string;
    private _contactPhone: string;
    private _contactEmailAddress: string;
    private _createdTimestamp: number | null;

    private constructor() {}

    public get firebaseOrgID(): string {
        return this._firebaseOrgID;
    }

    public get name(): string {
        return this._name;
    }

    public get address(): string {
        return this._address;
    }

    public get contactPhone(): string {
        return this._contactPhone;
    }

    public get contactEmailAddress(): string {
        return this._contactEmailAddress;
    }

    public static createFromFirebase(firebaseOrgID: string, firebaseOrg: IFirebaseOrg): Org {

        const org = new Org();

        assert(typeof firebaseOrgID === 'string', 'Org constructor passed args with invalid firebaseOrgID');
        assert(typeof firebaseOrg.name === 'string', 'Org constructor passed args with invalid name');
        assert(typeof firebaseOrg.address === 'string', 'Org constructor passed args with invalid address');
        assert(typeof firebaseOrg.createdTimestamp === 'number', 'Org constructor passed args with invalid createdTimestamp');
        org._firebaseOrgID = firebaseOrgID;
        org._name = firebaseOrg.name;
        org._address = firebaseOrg.address;
        org._createdTimestamp = firebaseOrg.createdTimestamp;

        assert(firebaseOrg.contact !== undefined, 'Org constructor passed args with undefined contact object');
        assert(firebaseOrg.contact.phone == null || typeof firebaseOrg.contact.phone === 'string', 'Org constructor passed args with invalid contact phone');
        assert(firebaseOrg.contact.emailAddress == null || typeof firebaseOrg.contact.emailAddress === 'string', 'Org constructor passed args with invalid contact email address');
        org._contactPhone = firebaseOrg.contact.phone;
        org._contactEmailAddress = firebaseOrg.contact.emailAddress;

        return org;
    }

    public static create(name: string, address: string, contactPhone: string, contactEmailAddress: string, existingOrg: Org | undefined): Org {
        
        const org = existingOrg  || new Org();
        org._name = name;
        org._address = address;
        org._contactPhone = contactPhone;
        org._contactEmailAddress = contactEmailAddress;

        if (undefined === existingOrg) {
            org._createdTimestamp = new Date().getTime();
        }

        return org;
    }

    public toFirebaseOrg(): IFirebaseOrg {

        let createdTimestamp: number | null = null;
        if (this._firebaseOrgID) {
            assert(null != this._createdTimestamp, 'Org.toFirebaseOrg: null createdTimestamp for existing org');
            createdTimestamp = this._createdTimestamp;
        }

        return {
            name: this._name,
            address: this._address,
            contact: {
                phone: this._contactPhone,
                emailAddress: this._contactEmailAddress
            },
            createdTimestamp: createdTimestamp
        };
    }

    public compareToForSort(org: Org): number {

        return this._name.localeCompare(org._name);
    }
    
}
